import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Button = styled.button`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  height: ${({ height }) => `${height}px`};
  padding: 0 16px;
  font-size: 14px;
  font-weight: 500;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  overflow: hidden;
  transition: 0.15s linear;
  cursor: pointer;

  ${({ variant, theme, variantColor }) => {
    switch (variant) {
      case 'outline':
        return css`
          color: ${variantColor || theme.maastrichtBlue};
          background-color: ${variantColor ? 'transparent' : theme.white};
          border-color: ${variantColor || theme.gainsboro};

          &:hover {
            background-color: ${theme.cultured};
          }

          &:focus {
            border-color: ${theme.tuftsBlue};
            background-color: ${theme.cultured};
          }

          &:active {
            background-color: ${theme.platinum};
          }
        `;
      case 'text':
        return css`
          color: ${theme.maastrichtBlue};
          border-color: transparent;
          background-color: transparent;

          &:hover {
            background-color: ${theme.cultured};
          }

          &:focus {
            border-color: ${theme.cyanBlueAzure};
            background-color: ${theme.cultured};
          }

          &:active {
            background-color: ${theme.platinum};
          }
        `;
      case 'buy':
        return css`
          color: ${theme.white};
          border-color: ${theme.emerald};
          background-color: ${theme.emerald};

          &:hover {
            background-color: ${theme.goGreen};
            border-color: ${theme.goGreen};
          }

          &:focus {
            background-color: ${theme.goGreen};
            border-color: ${theme.goGreen};
          }

          &:active {
            background-color: ${theme.goGreen};
            border-color: ${theme.goGreen};
          }

          &:disabled {
            color: ${theme.gainsboro};
            border-color: ${theme.gainsboro};
            background-color: ${theme.antiFlashWhite};
          }
        `;
      case 'sell':
        return css`
          color: ${theme.white};
          border-color: ${theme.coquelicot};
          background-color: ${theme.coquelicot};

          &:hover {
            background-color: ${theme.flamePea};
            border-color: ${theme.flamePea};
          }

          &:focus {
            background-color: ${theme.grenadier};
            border-color: ${theme.grenadier};
          }

          &:active {
            background-color: ${theme.grenadier};
            border-color: ${theme.grenadier};
          }

          &:disabled {
            color: ${theme.gainsboro};
            border-color: ${theme.gainsboro};
            background-color: ${theme.antiFlashWhite};
          }
        `;
      case 'secondary-dark':
        return css`
          color: ${theme.white};
          border-color: ${theme.maastrichtBlue};
          background-color: ${theme.maastrichtBlue};
          font-size: 14px;
          line-height: 19.6px;
          border-radius: 9px;

          &:disabled {
            color: ${theme.gainsboro};
            border-color: ${theme.gainsboro};
            background-color: ${theme.antiFlashWhite};
          }
        `;
      case 'secondary-gray':
        return css`
          color: ${theme.maastrichtBlue};
          border-color: ${theme.gainsboro};
          background-color: ${theme.gainsboro};
          font-size: 14px;
          line-height: 19.6px;
          border-radius: 9px;

          &:disabled {
            color: ${theme.gainsboro};
            border-color: ${theme.gainsboro};
            background-color: ${theme.antiFlashWhite};
          }
        `;

      default:
        return css`
          color: ${theme.white};
          background-color: ${theme.trinidad};
          border-color: ${theme.trinidad};

          &:hover {
            background-color: ${theme.flamePea};
            border-color: ${theme.flamePea};
          }

          &:focus {
            background-color: ${theme.grenadier};
            border-color: ${theme.grenadier};
          }

          &:active {
            background-color: ${theme.fire};
            border-color: ${theme.fire};
          }
        `;
    }
  }}

  ${({ disabled, theme, variant, $loading }) =>
    disabled &&
    css`
      color: ${$loading && theme.gainsboro};
      background-color: ${variant !== 'contained'
        ? 'transparent'
        : theme.antiFlashWhite};
      border-color: ${variant !== 'text' ? theme.gainsboro : 'transparent'};
      pointer-events: none;
    `}
  ${({ css }) => css}
`;

const ButtonChildren = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const PrependIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      & > svg {
        fill: ${theme.gainsboro};
      }
    `}
`;

export { Button, ButtonChildren, PrependIcon };
