import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const authLogin = createAsyncThunk(
  'auth/login',
  async (userData, { rejectWithValue }) => {
    try {
      const { data } = await api.auth.login(userData);
      return data.data;
    } catch (err) {
      const { status, data } = err.response;
      if (status !== 401 && status !== 429) handleServerErrors(err);
      throw rejectWithValue({ status, message: data.message });
    }
  }
);

const getRolesList = createAsyncThunk('auth/getRolesList', async () => {
  try {
    const { data } = await api.auth.getRolesList();
    return data.roles;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const authRegister = createAsyncThunk('auth/register', async (userData) => {
  try {
    const { data } = await api.auth.register(userData);
    return data.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const authLogout = createAsyncThunk('auth/logout', async () => {
  try {
    const { data } = await api.auth.logout();
    return data.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const authTwoFactorChooseWay = createAsyncThunk(
  'auth/twoFactorChooseWay',
  async (formData) => {
    try {
      const { data } = await api.auth.twoFactorChooseWay(formData);
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const authTwoFactorLogin = createAsyncThunk(
  'auth/twoFactorLogin',
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await api.auth.twoFactorLogin(formData);
      return data.data;
    } catch (err) {
      const { status, data } = err.response;
      if (status !== 429) handleServerErrors(err);
      return rejectWithValue({
        status,
        rateLimit: data?.rateLimit,
        message: data.message,
      });
    }
  }
);

const authTwoFactorLoginResend = createAsyncThunk(
  'auth/twoFactorLoginResend',
  async (formData) => {
    try {
      const { data } = await api.auth.twoFactorLoginResend(formData);
      toast.success(data.data.success);
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (login, { rejectWithValue }) => {
    try {
      const { data } = await api.auth.resetPassword(login);
      return data;
    } catch (err) {
      const { status, data } = err.response;
      if (status !== 429) handleServerErrors(err);
      return rejectWithValue({ status, message: data.message });
    }
  }
);

const sendResetPasswordCode = createAsyncThunk(
  'auth/sendResetPasswordCode',
  async (fd, { rejectWithValue }) => {
    try {
      const { data } = await api.auth.sendResetPasswordCode(fd);
      return data;
    } catch (err) {
      const { status, data } = err.response;
      if (status !== 429) handleServerErrors(err);
      return rejectWithValue({
        status,
        rateLimit: data?.rateLimit,
        message: data.message,
      });
    }
  }
);

const changePassword = createAsyncThunk('auth/changePassword', async (fd) => {
  try {
    const { data } = await api.auth.changePassword(fd);
    toast.success('Your password has been updated');
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const changePasswordByOld = createAsyncThunk(
  'auth/changePasswordByOld',
  async (data) => {
    try {
      return await api.auth.changePasswordByOld(data);
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  authLogin,
  getRolesList,
  authRegister,
  authLogout,
  authTwoFactorChooseWay,
  authTwoFactorLogin,
  authTwoFactorLoginResend,
  resetPassword,
  sendResetPasswordCode,
  changePassword,
  changePasswordByOld,
};

export { thunks };
