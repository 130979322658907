import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const listerServices = {
  getListers(params) {
    return http.get(WEB_API_ROUTES.admin.listerServices.getListers, { params });
  },

  getListerInfo(listerId) {
    return http.get(
      WEB_API_ROUTES.admin.listerServices.getListerInfo.replace(
        '{lister}',
        listerId
      )
    );
  },

  updateInfluencerInfo(influencerId, data) {
    return http.put(
      WEB_API_ROUTES.admin.listerServices.updateInfluencerInfo.replace(
        '{lister}',
        influencerId
      ),
      data
    );
  },

  updateInfluencerPhoto(influencerId, data) {
    return http.put(
      WEB_API_ROUTES.admin.listerServices.updateInfluencerPhoto.replace(
        '{lister}',
        influencerId
      ),
      data
    );
  },

  updateInfluencerRating(influencerId, data) {
    return http.put(
      WEB_API_ROUTES.admin.listerServices.updateInfluencerRating.replace(
        '{lister}',
        influencerId
      ),
      data
    );
  },

  updateActions(listerId, data) {
    return http.put(
      WEB_API_ROUTES.admin.listerServices.updateActions.replace(
        '{lister}',
        listerId
      ),
      data
    );
  },

  approveLister(listerId) {
    return http.post(
      WEB_API_ROUTES.admin.listerServices.approveLister.replace(
        '{lister}',
        listerId
      )
    );
  },

  rejectLister(listerId, data) {
    return http.post(
      WEB_API_ROUTES.admin.listerServices.rejectLister.replace(
        '{lister}',
        listerId
      ),
      data
    );
  },

  stopTrading(listerId) {
    return http.post(
      WEB_API_ROUTES.admin.listerServices.stopTrading.replace(
        '{lister}',
        listerId
      )
    );
  },

  startTrading(listerId) {
    return http.post(
      WEB_API_ROUTES.admin.listerServices.startTrading.replace(
        '{lister}',
        listerId
      )
    );
  },

  updateAccountStatus(userId, data) {
    return http.post(
      WEB_API_ROUTES.admin.listerServices.updateAccountStatus.replace(
        '{user}',
        userId
      ),
      data
    );
  },
};
