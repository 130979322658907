import { notificationsFormattedList } from 'resources/user/notifications';

const selectors = {
  listLatest: (state) => state.userNotifications.listLatest,
  latestUnreadCount: (state) => state.userNotifications.latestUnreadCount,
  listStatic: (state) => {
    return notificationsFormattedList(
      state.userNotifications.listStatic,
      state.settingsPage.defaultInterfaceSettings.timeZone.offset
    );
  },
  listStaticParams: (state) => state.userNotifications.listStaticParams,
  listStaticStatus: (state) => state.userNotifications.listStaticStatus,
  markAllAsReadStatus: (state) => state.userNotifications.markAllAsReadStatus,
};

export { selectors };
