import PropTypes from 'prop-types';
import { ThemeProvider } from '@emotion/react';
import { useState } from 'react';

const theme = {
  LIGHT: {
    primary: '#A5A5A5',
    error: '#e96b6b',
    white: '#ffffff',
    flamingo: '#eb5757',
    trinidad: '#e93b04',
    transparentTrinidad: 'rgba(233, 59, 4, 0.1)',
    flamePea: '#e56137',
    grenadier: '#d13200',
    fire: '#b92c00',
    coquelicot: '#EE3900',
    indianRed: '#D75959',
    gainsboro: '#d7d9e7',
    maastrichtBlue: '#03182a',
    cyanBlueAzure: '#5282cb',
    tropicalBlue: '#B2D0F6',
    brightGray: '#e8eaed',
    spanishGray: '#919599',
    lavenderGray: '#bcc2cb',
    osloGrey: '#889097',
    graniteGray: '#60656A',
    softPeach: '#ededed',
    outerSpace: '#444D55',
    davyGrey: '#535658',
    manatee: '#959ba4',
    romanSilver: '#978F88',
    cultured: '#f4f5f5',
    platinum: '#E1E5E8',
    antiFlashWhite: '#f2f2f2',
    azure: '#F0FCFF',
    tuftsBlue: '#4694DC',
    onahau: '#D2E6FF',
    honeydew: '#ECFFED',
    chineseWhite: '#DEF1DF',
    nyanza: '#D9FEDB',
    aliceBlue: '#F3F8FB',
    policeBlue: '#3e5970',
    blueChalk: '#eaecf5',
    coolBlue: '#3c80bf',
    bluish: '#3c7fbe',
    mariner: '#2e73b2',
    pattensBlue: '#DFEDFF',
    lavenderPinocchio: '#dee0ea',
    languidLavender: '#cdd0e1',
    harp: '#EFEFEF',
    alabaster: '#FAFAFA',
    goGreen: '#00B066',
    emerald: '#40C277',
    geyser: '#DBDDE9',
    dodgerBlue: '#5081FF',
    mountainMist: '#989898',
    spunPearl: '#A8AAB8',
    moonMist: '#DDDDDD',
    goldenBrown: '#EAC60F',
    burntYellow: '#CBAC0E',
    chineseGold: '#C89E0B',
    hintOfGreen: '#E9FDEA',
    titanWhite: '#EAEEF3',
    whiteLilac: '#F7F7F8',
    mercury: '#E3E6E8',
    ghostWhite: '#F9FAFC',
    lilyWhite: '#E1F9FF',
    blackCow: '#4B4B4B',
    quillGrey: '#D9D9D9',
    carbonGrey: '#565C62',
    aquaSpring: '#e9faf1',
  },
  DARK: {},
};

Theme.propTypes = {
  children: PropTypes.node,
};

function Theme({ children }) {
  const [mode] = useState('LIGHT');
  return <ThemeProvider theme={theme[mode]}>{children}</ThemeProvider>;
}

export default Theme;
