import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const userNotifications = {
  getNotifications(params) {
    return http.get(WEB_API_ROUTES.user.notifications.getNotifications, {
      params,
    });
  },
  getLatest() {
    return http.get(WEB_API_ROUTES.user.notifications.getLatest);
  },
  setMarkAllAsRead() {
    return http.post(WEB_API_ROUTES.user.notifications.setMarkAllAsRead);
  },
};
