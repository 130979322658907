const selectors = {
  dividend: (state) => state.dividendDetails.dividend,
  list: (state) => state.dividendDetails.list,
  pagination: (state) => state.dividendDetails.pagination,
  filters: (state) => state.dividendDetails.filters,
  fetchingDividendDetailsStatus: (state) =>
    state.dividendDetails.fetchingDividendDetailsStatus,
  fetchingPaymentsStatus: (state) =>
    state.dividendDetails.fetchingPaymentsStatus,
  sendDividendsAndReportStatus: (state) =>
    state.dividendDetails.sendDividendsAndReportStatus,
  exportCsvStatus: (state) => state.dividendDetails.exportCsvStatus,
};

export { selectors };
