const selectors = {
  list: (s) => s.unregisteredUserMarket.list,
  pagination: (s) => s.unregisteredUserMarket.pagination,
  filters: (s) => s.unregisteredUserMarket.filters,
  fetchingStatus: (s) => s.unregisteredUserMarket.fetchingStatus,
  addToWatchlistStatus: (s) => s.unregisteredUserMarket.addToWatchlistStatus,
  removeFromWatchlistStatus: (s) =>
    s.unregisteredUserMarket.removeFromWatchlistStatus,
};

export { selectors };
