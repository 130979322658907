import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { prepareDateToServer } from 'utils/prepareDate';
import { checkOptionAll } from 'utils/prepareFields';

const prepareFilters = (filters) => ({
  eventType: checkOptionAll(filters.eventType),
  eventStatus: checkOptionAll(filters.eventStatus),
  country: checkOptionAll(filters.country),
  dateFrom: filters.dateFrom
    ? prepareDateToServer(filters.dateFrom)
    : filters.dateFrom,
  dateTo: filters.dateTo ? prepareDateToServer(filters.dateTo) : filters.dateTo,
});

const getLogs = createAsyncThunk(
  'securityLogs/getLogs',
  async ({ pageSize, pageIndex, sortCol, sortDir, filters }) => {
    try {
      const params = {
        perPage: pageSize,
        page: pageIndex,
        sortCol,
        sortDir,
        ...filters,
        ...prepareFilters(filters),
      };
      const { data } = await api.securitySetup.getLogs(params);
      return { data: data.data, meta: data.meta };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const exportLogsCsv = createAsyncThunk(
  'securityLogs/exportCsv',
  async ({ sortCol, sortDir, filters }) => {
    try {
      const params = {
        sortCol,
        sortDir,
        ...filters,
        ...prepareFilters(filters),
      };
      const fd = Object.fromEntries(
        Object.entries(params).filter((item) => item[1])
      );
      const { data } = await api.securitySetup.exportLogsCsv(fd);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getLogs,
  exportLogsCsv,
};

export { thunks };
