import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const userSettings = {
  updateNotificationSettings(data) {
    return http.put(
      WEB_API_ROUTES.user.settings.updateNotificationSettings,
      data
    );
  },
  updateInterfaceSettings(data) {
    return http.put(WEB_API_ROUTES.user.settings.updateInterfaceSettings, data);
  },
  disableTwoFactor(data) {
    return http.post(WEB_API_ROUTES.user.settings.disableTwoFactor, data);
  },
  enableTwoFactor(data) {
    return http.post(WEB_API_ROUTES.user.settings.enableTwoFactor, data);
  },
  confirmTwoFactor(data) {
    return http.post(WEB_API_ROUTES.user.settings.confirmTwoFactor, data);
  },
  getQR() {
    return http.get(WEB_API_ROUTES.user.settings.getQR);
  },
};
