import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const securitySetup = {
  getSecuritySetup() {
    return http.get(WEB_API_ROUTES.admin.securitySetup.securityConfig);
  },

  updateSecuritySetup(fd) {
    return http.post(WEB_API_ROUTES.admin.securitySetup.securityConfig, fd);
  },

  getLogs(params) {
    return http.get(WEB_API_ROUTES.admin.securitySetup.getLogs, { params });
  },
  exportLogsCsv(fd) {
    return http.post(WEB_API_ROUTES.admin.securitySetup.exportLogsCsv, fd);
  },
  getArchived(params) {
    return http.get(WEB_API_ROUTES.admin.securitySetup.getArchived, { params });
  },
};
