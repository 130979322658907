import { createSlice } from '@reduxjs/toolkit';

import {
  quarterOptions,
  yearOptions,
} from 'components/Admin/DividendPaymentsPage/Filters/config';
import { pagination } from 'models/pagination';
import { prepareDateFromServer } from 'utils/prepareDate';
import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  list: [],
  pagination: {},
  filters: {
    search: null,
    quarter: quarterOptions.map(({ value }) => value),
    year: yearOptions.map(({ value }) => value),
  },
  fetchingStatus: status.IDLE,
  addDividendStatus: status.IDLE,
};

const slice = createSlice({
  name: 'financialServices',
  initialState,
  reducers: {
    SET_FILTERS: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
    RESET_FILTERS: (state) => {
      state.filters = initialState.filters;
    },
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getDividends.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getDividends.fulfilled, (state, { payload }) => {
        const { meta, data } = payload;
        const { current_page, from, to, total, per_page } = meta;
        state.list = data.map((item) => ({
          ...item,
          createdAt: prepareDateFromServer(item.createdAt),
        }));
        state.pagination = pagination(current_page, from, to, total, per_page);
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getDividends.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      })

      .addCase(thunks.addDividend.pending, (state) => {
        state.addDividendStatus = status.PENDING;
      })
      .addCase(thunks.addDividend.fulfilled, (state) => {
        state.addDividendStatus = status.SUCCESS;
      })
      .addCase(thunks.addDividend.rejected, (state) => {
        state.addDividendStatus = status.FAIL;
      });
  },
});

const financialServices = {
  actions: slice.actions,
  selectors,
  thunks,
};

export { financialServices };
export default slice.reducer;
