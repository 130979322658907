import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const withdrawBalance = createAsyncThunk('user/withdrawBalance', async (fd) => {
  try {
    const { data } = await api.userMoney.withdrawBalance(fd);
    if (data.success) toast.success('Withdraw request successfully sent');
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getUserWallet = createAsyncThunk('user/getUserWallet', async () => {
  try {
    const { data } = await api.userMoney.getUserWallet();
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const swapUSDTtoINX = createAsyncThunk('user/swapUSDTtoINX', async (fd) => {
  try {
    const { data } = await api.userMoney.swapUSDTtoINX(fd);
    toast.success('Token Swap Successful');
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const swapINXtoUSDT = createAsyncThunk('user/swapINXtoUSDT', async (fd) => {
  try {
    const { data } = await api.userMoney.swapINXtoUSDT(fd);
    toast.success('Token Swap Successful');
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getTransferUsers = createAsyncThunk(
  'user/getTransferUsers',
  async (params) => {
    try {
      const { data } = await api.userMoney.getTransferUsers(params);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const transferTokensToUser = createAsyncThunk(
  'user/transferTokensToUser',
  async (fd) => {
    try {
      const { data } = await api.userMoney.transferTokensToUser(fd);
      toast.success('Token Transfer successful');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  withdrawBalance,
  getUserWallet,
  swapUSDTtoINX,
  swapINXtoUSDT,
  getTransferUsers,
  transferTokensToUser,
};

export { thunks };
