import yup from 'plugins/yup-config';

import { transformObject } from 'utils/prepare';
import { appRoles } from 'utils/roles';
import { accountStatus } from 'utils/const';

const adminStatusOptions = [
  { label: 'All', value: 'All' },
  { label: 'Active', value: accountStatus.ACTIVE },
  { label: 'Disabled', value: accountStatus.DISABLED },
];

const roleOptions = [
  { label: 'All', value: 'All' },
  { label: 'Support', value: appRoles.ADMIN },
  { label: 'Financial', value: appRoles.F_ADMIN },
  { label: 'Lister', value: appRoles.L_ADMIN },
  { label: 'Reports', value: appRoles.R_ADMIN },
  { label: 'Security', value: appRoles.S_OFFICER },
  { label: 'Super', value: appRoles.S_ADMIN },
];

const fields = [
  { label: 'Admin Status', name: 'status', options: adminStatusOptions },
  { label: 'Role', name: 'role', options: roleOptions },
];

const schema = yup.object().shape({
  status: yup.object().transform(transformObject).nullable().required(),
  role: yup.object().transform(transformObject).nullable().required(),
});

export { schema, adminStatusOptions, roleOptions, fields };
