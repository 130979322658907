import { getObjectFromValue } from 'utils/prepareFields';
import { prepareDateFromServer } from 'utils/prepareDate';
import { verificationStatus } from 'utils/const';

class ListerProfileModel {
  constructor(defaultProfile = {}) {
    this.defaultProfile = defaultProfile;
  }

  get(offset) {
    const {
      dob,
      levelOfEducation,
      countryOfMainProfession,
      militaryService,
      status,
      ...data
    } = this.defaultProfile;

    return {
      ...data,
      status: status || verificationStatus.NOT_VERIFIED,
      dob: prepareDateFromServer(dob, offset),
      levelOfEducation: getObjectFromValue(levelOfEducation),
      countryOfMainProfession: getObjectFromValue(countryOfMainProfession),
      militaryService: getObjectFromValue(militaryService),
    };
  }
}

export { ListerProfileModel };
