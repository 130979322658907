import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const setAuthData = (state, payload) => {
  const { accessToken, refreshToken, roles, username } = payload;
  state.token = accessToken;
  state.refreshToken = refreshToken;
  state.roles = roles;
  state.username = username;
};

const initialState = {
  token: null,
  refreshToken: null,
  roles: [],
  rateLimit: {
    limit: '3',
    remaining: '3',
  },
  accountAccessError: null,
  skipRegistration: false,
  accountWasDisabled: false,
  accountWasDisabledAfterAllAttempts: false,
  loginStatus: status.IDLE,
  registerStatus: status.IDLE,
  logoutStatus: status.IDLE,
  twoFactorLoginStatus: status.IDLE,
  twoFactorChooseWayStatus: status.IDLE,
  twoFactorLoginResendStatus: status.IDLE,
  socialLoginStatus: status.IDLE,
  resetPasswordRequestStatus: status.IDLE,
  sendResetPasswordCodeStatus: status.IDLE,
  changePasswordStatus: status.IDLE,
  changePasswordByOldStatus: status.IDLE,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    SET_LOGIN: (state, { payload }) => {
      setAuthData(state, payload);
    },
    SET_REFRESH_TOKEN: (state, { payload }) => {
      const { accessToken, refreshToken } = payload;
      state.token = accessToken;
      state.refreshToken = refreshToken;
    },
    SET_SKIP_REGISTRATION: (state, { payload }) => {
      state.skipRegistration = payload;
    },
    SET_ACCOUNT_WAS_DISABLED: (state, { payload }) => {
      state.accountWasDisabled = payload;
    },
    SET_ACCOUNT_WAS_DISABLED_AFTER_ALL_ATTEMPTS: (state, { payload }) => {
      state.accountWasDisabledAfterAllAttempts = payload;
    },
    RESET_CHANGE_PASSWORD_STATUS: (state) => {
      state.changePasswordStatus = status.IDLE;
    },
    RESET_CHANGE_PASSWORD_BY_OLD_STATUS: (state) => {
      state.changePasswordByOldStatus = status.IDLE;
    },
    SET_APP_IS_FAILED: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.authLogin.pending, (state) => {
        state.loginStatus = status.PENDING;
      })
      .addCase(thunks.authLogin.fulfilled, (state) => {
        state.loginStatus = status.SUCCESS;
      })
      .addCase(thunks.authLogin.rejected, (state, { payload }) => {
        const { status: httpStatus, message } = payload;
        if (httpStatus === 401) {
          state.accountWasDisabled = true;
        } else if (httpStatus === 429) {
          state.accountAccessError = message;
          state.accountWasDisabledAfterAllAttempts = true;
        }
        state.loginStatus = status.FAIL;
      })

      .addCase(thunks.getRolesList.fulfilled, (state, { payload }) => {
        state.roles = payload;
      })

      .addCase(thunks.authRegister.pending, (state) => {
        state.registerStatus = status.PENDING;
      })
      .addCase(thunks.authRegister.fulfilled, (state, { payload }) => {
        setAuthData(state, payload);
        state.registerStatus = status.SUCCESS;
      })
      .addCase(thunks.authRegister.rejected, (state) => {
        state.registerStatus = status.FAIL;
      })

      .addCase(thunks.authLogout.pending, (state) => {
        state.logoutStatus = status.PENDING;
      })
      .addCase(thunks.authLogout.fulfilled, (state) => {
        state.logoutStatus = status.SUCCESS;
      })
      .addCase(thunks.authLogout.rejected, (state) => {
        state.logoutStatus = status.FAIL;
      })

      .addCase(thunks.authTwoFactorChooseWay.pending, (state) => {
        state.twoFactorChooseWayStatus = status.PENDING;
      })
      .addCase(thunks.authTwoFactorChooseWay.fulfilled, (state) => {
        state.twoFactorChooseWayStatus = status.SUCCESS;
      })
      .addCase(thunks.authTwoFactorChooseWay.rejected, (state) => {
        state.twoFactorChooseWayStatus = status.FAIL;
      })

      .addCase(thunks.authTwoFactorLogin.pending, (state) => {
        state.twoFactorLoginStatus = status.PENDING;
      })
      .addCase(thunks.authTwoFactorLogin.fulfilled, (state, { payload }) => {
        setAuthData(state, payload);
        state.twoFactorLoginStatus = status.SUCCESS;
      })
      .addCase(thunks.authTwoFactorLogin.rejected, (state, { payload }) => {
        const { status: httpStatus, rateLimit, message } = payload;
        if (rateLimit) {
          state.rateLimit = rateLimit;
        }
        if (httpStatus === 429) {
          state.accountAccessError = message;
          state.accountWasDisabledAfterAllAttempts = true;
        }
        state.twoFactorLoginStatus = status.FAIL;
      })

      .addCase(thunks.authTwoFactorLoginResend.pending, (state) => {
        state.twoFactorLoginResendStatus = status.PENDING;
      })
      .addCase(thunks.authTwoFactorLoginResend.fulfilled, (state) => {
        state.twoFactorLoginResendStatus = status.SUCCESS;
      })
      .addCase(thunks.authTwoFactorLoginResend.rejected, (state) => {
        state.twoFactorLoginResendStatus = status.FAIL;
      })

      .addCase(thunks.resetPassword.pending, (state) => {
        state.resetPasswordRequestStatus = status.PENDING;
      })
      .addCase(thunks.resetPassword.fulfilled, (state) => {
        state.resetPasswordRequestStatus = status.SUCCESS;
      })
      .addCase(thunks.resetPassword.rejected, (state, { payload }) => {
        const { status: httpStatus, message } = payload;
        if (httpStatus === 429) {
          state.accountAccessError = message;
          state.accountWasDisabledAfterAllAttempts = true;
        }
        state.resetPasswordRequestStatus = status.FAIL;
      })

      .addCase(thunks.sendResetPasswordCode.pending, (state) => {
        state.sendResetPasswordCodeStatus = status.PENDING;
      })
      .addCase(thunks.sendResetPasswordCode.fulfilled, (state) => {
        state.sendResetPasswordCodeStatus = status.SUCCESS;
        state.changePasswordStatus = status.IDLE;
      })
      .addCase(thunks.sendResetPasswordCode.rejected, (state, { payload }) => {
        const { status: httpStatus, rateLimit, message } = payload;
        if (rateLimit) {
          state.rateLimit = rateLimit;
        }
        if (httpStatus === 429) {
          state.accountAccessError = message;
          state.accountWasDisabledAfterAllAttempts = true;
        }
        state.sendResetPasswordCodeStatus = status.FAIL;
      })

      .addCase(thunks.changePassword.pending, (state) => {
        state.changePasswordStatus = status.PENDING;
      })
      .addCase(thunks.changePassword.fulfilled, (state) => {
        state.changePasswordStatus = status.SUCCESS;
      })
      .addCase(thunks.changePassword.rejected, (state) => {
        state.changePasswordStatus = status.FAIL;
      })

      .addCase(thunks.changePasswordByOld.pending, (state) => {
        state.changePasswordByOldStatus = status.PENDING;
      })
      .addCase(thunks.changePasswordByOld.fulfilled, (state) => {
        state.changePasswordByOldStatus = status.SUCCESS;
      })
      .addCase(thunks.changePasswordByOld.rejected, (state) => {
        state.changePasswordByOldStatus = status.FAIL;
      });
  },
});

const auth = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { auth };
export default slice.reducer;
