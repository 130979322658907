import { createSlice } from '@reduxjs/toolkit';

import { selectors } from './selectors';

const steps = [
  {
    id: 0,
    slug: 'GENERAL',
    title: 'General',
    completed: false,
  },
  {
    id: 1,
    slug: 'SOCIAL_MEDIA',
    title: 'Social Media',
    completed: false,
  },
  {
    id: 2,
    slug: 'PERSONAL_BACKGROUND',
    title: 'Personal background',
    completed: false,
  },
  {
    id: 3,
    slug: 'LEGAL_INFO',
    title: 'Legal info',
    completed: false,
  },
];

const initialState = {
  steps,
  currentStep: steps[0],
  activeStep: steps[0],
};

const slice = createSlice({
  name: 'listerProfileSettings',
  initialState: { ...initialState },
  reducers: {
    RESET_STATE: (state) => {
      Object.assign(state, initialState);
    },
    SET_CURRENT_STEP: (state, { payload }) => {
      state.currentStep = payload;
    },
    SET_ACTIVE_STEP: (state, { payload }) => {
      state.activeStep = payload;
    },
    SET_MODIFIED_STEPS: (state, { payload }) => {
      state.steps = payload;
    },
  },
  extraReducers: () => {},
});

const listerProfileSettings = {
  actions: slice.actions,
  selectors,
};

export { listerProfileSettings };
export default slice.reducer;
