import PageNotFound from 'assets/img/global/img/bg-page-not-found.svg';
import { Wrap, Content, Img, Text, Error, Heading, Paragraph } from './styled';

function NotFoundPage() {
  return (
    <Wrap>
      <Img
        src={PageNotFound}
        alt="Logo"
      />
      <Content>
        <Text>
          <Error>Error 404</Error>
          <Heading>This page does not exist ;(</Heading>
          <Paragraph>
            Sorry, this page does not exist. Check the spelling of the address,
            go to the main page or use the search
          </Paragraph>
        </Text>
      </Content>
    </Wrap>
  );
}

export default NotFoundPage;
