const selectors = {
  twoFactorWay: (state) => state.settingsPage.twoFactorWay,
  twoFactorEnabled: (state) => state.settingsPage.twoFactorEnabled,
  enable2FAStatus: (state) => state.settingsPage.enable2FAStatus,
  disable2FAStatus: (state) => state.settingsPage.disable2FAStatus,
  googleAppSettings: (state) => state.settingsPage.googleAppSettings,
  alertPreferences: (state) => state.settingsPage.alertPreferences,
  defaultInterfaceSettings: (state) =>
    state.settingsPage.defaultInterfaceSettings,
  currentTimeZoneOffset: (state) =>
    state.settingsPage.defaultInterfaceSettings.timeZone?.offset,
  updateSettingsStatus: (state) => state.settingsPage.updateSettingsStatus,
  confirm2FAStatus: (state) => state.settingsPage.confirm2FAStatus,
  gettingQRStatus: (state) => state.settingsPage.gettingQRStatus,
};

export { selectors };
