import { createSlice } from '@reduxjs/toolkit';

import { pagination } from 'models/pagination';
import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  list: [],
  pagination: {},
  filters: {
    dateFrom: null,
    dateTo: null,
  },
  fetchingStatus: status.IDLE,
};

const slice = createSlice({
  name: 'securityArchived',
  initialState,
  reducers: {
    SET_FILTERS: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
    RESET_FILTERS: (state) => {
      state.filters = initialState.filters;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getArchived.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getArchived.fulfilled, (state, { payload }) => {
        const { meta, data } = payload;
        const { current_page, from, to, total, per_page } = meta;
        state.list = data;
        state.pagination = pagination(current_page, from, to, total, per_page);
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getArchived.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      });
  },
});

export const securityArchived = {
  actions: slice.actions,
  selectors,
  thunks,
};

export default slice.reducer;
