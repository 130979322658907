import React from 'react';
import Router from 'router';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import 'react-toastify/dist/ReactToastify.css';
import './styles/app.scss';
import { Global } from '@emotion/react';

import store from 'store';
import App from 'App';
import Theme from 'components/App/Theme';
import { GlobalStyles } from 'components/App/GlobalStyled';
import ErrorBoundary from 'middleware/ErrorBoundary';

const persist = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persist}
      >
        <Theme>
          <Global styles={GlobalStyles} />
          <Router>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </Router>
        </Theme>
      </PersistGate>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        newestOnTop={false}
        closeButton={false}
        limit={1}
        hideProgressBar
        draggable
        pauseOnHover
        theme="light"
      />
    </Provider>
  </React.StrictMode>,
  document.getElementById(`root`)
);
