import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getDashboard = createAsyncThunk(
  'userDashboard/getDashboard',
  async () => {
    try {
      const { data } = await api.userDashboard.getDashboard();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateChart = createAsyncThunk(
  'userDashboard/updateChart',
  async ({ listerId, fd }) => {
    try {
      const { data } = await api.listerProfile.getGraphic(listerId, fd);
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const removeFromWatchlist = createAsyncThunk(
  'userDashboard/removeFromWatchlist',
  async (listerId) => {
    try {
      await api.userMarket.removeFromWatchlist(listerId);
      return { listerId };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getDashboard,
  updateChart,
  removeFromWatchlist,
};

export { thunks };
