import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { checkOptionAll } from 'utils/prepareFields';

const getListerDetails = createAsyncThunk(
  'holdings/getListerDetails',
  async (influencerId) => {
    try {
      const {
        data: { data },
      } = await api.holdings.getListerDetails(influencerId);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getListerOffers = createAsyncThunk(
  'holdings/getListerOffers',
  async ({ filters, influencerId, pageSize, pageIndex, sortCol, sortDir }) => {
    try {
      const { data } = await api.holdings.getListerOffers(influencerId, {
        perPage: pageSize,
        page: pageIndex,
        sortCol,
        sortDir,
        ...filters,
        status: checkOptionAll(filters.status),
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const stopOffer = createAsyncThunk('holdings/stopOffer', async (offer) => {
  try {
    const { data } = await api.holdings.stopOffer(offer);
    toast.success('Sale of shards successfully stopped');
    return data.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const startOffer = createAsyncThunk('holdings/startOffer', async (offer) => {
  try {
    const { data } = await api.holdings.startOffer(offer);
    toast.success('Sale of shards successfully started');
    return data.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const deleteSellOffer = createAsyncThunk(
  'holdings/deleteSellOffer',
  async (offer) => {
    try {
      const { data } = await api.holdings.deleteSellOffer(offer);
      if (data.success) toast.success(data.message);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sellOffer = createAsyncThunk('holdings/sellOffer', async (fd) => {
  try {
    const { data } = await api.holdings.sellOffer(fd);
    if (data.success) toast.success(data.message);
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const thunks = {
  getListerDetails,
  getListerOffers,
  stopOffer,
  startOffer,
  deleteSellOffer,
  sellOffer,
};

export { thunks };
