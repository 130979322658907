import { createSlice } from '@reduxjs/toolkit';

import { selectors } from 'store/user/userHoldings/selectors';
import { thunks } from 'store/user/userHoldings/thunks';
import { pagination } from 'models/pagination';
import { status } from 'utils/const';

const initialState = {
  approximateValue: 0,
  lockedTokens: {},
  list: [],
  pagination: {},
  filters: {
    shardsFrom: null,
    shardsTo: null,
    priceFrom: null,
    priceTo: null,
    search: null,
  },
  infoLoadingStatus: status.IDLE,
  holdingsLoadingStatus: status.IDLE,
  sellOfferStatus: status.IDLE,
};

const slice = createSlice({
  name: 'userHoldings',
  initialState: { ...initialState },
  reducers: {
    SET_FILTERS: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
    RESET_FILTERS: (state) => {
      state.filters = initialState.filters;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getHoldings.pending, (state) => {
        state.holdingsLoadingStatus = status.PENDING;
      })
      .addCase(thunks.getHoldings.fulfilled, (state, { payload }) => {
        const { meta, data } = payload;
        const { current_page, from, to, total, per_page } = meta;
        state.holdingsLoadingStatus = status.SUCCESS;
        state.list = data;
        state.pagination = pagination(current_page, from, to, total, per_page);
      })
      .addCase(thunks.getHoldings.rejected, (state) => {
        state.holdingsLoadingStatus = status.FAIL;
      })

      .addCase(thunks.getApproximateValue.pending, (state) => {
        state.infoLoadingStatus = status.PENDING;
      })
      .addCase(
        thunks.getApproximateValue.fulfilled,
        (state, { payload: { approximateValue, shardsOnLock } }) => {
          state.approximateValue = approximateValue;
          state.lockedTokens = shardsOnLock;
          state.infoLoadingStatus = status.PENDING;
        }
      )
      .addCase(thunks.getApproximateValue.rejected, (state) => {
        state.infoLoadingStatus = status.FAIL;
      });
  },
});

const userHoldings = {
  actions: slice.actions,
  selectors,
  thunks,
};

export { userHoldings };
export default slice.reducer;
