import { APP_ROUTES } from 'router/appRoutes';
import ErrorPage from 'pages/General/ErrorPage';
import TwitterCallbackHandler from 'pages/General/TwitterCallbackHandler';

export const middlewareRoutes = [
  {
    id: 1,
    exact: true,
    path: APP_ROUTES.error,
    component: ErrorPage,
  },
  {
    id: 2,
    exact: true,
    path: APP_ROUTES.twitterVerification,
    component: TwitterCallbackHandler,
  },
];
