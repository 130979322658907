import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getProfile = createAsyncThunk('guestProfile/getProfile', async () => {
  try {
    const { data } = await api.guestProfile.getProfile();
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const changeAvatar = createAsyncThunk(
  'guestProfile/changeAvatar',
  async (preparedFile) => {
    try {
      const { data } = await api.guestProfile.changeAvatar(preparedFile);
      return {
        data: data?.data,
      };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateUser = createAsyncThunk('guestProfile/updateUser', async (data) => {
  try {
    const res = await api.guestProfile.updateUser(data);
    return {
      data: res.data,
    };
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const confirmPhoneChange = createAsyncThunk(
  'guestProfile/confirmPhoneChange',
  async (code) => {
    try {
      const { data } = await api.guestProfile.confirmPhoneChange(code);
      return data?.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const confirmPhoneChangeResend = createAsyncThunk(
  'guestProfile/confirmPhoneChangeResend',
  async (code) => {
    try {
      const { data } = await api.guestProfile.confirmPhoneChangeResend(code);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const confirmEmailChange = createAsyncThunk(
  'guestProfile/confirmEmailChange',
  async (hash) => {
    try {
      const { data } = await api.guestProfile.confirmEmailChange(hash);
      return data?.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const confirmEmailChangeResend = createAsyncThunk(
  'guestProfile/confirmEmailChangeResend',
  async () => {
    try {
      const { data } = await api.guestProfile.confirmEmailChangeResend();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getProfile,
  changeAvatar,
  updateUser,
  confirmPhoneChange,
  confirmPhoneChangeResend,
  confirmEmailChange,
  confirmEmailChangeResend,
};

export { thunks };
