import { userHoldings } from 'api/modules/user/userHoldings';
import { userSettings } from 'api/modules/user/settings';
import { unregisteredUserMarket } from 'api/modules/general/market';
import { userTrading } from 'api/modules/user/userTrading';
import { auth } from './modules/auth/auth';
import { additionalData } from './modules/additional';
import { guestProfile } from './modules/user/guestProfile';
import { listerProfile } from './modules/user/listerProfile';
import { userNotifications } from './modules/user/notifications';
import { userMarket } from './modules/user/userMarket';
import { userMoney } from './modules/user/userMoney';
import { general } from './modules/general/general';
import { holdings } from './modules/general/holdings';
import { userDashboard } from './modules/user/userDashboard';

// ADMIN
import { adminDashboard } from './modules/admin/adminDashboard';
import { userService } from './modules/admin/userService';
import { listerServices } from './modules/admin/listerServices';
import { userReports } from './modules/admin/userReports';
import { systemSetup } from './modules/admin/systemSetup';
import { adminSetup } from './modules/admin/adminSetup';
import { financialServices } from './modules/admin/financialServices';
import { securitySetup } from './modules/admin/securitySetup';
import { userFunds } from './modules/admin/userFunds';

export const api = {
  auth,
  additionalData,
  guestProfile,
  userSettings,
  listerProfile,
  userNotifications,
  userDashboard,
  userHoldings,
  userMarket,
  userTrading,
  userMoney,
  general,
  holdings,
  unregisteredUserMarket,

  // ADMIN
  adminDashboard,
  userService,
  listerServices,
  userReports,
  systemSetup,
  adminSetup,
  financialServices,
  securitySetup,
  userFunds,
};
