import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { prepareDateToServer } from 'utils/prepareDate';

const getArchived = createAsyncThunk(
  'securityArchived/getArchived',
  async ({ pageSize, pageIndex, sortCol, sortDir, filters }) => {
    try {
      const params = {
        perPage: pageSize,
        page: pageIndex,
        sortCol,
        sortDir,
        dateFrom: filters.dateFrom
          ? prepareDateToServer(filters.dateFrom)
          : filters.dateFrom,
        dateTo: filters.dateTo
          ? prepareDateToServer(filters.dateTo)
          : filters.dateTo,
      };
      const { data } = await api.securitySetup.getArchived(params);
      return { data: data.data, meta: data.meta };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getArchived,
};

export { thunks };
