export const notificationItemModel = (
  id = Date.now(),
  type = 'default',
  title = '',
  body = '',
  time = Date.now(),
  isRead = true
) => {
  return {
    id,
    type,
    title,
    body,
    time,
    isRead,
  };
};
