import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getServices = createAsyncThunk(
  'financialServices/getServices',
  async () => {
    try {
      const { data } = await api.financialServices.getServices();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getServices,
};

export { thunks };
