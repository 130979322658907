import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { prepareDateToServer } from 'utils/prepareDate';

const getTransactionsList = createAsyncThunk(
  'userMarket/getTransactionsList',
  async ({ pageSize, pageIndex, sortCol, sortDir, filters }, { getState }) => {
    const { offset } =
      getState().settingsPage.defaultInterfaceSettings.timeZone;

    try {
      const { data } = await api.userMarket.getTransactionsList({
        perPage: pageSize,
        page: pageIndex,
        sortCol,
        sortDir,
        ...filters,
        dateFrom: filters.dateFrom
          ? prepareDateToServer(filters.dateFrom, offset)
          : filters.dateFrom,
        dateTo: filters.dateTo
          ? prepareDateToServer(filters.dateTo, offset)
          : filters.dateTo,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getTransactionsList,
};

export { thunks };
