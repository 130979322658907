import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const systemSetup = {
  getData() {
    return http.get(WEB_API_ROUTES.admin.systemSetup.getData);
  },

  createMessage(fd) {
    return http.post(WEB_API_ROUTES.admin.systemSetup.createMessage, fd);
  },

  createNews(fd) {
    return http.post(WEB_API_ROUTES.admin.systemSetup.createNews, fd);
  },

  getMessage(messageId) {
    return http.get(
      WEB_API_ROUTES.admin.systemSetup.messageAction.replace(
        '{message}',
        messageId
      )
    );
  },

  getNews(newsId) {
    return http.get(
      WEB_API_ROUTES.admin.systemSetup.newsAction.replace('{news}', newsId)
    );
  },

  editMessage(messageId, fd) {
    return http.put(
      WEB_API_ROUTES.admin.systemSetup.messageAction.replace(
        '{message}',
        messageId
      ),
      fd
    );
  },

  editNews(newsId, fd) {
    return http.put(
      WEB_API_ROUTES.admin.systemSetup.newsAction.replace('{news}', newsId),
      fd
    );
  },

  deleteMessage(messageId) {
    return http.delete(
      WEB_API_ROUTES.admin.systemSetup.messageAction.replace(
        '{message}',
        messageId
      )
    );
  },

  deleteNews(newsId) {
    return http.delete(
      WEB_API_ROUTES.admin.systemSetup.newsAction.replace('{news}', newsId)
    );
  },

  changeMessageStatus(messageId) {
    return http.post(
      WEB_API_ROUTES.admin.systemSetup.changeMessageStatus.replace(
        '{message}',
        messageId
      )
    );
  },

  changeNewsStatus(newsId) {
    return http.post(
      WEB_API_ROUTES.admin.systemSetup.changeNewsStatus.replace(
        '{news}',
        newsId
      )
    );
  },

  resendMessage(messageId) {
    return http.post(
      WEB_API_ROUTES.admin.systemSetup.resendMessage.replace(
        '{message}',
        messageId
      )
    );
  },
};
