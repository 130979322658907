import styled from '@emotion/styled/macro';
import { css, keyframes } from '@emotion/react';

import { variants } from 'components/RSpinner';

const rotate = keyframes`
  100%{
    transform: rotate(360deg);
  }
`;

const Dot = styled.div`
  position: absolute;
  border-radius: 50%;

  &:nth-of-type(1) {
    top: 12%;
    left: 50%;
    transform: translate(-50%, -12%);
  }

  &:nth-of-type(2) {
    top: 24%;
    right: 15%;
    transform: translate(-15%, -24%);
  }

  &:nth-of-type(3) {
    bottom: 24%;
    right: 15%;
    transform: translate(-15%, -24%);
  }

  &:nth-of-type(4) {
    bottom: 12%;
    left: 50%;
    transform: translate(-50%, -12%);
  }

  &:nth-of-type(5) {
    bottom: 24%;
    left: 15%;
    transform: translate(-15%, -24%);
  }

  &:nth-of-type(6) {
    top: 24%;
    left: 15%;
    transform: translate(-15%, -24%);
  }
`;

const Wrap = styled.div(
  ({ size, variant, theme, css: styles }) => css`
    position: relative;
    width: ${size}px;
    height: ${size}px;
    animation: ${rotate} 1s linear infinite;

    ${Dot} {
      width: ${size / 8}px;
      height: ${size / 8}px;
      background-color: ${variant === variants.PRIMARY
        ? theme.white
        : theme.spanishGray};
    }

    ${styles};
  `
);

export { Wrap, Dot };
