import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getTradingSetup = createAsyncThunk(
  'general/getTradingSetup',
  async () => {
    try {
      const { data } = await api.general.getTradingSetup();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateTradingSetup = createAsyncThunk(
  'general/updateTradingSetup',
  async (fd) => {
    try {
      const { data } = await api.general.updateTradingSetup(fd);
      toast.success('Trading setup has been successfully updated');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getStripeFee = createAsyncThunk('general/getStripeFee', async () => {
  try {
    const { data } = await api.general.getStripeFee();
    return data.message;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const thunks = {
  getTradingSetup,
  updateTradingSetup,
  getStripeFee,
};

export { thunks };
