import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getCountries = createAsyncThunk('additional/getCountries', async () => {
  try {
    const {
      data: { data },
    } = await api.additionalData.getCountries();
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getLanguages = createAsyncThunk('additional/getLanguages', async () => {
  try {
    const {
      data: { data },
    } = await api.additionalData.getLanguages();
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getTimeZones = createAsyncThunk('additional/getTimeZones', async () => {
  try {
    const {
      data: { data },
    } = await api.additionalData.getTimeZones();
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getContactInformation = createAsyncThunk(
  'additional/getContactInformation',
  async () => {
    try {
      const {
        data: { data },
      } = await api.additionalData.contactInformation();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getCountries,
  getLanguages,
  getTimeZones,
  getContactInformation,
};

export { thunks };
