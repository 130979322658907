const selectors = {
  data: (state) => state.securitySetup.data,
  setupLogin: (state) => {
    return state.securitySetup.data?.login || {};
  },
  fetchingStatus: (state) => state.securitySetup.fetchingStatus,
  popupIsVisible: (state) => state.securitySetup.popupIsVisible,
  timerDurationInMinutes: (state) => state.securitySetup.timerDurationInMinutes,
};

export { selectors };
