import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { rating } from 'utils/const';
import { handleServerErrors } from 'utils/serverErrors';
import { checkMultiselectOptionAll } from 'utils/prepareFields';

const getMarketList = createAsyncThunk(
  'unregisteredUser/getMarketList',
  async ({ pageSize, pageIndex, sortCol, sortDir, filters }) => {
    try {
      const { data } = await api.unregisteredUserMarket.getMarketList({
        perPage: pageSize,
        page: pageIndex,
        sortCol,
        sortDir,
        ...filters,
        rating: checkMultiselectOptionAll(filters.rating, Object.keys(rating)),
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getMarketList,
};

export { thunks };
