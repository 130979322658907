import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  data: {},
  fetchingStatus: status.IDLE,
};

const slice = createSlice({
  name: 'adminDashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getDashboard.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getDashboard.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getDashboard.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      });
  },
});

export const adminDashboard = {
  actions: slice.actions,
  selectors,
  thunks,
};

export default slice.reducer;
