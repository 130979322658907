import yup from 'plugins/yup-config';

import { transformNaN, transformObject } from 'utils/prepare';

const defaultValues = {
  type: {},
  dateFrom: null,
  dateTo: null,
};

const schema = yup.object().shape({
  type: yup.object().transform(transformObject).nullable().required(),
  dateFrom: yup.number().nullable().transform(transformNaN),
  dateTo: yup.number().nullable().transform(transformNaN),
});

export { defaultValues, schema };
