import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { ListerProfileModel } from 'resources/listerProfile';
import { thunks as guestProfileThunks } from 'store/user/guestProfile/thunks';
import { twitterData } from 'models/twitter-data';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  profile: {},
  twitterVerificationData: null,
  profileStatus: status.IDLE,
  listerData: {},
  verificationLink: null,
  listerDataStatus: status.IDLE,
  updatingListerProfileStatus: status.IDLE,
  updatingListerPhotoStatus: status.IDLE,
  gettingVerificationLinkStatus: status.IDLE,
  sendingVerificationParamsStatus: status.IDLE,
};

const slice = createSlice({
  name: 'listerProfile',
  initialState: { ...initialState },
  reducers: {
    UPDATE_PROFILE: (state, { payload }) => {
      state.profile = { ...state.profile, ...payload };
    },
    SET_UPDATING_LISTER_PROFILE_STATUS: (state, { payload }) => {
      state.updatingListerProfileStatus = payload;
    },
    RESET_PROFILE_STATUS: (state) => {
      state.profileStatus = status.IDLE;
    },
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        guestProfileThunks.getProfile.fulfilled,
        (state, { payload: { listerStatus } }) => {
          state.profile = { ...state.profile, status: listerStatus };
        }
      )

      .addCase(thunks.getProfile.pending, (state) => {
        state.profileStatus = status.PENDING;
      })
      .addCase(thunks.getProfile.fulfilled, (state, { payload }) => {
        const { data, offset } = payload;
        const listerProfileModel = new ListerProfileModel(data);
        state.profile = listerProfileModel.get(offset);
        state.profileStatus = status.SUCCESS;
      })
      .addCase(thunks.getProfile.rejected, (state) => {
        state.profileStatus = status.FAIL;
      })

      .addCase(thunks.getListerData.pending, (state) => {
        state.listerDataStatus = status.PENDING;
      })
      .addCase(thunks.getListerData.fulfilled, (state, { payload }) => {
        state.listerData = payload;
        state.listerDataStatus = status.SUCCESS;
      })
      .addCase(thunks.getListerData.rejected, (state) => {
        state.listerDataStatus = status.FAIL;
      })

      .addCase(thunks.updateListerProfile.pending, (state) => {
        state.updatingListerProfileStatus = status.PENDING;
      })
      .addCase(thunks.updateListerProfile.fulfilled, (state, { payload }) => {
        state.profile = { ...state.profile, ...payload };
        state.updatingListerProfileStatus = status.SUCCESS;
      })
      .addCase(thunks.updateListerProfile.rejected, (state) => {
        state.updatingListerProfileStatus = status.FAIL;
      })

      .addCase(thunks.updateListerPhoto.pending, (state) => {
        state.updatingListerPhotoStatus = status.PENDING;
      })
      .addCase(thunks.updateListerPhoto.fulfilled, (state, { payload }) => {
        state.profile.photoPath = payload.photoPath;
        state.updatingListerPhotoStatus = status.SUCCESS;
      })
      .addCase(thunks.updateListerPhoto.rejected, (state) => {
        state.updatingListerPhotoStatus = status.FAIL;
      })

      .addCase(
        guestProfileThunks.changeAvatar.fulfilled,
        (state, { payload }) => {
          state.profile.photoPath = payload.data.photo;
        }
      )

      .addCase(thunks.getVerificationLink.pending, (state) => {
        state.gettingVerificationLinkStatus = status.PENDING;
      })
      .addCase(thunks.getVerificationLink.fulfilled, (state, { payload }) => {
        state.verificationLink = payload.url;
        state.gettingVerificationLinkStatus = status.SUCCESS;
      })
      .addCase(thunks.getVerificationLink.rejected, (state) => {
        state.gettingVerificationLinkStatus = status.FAIL;
      })

      .addCase(thunks.sendTwitterVerificationParams.pending, (state) => {
        state.sendingVerificationParamsStatus = status.PENDING;
      })
      .addCase(
        thunks.sendTwitterVerificationParams.fulfilled,
        (state, { payload }) => {
          state.twitterVerificationData = twitterData(payload);
          state.sendingVerificationParamsStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.sendTwitterVerificationParams.rejected, (state) => {
        state.sendingVerificationParamsStatus = status.FAIL;
      });
  },
});

const listerProfile = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { listerProfile };
export default slice.reducer;
