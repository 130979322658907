const appRoles = {
  // Unverified user
  GUEST: 'Guest', // before admin will approve
  // User roles
  P_USER: 'Portal User', // after admin approved
  P_LISTER: 'Portal Lister', // after apply for lister
  // Admin roles
  ADMIN: 'Support Admin',
  F_ADMIN: 'Financial Admin',
  S_OFFICER: 'System Security Officer',
  S_ADMIN: 'Super Admin',
  L_ADMIN: 'Lister Admin',
  R_ADMIN: 'Report Admin',
};

const userAppRoles = [appRoles.GUEST, appRoles.P_USER, appRoles.P_LISTER];

const adminAppRoles = [
  appRoles.ADMIN,
  appRoles.F_ADMIN,
  appRoles.S_OFFICER,
  appRoles.S_ADMIN,
  appRoles.L_ADMIN,
  appRoles.R_ADMIN,
];

export { appRoles, userAppRoles, adminAppRoles };
