const selectors = {
  influencerId: (state) => state.marketTrading.id,
  influencerName: (state) => state.marketTrading.influencerName,
  mint: (state) => state.marketTrading.mint,
  priceInfo: (state) => state.marketTrading.priceInfo,
  balance: (state) => state.marketTrading.balance,
  influencer: (state) => state.marketTrading.influencer,
  lineChart: (state) => state.marketTrading.lineChart,
  candleChart: (state) => state.marketTrading.candleChart,
  fees: (state) => state.marketTrading.fees,
  ordersList: (state) => state.marketTrading.ordersList,
  pagination: (state) => state.marketTrading.pagination,
  fetchingOrdersStatus: (state) => state.marketTrading.fetchingOrdersStatus,
  buyMarketTokensStatus: (state) => state.marketTrading.buyMarketTokensStatus,
  sellMarketTokensStatus: (state) => state.marketTrading.sellMarketTokensStatus,
  fetchingInfluencerTradingStatus: (state) =>
    state.marketTrading.fetchingInfluencerTradingStatus,
  getLineChartStatus: (state) => state.marketTrading.getLineChartStatus,
  getCandleChartStatus: (state) => state.marketTrading.getCandleChartStatus,
  cancellingOrderStatus: (state) => state.marketTrading.cancellingOrderStatus,
};

export { selectors };
