import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';

import { APP_ROUTES } from 'router/appRoutes';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hasError !== this.state.hasError && this.state.hasError) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, info) {
    console.log(error, info.componentStack);
  }

  render() {
    const { children } = this.props;

    if (this.state.hasError) {
      return <Redirect to={APP_ROUTES.error} />;
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};
