import { useHistory } from 'react-router-dom';

import { RButton } from 'components/Form/RButton';
import PageError from 'assets/img/global/img/page-error-img.svg';
import { APP_ROUTES } from 'router/appRoutes';
import { Wrap, Img, Heading, Paragraph, ButtonContainer } from './styled';

function ErrorPage() {
  const history = useHistory();

  const onButtonClick = () => {
    history.replace(APP_ROUTES.guest.dashboard);
  };

  return (
    <Wrap>
      <Img
        src={PageError}
        alt=""
      />
      <Heading>Oops, something went wrong</Heading>
      <Paragraph>
        Oops, it seems like we hit a snag! <br />
        We apologize for the inconvenience. Our team is already on it, working
        hard to fix the issue. Thank you for your patience and understanding.
        We&apos;ll have things up and running smoothly again in no time!
      </Paragraph>
      <ButtonContainer>
        <RButton
          height={44}
          variant="contained"
          fullWidth
          onClick={onButtonClick}
        >
          To main screen
        </RButton>
      </ButtonContainer>
    </Wrap>
  );
}

export default ErrorPage;
