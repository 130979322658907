import { createSlice } from '@reduxjs/toolkit';

import { pagination } from 'models/pagination';
import { status } from 'utils/const';
import {
  accountStatusOptions,
  tradingStatusOptions,
  verificationOptions,
} from 'components/Admin/UserServicePage/UserServiceFilters/config';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  list: [],
  pagination: {},
  filters: {
    verificationStatus: verificationOptions[0],
    userStatus: accountStatusOptions[0],
    tradingStatus: tradingStatusOptions[0],
    username: null,
  },
  listStatus: status.IDLE,
  updateUserStatus: status.IDLE,
};

const slice = createSlice({
  name: 'userService',
  initialState: { ...initialState },
  reducers: {
    SET_FILTERS: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
    UPDATE_USER_STATUS: (state, { payload }) => {
      const { userId, status } = payload;
      state.list = state.list.map((user) => {
        if (user.id === userId) {
          return { ...user, status };
        }
        return user;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getUsers.pending, (state) => {
        state.listStatus = status.PENDING;
      })
      .addCase(thunks.getUsers.fulfilled, (state, { payload }) => {
        const {
          meta: { current_page, from, to, total, per_page },
          data,
        } = payload;
        state.list = data;
        state.pagination = pagination(current_page, from, to, total, per_page);
        state.listStatus = status.SUCCESS;
      })
      .addCase(thunks.getUsers.rejected, (state) => {
        state.listStatus = status.FAIL;
      })

      .addCase(thunks.updateUserStatus.pending, (state) => {
        state.updateUserStatus = status.PENDING;
      })
      .addCase(thunks.updateUserStatus.fulfilled, (state) => {
        state.updateUserStatus = status.SUCCESS;
      })
      .addCase(thunks.updateUserStatus.rejected, (state) => {
        state.updateUserStatus = status.FAIL;
      });
  },
});

const userService = {
  actions: slice.actions,
  selectors,
  thunks,
};

export { userService };
export default slice.reducer;
