import { createSlice } from '@reduxjs/toolkit';

import { pagination } from 'models/pagination';
import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  dividend: {},
  list: [],
  pagination: {},
  filters: {
    search: null,
  },
  fetchingDividendDetailsStatus: status.IDLE,
  fetchingPaymentsStatus: status.IDLE,
  sendDividendsAndReportStatus: status.IDLE,
  exportCsvStatus: status.IDLE,
};

const slice = createSlice({
  name: 'dividendDetails',
  initialState,
  reducers: {
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
    SET_FILTERS: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getDividendDetails.pending, (state) => {
        state.fetchingDividendDetailsStatus = status.PENDING;
      })
      .addCase(thunks.getDividendDetails.fulfilled, (state, { payload }) => {
        state.dividend = payload;
        state.fetchingDividendDetailsStatus = status.SUCCESS;
      })
      .addCase(thunks.getDividendDetails.rejected, (state) => {
        state.fetchingDividendDetailsStatus = status.FAIL;
      })

      .addCase(thunks.getPayments.pending, (state) => {
        state.fetchingPaymentsStatus = status.PENDING;
      })
      .addCase(thunks.getPayments.fulfilled, (state, { payload }) => {
        const { data, meta } = payload;
        const { current_page, from, to, total, per_page } = meta;
        state.list = data;
        state.pagination = pagination(current_page, from, to, total, per_page);
        state.fetchingPaymentsStatus = status.SUCCESS;
      })
      .addCase(thunks.getPayments.rejected, (state) => {
        state.fetchingPaymentsStatus = status.FAIL;
      })

      .addCase(thunks.sendDividendsAndReport.pending, (state) => {
        state.sendDividendsAndReportStatus = status.PENDING;
      })
      .addCase(thunks.sendDividendsAndReport.fulfilled, (state) => {
        state.sendDividendsAndReportStatus = status.SUCCESS;
      })
      .addCase(thunks.sendDividendsAndReport.rejected, (state) => {
        state.sendDividendsAndReportStatus = status.FAIL;
      })

      .addCase(thunks.exportCsv.pending, (state) => {
        state.exportCsvStatus = status.PENDING;
      })
      .addCase(thunks.exportCsv.fulfilled, (state) => {
        state.exportCsvStatus = status.SUCCESS;
      })
      .addCase(thunks.exportCsv.rejected, (state) => {
        state.exportCsvStatus = status.FAIL;
      });
  },
});

const dividendDetails = {
  actions: slice.actions,
  selectors,
  thunks,
};

export { dividendDetails };
export default slice.reducer;
