import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { checkOptionAll } from 'utils/prepareFields';
import { prepareDateToServer } from 'utils/prepareDate';

const getRequests = createAsyncThunk(
  'userFunds/getWithdrawalRequests',
  async ({ pageSize, pageIndex, sortCol, sortDir, filters }) => {
    const queryParam = {
      perPage: pageSize,
      page: pageIndex,
      sortCol,
      sortDir,
      ...filters,
      tradeStatus: checkOptionAll(filters.tradeStatus),
      dateFrom: filters.dateFrom
        ? prepareDateToServer(filters.dateFrom)
        : filters.dateFrom,
      dateTo: filters.dateTo
        ? prepareDateToServer(filters.dateTo)
        : filters.dateTo,
    };
    try {
      const { data } = await api.userFunds.getWithdrawRequests(queryParam);
      return {
        data: data.data,
        meta: data.meta,
      };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const approveRequest = createAsyncThunk(
  'userFunds/approveRequest',
  async ({ requestId, code }) => {
    try {
      const { data } = await api.userFunds.approveRequest({
        withdraw_id: requestId,
        code,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const rejectRequest = createAsyncThunk(
  'userFunds/rejectRequest',
  async ({ requestId, desc }) => {
    try {
      const { data } = await api.userFunds.rejectRequest({
        withdraw_id: requestId,
        desc,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getWalletBalance = createAsyncThunk(
  'userFunds/getWalletBalance',
  async () => {
    try {
      const { data } = await api.userFunds.getWalletBalance();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getRequests,
  approveRequest,
  rejectRequest,
  getWalletBalance,
};

export { thunks };
