import yup from 'plugins/yup-config';

const verificationOptions = [
  { value: 'All', label: 'All' },
  { value: 'Verified', label: 'Verified' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Not verified', label: 'Not verified' },
];

const accountStatusOptions = [
  { value: 'All', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'disabled', label: 'Disabled' },
  { value: 'suspended', label: 'Suspended' },
];

const tradingStatusOptions = [
  { value: 'All', label: 'All' },
  { value: 'Active', label: 'Active' },
  { value: 'Paused', label: 'Paused' },
];

const defaultValues = {
  verificationStatus: verificationOptions[0],
  userStatus: accountStatusOptions[0],
  tradingStatus: tradingStatusOptions[0],
};

const schema = yup.object().shape({});

export {
  defaultValues,
  schema,
  verificationOptions,
  accountStatusOptions,
  tradingStatusOptions,
};
