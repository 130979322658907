import PropTypes from 'prop-types';

import { Wrap, Dot } from './styled';

export const variants = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

RSpinner.propTypes = {
  variant: PropTypes.oneOf([variants.PRIMARY, variants.SECONDARY]),
  size: PropTypes.number,
  css: PropTypes.object,
};

export function RSpinner({ variant = variants.PRIMARY, size = 24, css }) {
  return (
    <Wrap
      variant={variant}
      size={size}
      css={css}
    >
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
    </Wrap>
  );
}
