import { prepareSelectField } from 'utils/prepareFields';

const selectors = {
  profile: (state) => state.listerProfile.profile,
  twitterVerificationData: (state) =>
    state.listerProfile.twitterVerificationData,
  verificationLink: (state) => state.listerProfile.verificationLink,
  profileStatus: (state) => state.listerProfile.profileStatus,
  listerDataStatus: (state) => state.listerProfile.listerDataStatus,
  updatingListerProfileStatus: (state) =>
    state.listerProfile.updatingListerProfileStatus,
  gettingVerificationLinkStatus: (state) =>
    state.listerProfile.gettingVerificationLinkStatus,
  sendingVerificationParamsStatus: (state) =>
    state.listerProfile.sendingVerificationParamsStatus,
  education: (state) =>
    prepareSelectField(state.listerProfile.listerData.education),
  martial: (state) =>
    prepareSelectField(state.listerProfile.listerData.martial),
  militaryService: (state) =>
    prepareSelectField(state.listerProfile.listerData.militaryService),
};

export { selectors };
