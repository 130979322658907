import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const general = {
  sendSupport(fd) {
    return http.post(WEB_API_ROUTES.general.support, fd);
  },
  checkPhoneIsExist(params) {
    return http.get(WEB_API_ROUTES.general.phoneIsExist, { params });
  },
  getTradingSetup() {
    return http.get(WEB_API_ROUTES.general.tradingSetup);
  },
  updateTradingSetup(data) {
    return http.post(WEB_API_ROUTES.general.tradingSetup, data);
  },
  getStripeFee() {
    return http.get(WEB_API_ROUTES.general.stripeFee);
  },
  getListersList(params) {
    return http.get(WEB_API_ROUTES.general.getListersList, { params });
  },
  getSecuritySetup() {
    return http.get(WEB_API_ROUTES.general.getSecuritySetup);
  },
};
