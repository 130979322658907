import { createSlice } from '@reduxjs/toolkit';

import { pagination } from 'models/pagination';
import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  lister: {
    symbol: '',
    photo: '',
    website: '',
    myShards: 0,
    shardsOnHold: 0,
    shardsOnSale: 0,
    price: 0,
    change: { changeDynamic: 'up', priceChange: 0 },
    rating: '-',
  },
  list: [],
  shardsInfo: {
    averagePrice: 0,
    shardsOnSale: 0,
    approximateTotal: 0,
  },
  pagination: {},
  filters: {
    status: { value: 'All', label: 'All' },
  },
  updatingDetails: false,
  listerLoadingStatus: status.IDLE,
  offersLoadingStatus: status.IDLE,
  stopOfferStatus: status.IDLE,
  startOfferStatus: status.IDLE,
  deleteOfferStatus: status.IDLE,
  sellOfferStatus: status.IDLE,
};

const slice = createSlice({
  name: 'listerDetails',
  initialState,
  reducers: {
    SET_FILTERS: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
    RESET_FILTERS: (state) => {
      state.filters = initialState.filters;
    },
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
    SET_UPDATING_DETAILS: (state, { payload }) => {
      state.updatingDetails = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getListerOffers.pending, (state) => {
        state.offersLoadingStatus = status.PENDING;
      })
      .addCase(thunks.getListerOffers.fulfilled, (state, { payload }) => {
        const { meta, data, averagePrice, shardsOnSale, approximateTotal } =
          payload;
        const { current_page, from, to, total, per_page } = meta;
        state.offersLoadingStatus = status.SUCCESS;
        state.list = data;
        state.shardsInfo = { averagePrice, shardsOnSale, approximateTotal };
        state.pagination = pagination(current_page, from, to, total, per_page);
      })
      .addCase(thunks.getListerOffers.rejected, (state) => {
        state.offersLoadingStatus = status.FAIL;
      })

      .addCase(thunks.getListerDetails.pending, (state) => {
        state.listerLoadingStatus = status.PENDING;
      })
      .addCase(thunks.getListerDetails.fulfilled, (state, { payload }) => {
        state.lister = payload;
        state.listerLoadingStatus = status.SUCCESS;
      })
      .addCase(thunks.getListerDetails.rejected, (state) => {
        state.listerLoadingStatus = status.FAIL;
      })

      .addCase(thunks.stopOffer.pending, (state) => {
        state.stopOfferStatus = status.PENDING;
      })
      .addCase(thunks.stopOffer.fulfilled, (state, { payload }) => {
        const itemIndex = state.list.findIndex(
          (item) => item.id === payload.id
        );
        if (itemIndex !== -1) {
          state.list[itemIndex] = payload;
        }
        state.stopOfferStatus = status.SUCCESS;
      })
      .addCase(thunks.stopOffer.rejected, (state) => {
        state.stopOfferStatus = status.FAIL;
      })

      .addCase(thunks.startOffer.pending, (state) => {
        state.startOfferStatus = status.PENDING;
      })
      .addCase(thunks.startOffer.fulfilled, (state, { payload }) => {
        const itemIndex = state.list.findIndex(
          (item) => item.id === payload.id
        );
        if (itemIndex !== -1) {
          state.list[itemIndex] = payload;
        }
        state.startOfferStatus = status.SUCCESS;
      })
      .addCase(thunks.startOffer.rejected, (state) => {
        state.startOfferStatus = status.FAIL;
      })

      .addCase(thunks.deleteSellOffer.pending, (state) => {
        state.deleteOfferStatus = status.PENDING;
      })
      .addCase(thunks.deleteSellOffer.fulfilled, (state) => {
        state.deleteOfferStatus = status.SUCCESS;
      })
      .addCase(thunks.deleteSellOffer.rejected, (state) => {
        state.deleteOfferStatus = status.FAIL;
      })

      .addCase(thunks.sellOffer.pending, (state) => {
        state.sellOfferStatus = status.PENDING;
      })
      .addCase(thunks.sellOffer.fulfilled, (state) => {
        state.sellOfferStatus = status.SUCCESS;
      })
      .addCase(thunks.sellOffer.rejected, (state) => {
        state.sellOfferStatus = status.FAIL;
      });
  },
});

const listerDetails = {
  actions: slice.actions,
  selectors,
  thunks,
};

export { listerDetails };
export default slice.reducer;
