import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const userHoldings = {
  getHoldings(params) {
    return http.get(WEB_API_ROUTES.user.holdings.getHoldings, {
      params,
    });
  },
  getApproximateValue() {
    return http.get(WEB_API_ROUTES.user.holdings.getApproximateValue);
  },
};
