import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { accountStatus } from 'utils/const';
import { handleServerErrors } from 'utils/serverErrors';
import { checkOptionAll } from 'utils/prepareFields';

const getAdmins = createAsyncThunk(
  'adminSetup/getAdmins',
  async ({ filters, pageSize, pageIndex }) => {
    try {
      const params = {
        perPage: pageSize,
        page: pageIndex,
        status: checkOptionAll(filters.status),
        role: checkOptionAll(filters.role),
      };
      const { data } = await api.adminSetup.getAdmins(params);
      return { data: data.data, meta: data.meta };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const addAdmin = createAsyncThunk('adminSetup/addAdmin', async (fd) => {
  try {
    const { data } = await api.adminSetup.addAdmin(fd);
    toast.success(data.message);
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getAdmin = createAsyncThunk('adminSetup/getAdmin', async (adminId) => {
  try {
    const { data } = await api.adminSetup.getAdmin(adminId);
    return data.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const editAdmin = createAsyncThunk(
  'adminSetup/editAdmin',
  async ({ adminId, formData }) => {
    try {
      const { data } = await api.adminSetup.editAdmin(adminId, formData);
      toast.success('Admin data has been successfully updated');
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const changeStatus = createAsyncThunk(
  'adminSetup/changeStatus',
  async (adminId) => {
    try {
      const { data } = await api.adminSetup.changeStatus(adminId);
      if (data.data.status === accountStatus.ACTIVE) {
        toast.success('Account has been successfully activated');
      } else {
        toast.success('Account has been successfully disabled');
      }
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getAdmins,
  addAdmin,
  getAdmin,
  editAdmin,
  changeStatus,
};

export { thunks };
