const selectors = {
  tokens: (s) => s.userTransactions.tokens,
  tokensPagination: (s) => s.userTransactions.tokensPagination,
  tokensFilters: (s) => s.userTransactions.tokensFilters,
  fetchingTokensStatus: (s) => s.userTransactions.fetchingTokensStatus,
  money: (s) => s.userTransactions.money,
  moneyPagination: (s) => s.userTransactions.moneyPagination,
  moneyFilters: (s) => s.userTransactions.moneyFilters,
  fetchingMoneyStatus: (s) => s.userTransactions.fetchingMoneyStatus,
  INXTokens: (s) => s.userTransactions.INXTokens,
  INXTokensPagination: (s) => s.userTransactions.INXTokensPagination,
  INXTokensFilters: (s) => s.userTransactions.INXTokensFilters,
  fetchingINXTokensStatus: (s) => s.userTransactions.fetchingINXTokensStatus,
};

export { selectors };
