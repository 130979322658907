import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const userService = {
  getUsers(params) {
    return http.get(WEB_API_ROUTES.admin.userService.getUsers, { params });
  },
  updateUserStatus(userId, fd) {
    return http.post(
      WEB_API_ROUTES.admin.userService.updateUserStatus.replace(
        '{user}',
        userId
      ),
      fd
    );
  },

  getUserInfo(userId) {
    return http.get(
      WEB_API_ROUTES.admin.userService.getUserInfo.replace('{userId}', userId)
    );
  },
  getTitlesForReject() {
    return http.get(WEB_API_ROUTES.admin.userService.getTitlesForReject);
  },
  rejectUser(fd) {
    return http.post(WEB_API_ROUTES.admin.userService.rejectUser, fd);
  },
  updateUserInfo(userId, fd) {
    return http.put(
      WEB_API_ROUTES.admin.userService.updateUserInfo.replace('{user}', userId),
      fd
    );
  },
  updateUserPhoto(userId, fd) {
    return http.put(
      WEB_API_ROUTES.admin.userService.updateUserPhoto.replace(
        '{user}',
        userId
      ),
      fd
    );
  },
};
