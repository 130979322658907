import { prepareDateFromServer } from 'utils/prepareDate';

class ListerProfileInfoModel {
  constructor(defaultProfile = {}) {
    this.defaultProfile = defaultProfile;
  }

  get(offset) {
    const {
      lister: {
        facebook,
        flickr,
        instagram,
        linkedIn,
        pinterest,
        reddit,
        snapchat,
        tikTok,
        tumblr,
        twitter,
        website,
        youtube,
        listedAt,
        ...restListerData
      },
      ...data
    } = this.defaultProfile;

    return {
      ...data,
      ...restListerData,
      socialLinks: {
        facebook,
        flickr,
        instagram,
        linkedIn,
        pinterest,
        reddit,
        snapchat,
        tikTok,
        tumblr,
        twitter,
        website,
        youtube,
      },
      website,
      listedAt: prepareDateFromServer(listedAt, offset),
    };
  }
}

export { ListerProfileInfoModel };
