const selectors = {
  shards: (state) => state.userReports.shards,
  shardsPagination: (state) => state.userReports.shardsPagination,
  shardsFilters: (state) => state.userReports.shardsFilters,
  shardsFetchingStatus: (state) => state.userReports.shardsFetchingStatus,
  exportShardsTableCsvStatus: (state) =>
    state.userReports.exportShardsTableCsvStatus,
  money: (state) => state.userReports.money,
  moneyPagination: (state) => state.userReports.moneyPagination,
  moneyFilters: (state) => state.userReports.moneyFilters,
  moneyFetchingStatus: (state) => state.userReports.moneyFetchingStatus,
  exportMoneyTableCsvStatus: (state) =>
    state.userReports.exportMoneyTableCsvStatus,
};

export { selectors };
