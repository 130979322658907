import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { pagination } from 'models/pagination';
import { defaultValues } from 'components/User/MoneyPage/Transactions/TransactionsFilter/config';
import { defaultValuesINX } from 'components/User/MoneyPage/Transactions/TransactionsINXTokens/INXTokensFilter/config';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  tokens: [],
  tokensPagination: {},
  tokensFilters: { ...defaultValues },
  fetchingTokensStatus: status.IDLE,
  money: [],
  moneyPagination: {},
  moneyFilters: { ...defaultValues },
  fetchingMoneyStatus: status.IDLE,
  INXTokens: [],
  INXTokensPagination: {},
  INXTokensFilters: { ...defaultValuesINX },
  fetchingINXTokensStatus: status.IDLE,
};

const slice = createSlice({
  name: 'userTransactions',
  initialState,
  reducers: {
    SET_FILTERS: (state, { payload }) => {
      const { key, data } = payload;
      state[key] = { ...state[key], ...data };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getTokens.pending, (state) => {
        state.fetchingTokensStatus = status.PENDING;
      })
      .addCase(thunks.getTokens.fulfilled, (state, { payload }) => {
        const {
          meta: { current_page, from, to, total, per_page },
          data,
        } = payload;
        state.tokens = data;
        state.tokensPagination = pagination(
          current_page,
          from,
          to,
          total,
          per_page
        );
        state.fetchingTokensStatus = status.SUCCESS;
      })
      .addCase(thunks.getTokens.rejected, (state) => {
        state.fetchingTokensStatus = status.FAIL;
      })

      .addCase(thunks.getMoney.pending, (state) => {
        state.fetchingMoneyStatus = status.PENDING;
      })
      .addCase(thunks.getMoney.fulfilled, (state, { payload }) => {
        const {
          meta: { current_page, from, to, total, per_page },
          data,
        } = payload;
        state.money = data;
        state.moneyPagination = pagination(
          current_page,
          from,
          to,
          total,
          per_page
        );
        state.fetchingMoneyStatus = status.SUCCESS;
      })
      .addCase(thunks.getMoney.rejected, (state) => {
        state.fetchingMoneyStatus = status.FAIL;
      })

      .addCase(thunks.getInxTokens.pending, (state) => {
        state.fetchingINXTokensStatus = status.PENDING;
      })
      .addCase(thunks.getInxTokens.fulfilled, (state, { payload }) => {
        const {
          meta: { current_page, from, to, total, per_page },
          data,
        } = payload;
        state.INXTokens = data;
        state.INXTokensPagination = pagination(
          current_page,
          from,
          to,
          total,
          per_page
        );
        state.fetchingINXTokensStatus = status.SUCCESS;
      })
      .addCase(thunks.getInxTokens.rejected, (state) => {
        state.fetchingINXTokensStatus = status.FAIL;
      });
  },
});

const userTransactions = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { userTransactions };
export default slice.reducer;
