import yup from 'plugins/yup-config';

import { transformNaN } from 'utils/prepare';
import { moneyType } from 'utils/const';

const typeOptions = [
  { value: moneyType.FEE, label: 'Fee' },
  { value: moneyType.WITHDRAW, label: 'Withdraw' },
  { value: moneyType.DIVIDENDS, label: 'Dividends' },
];

const schema = yup.object().shape({
  type: yup.array().nullable(),
  dateFrom: yup.number().nullable().transform(transformNaN),
  dateTo: yup.number().nullable().transform(transformNaN),
});

export { typeOptions, schema };
