import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { toast } from 'react-toastify';
import { handleServerErrors } from 'utils/serverErrors';
import { twoFactorAuthentication } from 'utils/const';

const updateNotificationSettings = createAsyncThunk(
  'settingsPage/updateNotificationSettings',
  async (settings) => {
    try {
      const { data } = await api.userSettings.updateNotificationSettings(
        settings
      );
      return {
        data: data?.data,
      };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateInterfaceSettings = createAsyncThunk(
  'settingsPage/updateInterfaceSettings',
  async (settings) => {
    try {
      const { data } = await api.userSettings.updateInterfaceSettings(settings);
      return {
        data: data?.data,
      };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const disable2FA = createAsyncThunk(
  'settingsPage/disable2FA',
  async (twoFaWay) => {
    try {
      const { data } = await api.userSettings.disableTwoFactor(twoFaWay);
      return data?.data[0];
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const enable2FA = createAsyncThunk('settingsPage/enable2FA', async (fd) => {
  try {
    const { data } = await api.userSettings.enableTwoFactor(fd);
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const confirm2FA = createAsyncThunk(
  'settingsPage/confirm2FA',
  async (formData) => {
    try {
      const { data } = await api.userSettings.confirmTwoFactor(formData);
      const { twoFactorWay } = formData;
      if (twoFactorWay === twoFactorAuthentication.TFA_GOOGLE) {
        toast.success('Authentication via mobile app successfully activated');
      }
      if (twoFactorWay === twoFactorAuthentication.TFA_EMAIL) {
        toast.success('Authentication via email successfully activated');
      }
      if (twoFactorWay === twoFactorAuthentication.TFA_PHONE) {
        toast.success('Authentication via sms successfully activated');
      }
      return data?.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getQR = createAsyncThunk('settingsPage/getQR', async () => {
  try {
    const { data } = await api.userSettings.getQR();
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const thunks = {
  updateNotificationSettings,
  updateInterfaceSettings,
  disable2FA,
  enable2FA,
  confirm2FA,
  getQR,
};

export { thunks };
