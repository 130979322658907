import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  data: {},
  stripeFee: null,
  updatingTradeStatus: false,
  fetchingStatus: status.IDLE,
  fetchingStripeFeeStatus: status.IDLE,
  updateTradingSetupStatus: status.IDLE,
};

const slice = createSlice({
  name: 'tradingSetup',
  initialState,
  reducers: {
    SET_UPDATING_TRADE_STATUS: (state, { payload }) => {
      state.updatingTradeStatus = payload;
    },
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getTradingSetup.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getTradingSetup.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getTradingSetup.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      })

      .addCase(thunks.updateTradingSetup.pending, (state) => {
        state.updateTradingSetupStatus = status.PENDING;
      })
      .addCase(thunks.updateTradingSetup.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.updateTradingSetupStatus = status.SUCCESS;
      })
      .addCase(thunks.updateTradingSetup.rejected, (state) => {
        state.updateTradingSetupStatus = status.FAIL;
      })
      .addCase(thunks.getStripeFee.pending, (state) => {
        state.fetchingStripeFeeStatus = status.PENDING;
      })
      .addCase(thunks.getStripeFee.fulfilled, (state, { payload }) => {
        state.stripeFee = payload;
        state.fetchingStripeFeeStatus = status.SUCCESS;
      })
      .addCase(thunks.getStripeFee.rejected, (state) => {
        state.fetchingStripeFeeStatus = status.FAIL;
      });
  },
});

const tradingSetup = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { tradingSetup };
export default slice.reducer;
