import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const updateProfile = createAsyncThunk(
  'guestSettings/updateProfile',
  async (payload) => {
    try {
      const { data } = await api.guestProfile.updateProfile(payload);
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  updateProfile,
};

export { thunks };
