import { createSlice } from '@reduxjs/toolkit';

import { typeOptions } from 'components/Admin/ReportsPage/MoneyTab/Filters/config';
import { pagination } from 'models/pagination';
import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  shards: [],
  shardsPagination: {},
  shardsFilters: {
    priceFrom: null,
    priceTo: null,
    shardsFrom: null,
    shardsTo: null,
    dateFrom: null,
    dateTo: null,
    search: null,
  },
  shardsFetchingStatus: status.IDLE,
  exportShardsTableCsvStatus: status.IDLE,
  money: [],
  moneyPagination: {},
  moneyFilters: {
    type: typeOptions.map(({ value }) => value),
    dateFrom: null,
    dateTo: null,
    search: null,
  },
  moneyFetchingStatus: status.IDLE,
  exportMoneyTableCsvStatus: status.IDLE,
};

const slice = createSlice({
  name: 'userReports',
  initialState,
  reducers: {
    SET_FILTERS: (state, { payload }) => {
      const { data, filterType } = payload;
      state[filterType] = { ...state[filterType], ...data };
    },
    RESET_FILTERS: (state, { payload }) => {
      const { filterType } = payload;
      state[filterType] = initialState[filterType];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getShardsReports.pending, (state) => {
        state.shardsFetchingStatus = status.PENDING;
      })
      .addCase(thunks.getShardsReports.fulfilled, (state, { payload }) => {
        const { meta, data } = payload;
        const { current_page, from, to, total, per_page } = meta;

        state.shards = data;
        state.shardsPagination = pagination(
          current_page,
          from,
          to,
          total,
          per_page
        );
        state.shardsFetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getShardsReports.rejected, (state) => {
        state.shardsFetchingStatus = status.FAIL;
      })

      .addCase(thunks.getMoneyReports.pending, (state) => {
        state.moneyFetchingStatus = status.PENDING;
      })
      .addCase(thunks.getMoneyReports.fulfilled, (state, { payload }) => {
        const { meta, data } = payload;
        const { current_page, from, to, total, per_page } = meta;

        state.money = data;
        state.moneyPagination = pagination(
          current_page,
          from,
          to,
          total,
          per_page
        );
        state.moneyFetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getMoneyReports.rejected, (state) => {
        state.moneyFetchingStatus = status.FAIL;
      })

      .addCase(thunks.exportShardsTableCsv.pending, (state) => {
        state.exportShardsTableCsvStatus = status.PENDING;
      })
      .addCase(thunks.exportShardsTableCsv.fulfilled, (state) => {
        state.exportShardsTableCsvStatus = status.SUCCESS;
      })
      .addCase(thunks.exportShardsTableCsv.rejected, (state) => {
        state.exportShardsTableCsvStatus = status.FAIL;
      })

      .addCase(thunks.exportMoneyTableCsv.pending, (state) => {
        state.exportMoneyTableCsvStatus = status.PENDING;
      })
      .addCase(thunks.exportMoneyTableCsv.fulfilled, (state) => {
        state.exportMoneyTableCsvStatus = status.SUCCESS;
      })
      .addCase(thunks.exportMoneyTableCsv.rejected, (state) => {
        state.exportMoneyTableCsvStatus = status.FAIL;
      });
  },
});

export const userReports = {
  actions: slice.actions,
  selectors,
  thunks,
};

export default slice.reducer;
