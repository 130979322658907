import { createSlice } from '@reduxjs/toolkit';

import { pagination } from 'models/pagination';
import { rating, status } from 'utils/const';

import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  list: [],
  pagination: {},
  filters: {
    priceFrom: null,
    priceTo: null,
    rating: Object.keys(rating),
    shardsFrom: null,
    shardsTo: null,
    search: null,
    newLister: 0,
    inMyWatchList: 0,
  },
  fetchingStatus: status.IDLE,
};

const slice = createSlice({
  name: 'unregisteredUserMarket',
  initialState,
  reducers: {
    SET_FILTERS: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
    RESET_FILTERS: (state) => {
      state.filters = initialState.filters;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getMarketList.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getMarketList.fulfilled, (state, { payload }) => {
        const {
          data,
          meta: { current_page, from, to, total, per_page },
        } = payload;
        state.list = data;
        state.pagination = pagination(current_page, from, to, total, per_page);
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getMarketList.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      });
  },
});

const unregisteredUserMarket = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { unregisteredUserMarket };
export default slice.reducer;
