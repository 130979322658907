import { createSlice } from '@reduxjs/toolkit';

import { notificationItemModel } from 'models/notifications';
import { prepareDateFromServer } from 'utils/prepareDate';
import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  listLatest: [],
  latestUnreadCount: 0,
  listStatic: [],
  listStaticParams: {
    page: 1,
    lastPage: 1,
    type: null,
  },
  listLatestStatus: status.IDLE,
  listStaticStatus: status.IDLE,
  listStaticMoreStatus: status.IDLE,
  markAllAsReadStatus: status.IDLE,
};

const slice = createSlice({
  name: 'userNotifications',
  initialState,
  reducers: {
    SET_STATIC_PARAMS: (state, { payload }) => {
      state.listStaticParams = { ...state.listStaticParams, ...payload };
    },
    RESET_LIST_STATIC: (state) => {
      state.listStatic = [];
      state.listStaticParams = {
        page: 1,
        lastPage: 1,
        type: null,
      };
      state.listStaticStatus = status.IDLE;
    },
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getNotifications.pending, (state) => {
        state.listStaticStatus = status.PENDING;
      })
      .addCase(thunks.getNotifications.fulfilled, (state, { payload }) => {
        const {
          data,
          pag: { page, lastPage },
        } = payload;
        state.listStatic = data;
        state.listStaticParams = { page, lastPage };
        state.listStaticStatus = status.SUCCESS;
      })
      .addCase(thunks.getNotifications.rejected, (state) => {
        state.listStaticStatus = status.FAIL;
      })

      .addCase(thunks.getMoreNotifications.pending, (state) => {
        state.listStaticMoreStatus = status.PENDING;
      })
      .addCase(thunks.getMoreNotifications.fulfilled, (state, { payload }) => {
        const {
          data,
          pag: { page, lastPage },
        } = payload;
        state.listStatic = [...state.listStatic, ...data];
        state.listStaticParams = { page, lastPage };
        state.listStaticMoreStatus = status.SUCCESS;
      })
      .addCase(thunks.getMoreNotifications.rejected, (state) => {
        state.listStaticMoreStatus = status.FAIL;
      })

      .addCase(thunks.getLatest.pending, (state) => {
        state.listLatestStatus = status.PENDING;
      })
      .addCase(thunks.getLatest.fulfilled, (state, { payload }) => {
        const { data, offset } = payload;
        state.listLatest = data.notifications.map((n) => {
          return notificationItemModel(
            n.id,
            n.type,
            n.title,
            n.body,
            prepareDateFromServer(n.time, offset),
            n.isRead
          );
        });
        state.latestUnreadCount = payload.unReadedNotificationsCount;
        state.listLatestStatus = status.SUCCESS;
      })
      .addCase(thunks.getLatest.rejected, (state) => {
        state.listLatestStatus = status.FAIL;
      })

      .addCase(thunks.setMarkAllAsRead.pending, (state) => {
        state.markAllAsReadStatus = status.PENDING;
      })
      .addCase(thunks.setMarkAllAsRead.fulfilled, (state, { payload }) => {
        if (payload.status && state.listStatic.length) {
          state.listStatic = state.listStatic.map((n) => ({
            ...n,
            isRead: true,
          }));
        }
        state.markAllAsReadStatus = status.SUCCESS;
      })
      .addCase(thunks.setMarkAllAsRead.rejected, (state) => {
        state.markAllAsReadStatus = status.FAIL;
      });
  },
});

const userNotifications = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { userNotifications };
export default slice.reducer;
