import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { pagination } from 'models/pagination';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  id: null,
  influencerName: null,
  mint: {
    isMinted: false,
  },
  priceInfo: {
    price: 0,
    dayHigh: 0,
    dayLow: 0,
    dayVolume: 0,
    marketCap: 0,
    change: { changeDynamic: 'up', priceChange: 0.0 },
  },
  fees: { transactionFee: 0, royaltyFee: 0 },
  balance: {
    tokens: 0,
    usdt: 0,
  },
  influencer: {
    tokens: 0,
  },
  lineChart: {
    data: [],
    interval: null,
  },
  candleChart: {
    data: [],
    interval: null,
  },
  ordersList: [],
  pagination: {},
  fetchingOrdersStatus: status.IDLE,
  cancellingOrderStatus: status.IDLE,
  fetchingInfluencerTradingStatus: status.IDLE,
  buyMarketTokensStatus: status.IDLE,
  sellMarketTokensStatus: status.IDLE,
  getLineChartStatus: status.IDLE,
  getCandleChartStatus: status.IDLE,
  updateCandleChartStatus: status.IDLE,
};

const slice = createSlice({
  name: 'trading',
  initialState,
  reducers: {
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
    RESET_STATE: (state) => {
      Object.assign(state, initialState);
    },
    SET_PAGINATION: (state, { payload }) => {
      state.pagination = { ...state.pagination, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getOrdersList.pending, (state) => {
        state.fetchingOrdersStatus = status.PENDING;
      })
      .addCase(thunks.getOrdersList.fulfilled, (state, { payload }) => {
        const { current_page, from, to, total, per_page } = payload.meta;
        state.ordersList = payload.data;
        state.pagination = pagination(current_page, from, to, total, per_page);
        state.fetchingOrdersStatus = status.SUCCESS;
      })
      .addCase(thunks.getOrdersList.rejected, (state) => {
        state.fetchingOrdersStatus = status.FAIL;
      })

      .addCase(thunks.cancelLimitOrder.pending, (state) => {
        state.cancellingOrderStatus = status.PENDING;
      })
      .addCase(thunks.cancelLimitOrder.fulfilled, (state) => {
        state.cancellingOrderStatus = status.SUCCESS;
      })
      .addCase(thunks.cancelLimitOrder.rejected, (state) => {
        state.cancellingOrderStatus = status.FAIL;
      })

      .addCase(thunks.getInfluencerTradingInfo.pending, (state) => {
        state.fetchingInfluencerTradingStatus = status.PENDING;
      })
      .addCase(
        thunks.getInfluencerTradingInfo.fulfilled,
        (state, { payload: data }) => {
          const {
            id,
            symbol,
            amount,
            tokenBalance,
            balance,
            transactionFee,
            royaltyFee,
            mint,
            ...rest
          } = data;
          state.id = id;
          state.influencerName = symbol;
          state.mint = mint;
          state.priceInfo = rest;
          state.fees = { transactionFee, royaltyFee };
          state.balance = { usdt: balance, tokens: tokenBalance || 0 };
          state.influencer.tokens = amount;
          state.fetchingInfluencerTradingStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.getInfluencerTradingInfo.rejected, (state) => {
        state.fetchingInfluencerTradingStatus = status.FAIL;
      })

      .addCase(thunks.buyMarketTokens.pending, (state) => {
        state.buyMarketTokensStatus = status.PENDING;
      })
      .addCase(thunks.buyMarketTokens.fulfilled, (state) => {
        state.buyMarketTokensStatus = status.SUCCESS;
      })
      .addCase(thunks.buyMarketTokens.rejected, (state) => {
        state.buyMarketTokensStatus = status.FAIL;
      })

      .addCase(thunks.sellMarketTokens.pending, (state) => {
        state.sellMarketTokensStatus = status.PENDING;
      })
      .addCase(thunks.sellMarketTokens.fulfilled, (state) => {
        state.sellMarketTokensStatus = status.SUCCESS;
      })
      .addCase(thunks.sellMarketTokens.rejected, (state) => {
        state.sellMarketTokensStatus = status.FAIL;
      })

      .addCase(thunks.getLineChart.pending, (state) => {
        state.getLineChartStatus = status.PENDING;
      })
      .addCase(thunks.getLineChart.fulfilled, (state, { payload }) => {
        // TODO: change format on the backend side
        const data = payload.data.map(({ price, date }) => ({
          time: date,
          value: price,
        }));
        state.lineChart = {
          data,
          // convert interval to milliseconds
          interval: payload.interval_update * 1000,
        };
        state.getLineChartStatus = status.SUCCESS;
      })
      .addCase(thunks.getLineChart.rejected, (state) => {
        state.getLineChartStatus = status.FAIL;
      })

      .addCase(thunks.getCandleChart.pending, (state) => {
        state.getCandleChartStatus = status.PENDING;
      })
      .addCase(thunks.getCandleChart.fulfilled, (state, { payload }) => {
        // TODO: change format on the backend side
        const data = payload.data.map(({ date_time, ...rest }) => ({
          time: date_time,
          ...rest,
        }));
        state.candleChart = {
          data,
          // TODO: return converting to milliseconds (1000)
          interval: payload.interval_update * 100,
        };
        state.getCandleChartStatus = status.SUCCESS;
      })
      .addCase(thunks.getCandleChart.rejected, (state) => {
        state.getCandleChartStatus = status.FAIL;
      })

      .addCase(thunks.updateCandleChart.pending, (state) => {
        state.updateCandleChartStatus = status.PENDING;
      })
      .addCase(thunks.updateCandleChart.fulfilled, (state) => {
        state.updateCandleChartStatus = status.SUCCESS;
      })
      .addCase(thunks.updateCandleChart.rejected, (state) => {
        state.updateCandleChartStatus = status.FAIL;
      });
  },
});

const marketTrading = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { marketTrading };
export default slice.reducer;
