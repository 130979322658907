import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { toast } from 'react-toastify';
import { handleServerErrors } from 'utils/serverErrors';
import { checkOptionAll } from 'utils/prepareFields';

const getUsers = createAsyncThunk('admin/userService', async (params) => {
  const { filters, pageSize, pageIndex } = params;
  const queryParam = filters
    ? {
        perPage: pageSize,
        page: pageIndex,
        verificationStatus: checkOptionAll(filters.verificationStatus),
        userStatus: checkOptionAll(filters.userStatus),
        tradingStatus: checkOptionAll(filters.tradingStatus),
        username: filters.username,
      }
    : {
        perPage: pageSize,
        page: pageIndex,
      };
  try {
    const { data } = await api.userService.getUsers(queryParam);
    return { data: data.data, meta: data.meta };
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const updateUserStatus = createAsyncThunk(
  'admin/updateUserStatus',
  async (formData) => {
    const { userId, fd } = formData;
    try {
      const { data } = await api.userService.updateUserStatus(userId, fd);
      toast.success('User status was successfully updated!');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getUsers,
  updateUserStatus,
};

export { thunks };
