import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { typeOptions } from 'components/Admin/ReportsPage/MoneyTab/Filters/config';
import { handleServerErrors } from 'utils/serverErrors';
import { prepareDateToServer } from 'utils/prepareDate';
import { checkMultiselectOptionAll } from 'utils/prepareFields';

const prepareDateFilters = (filters) => ({
  dateFrom: filters.dateFrom
    ? prepareDateToServer(filters.dateFrom)
    : filters.dateFrom,
  dateTo: filters.dateTo ? prepareDateToServer(filters.dateTo) : filters.dateTo,
});

const getShardsReports = createAsyncThunk(
  'admin/getShardsReports',
  async ({ pageSize, pageIndex, sortCol, sortDir, filters }) => {
    try {
      const params = {
        perPage: pageSize,
        page: pageIndex,
        sortCol,
        sortDir,
        ...filters,
        ...prepareDateFilters(filters),
      };
      const { data } = await api.userReports.getShardsReports(params);
      return { data: data.data, meta: data.meta };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getMoneyReports = createAsyncThunk(
  'admin/getMoneyReports',
  async ({ pageSize, pageIndex, sortCol, sortDir, filters }) => {
    try {
      const params = {
        perPage: pageSize,
        page: pageIndex,
        sortCol,
        sortDir,
        ...filters,
        type: checkMultiselectOptionAll(filters.type, typeOptions),
        ...prepareDateFilters(filters),
      };
      const { data } = await api.userReports.getMoneyReports(params);
      return { data: data.data, meta: data.meta };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const exportShardsTableCsv = createAsyncThunk(
  'admin/exportShardsTableCsv',
  async (filters) => {
    try {
      const params = {
        ...filters,
        ...prepareDateFilters(filters),
      };
      const formData = Object.fromEntries(
        Object.entries(params).filter((item) => item[1])
      );
      const { data } = await api.userReports.exportShardsTableCsv(formData);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);
const exportMoneyTableCsv = createAsyncThunk(
  'admin/exportMoneyTableCsv',
  async (filters) => {
    try {
      const params = {
        ...filters,
        ...prepareDateFilters(filters),
      };
      const formData = Object.fromEntries(
        Object.entries(params).filter((item) => item[1])
      );
      const { data } = await api.userReports.exportMoneyTableCsv(formData);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getShardsReports,
  getMoneyReports,
  exportShardsTableCsv,
  exportMoneyTableCsv,
};

export { thunks };
