import { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import { RLoadingOverlay } from 'components/RLoadingOverlay';
import NotFoundPage from 'pages/General/NotFound';
import { middlewareRoutes } from 'config';

const AdminLayout = lazy(() => import('layouts/AdminLayout'));
const UserLayout = lazy(() => import('layouts/UserLayout'));
const AuthLayout = lazy(() => import('layouts/AuthLayout'));
const GeneralLayout = lazy(() => import('layouts/GeneralLayout'));
const GuestLayout = lazy(() => import('layouts/UnregisteredGuestLayout'));

function App() {
  return (
    <Suspense fallback={<RLoadingOverlay isVisible />}>
      <Switch>
        {middlewareRoutes.map(({ id, exact, path, component }) => (
          <Route
            key={id}
            exact={exact}
            path={path}
            component={component}
          />
        ))}
        <Route
          path="/admin"
          component={AdminLayout}
        />
        <Route
          path="/user"
          component={UserLayout}
        />
        <Route
          path="/general"
          component={GeneralLayout}
        />
        <Route
          path="/guest"
          component={GuestLayout}
        />
        <Route
          path="/"
          component={AuthLayout}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
}

export default App;
