import StripeIcon from 'assets/img/admin/userService/stripe.svg';
import { transformToCapitalizeCase } from 'utils/prepare';

const getServiceIcon = (service) => {
  switch (service) {
    default:
      return StripeIcon;
  }
};

const getServiceUrl = (service) => {
  switch (service) {
    default:
      return 'https://dashboard.stripe.com/login';
  }
};

const walletServicesModel = (services = {}) => {
  return Object.entries(services).map(([key, value]) => ({
    id: key,
    label: transformToCapitalizeCase(key),
    icon: getServiceIcon(key),
    href: getServiceUrl(key),
    fields: [
      // decode base64 to string
      { label: 'Login', value: atob(value.login) },
      { label: 'Password', value: atob(value.password) },
    ],
  }));
};

export { walletServicesModel };
