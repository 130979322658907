import { notificationItemModel } from 'models/notifications';
import {
  prepareDateForNotifications,
  prepareDateFromServer,
} from 'utils/prepareDate';

export const notificationsFormattedList = (list, offset) => {
  if (!Array.isArray(list) || !list.length) {
    return [];
  }

  const formattedTimeCreated = (time) => {
    return prepareDateForNotifications(time);
  };

  const formattedData = list.reduce((acc, n) => {
    acc[formattedTimeCreated(prepareDateFromServer(n.time, offset))] = [];
    return acc;
  }, {});

  list.forEach((n) => {
    formattedData[
      formattedTimeCreated(prepareDateFromServer(n.time, offset))
    ].push(
      notificationItemModel(
        n.id,
        n.type,
        n.title,
        n.body,
        prepareDateFromServer(n.time, offset),
        n.isRead
      )
    );
  });

  return formattedData;
};
