export const APP_ROUTES = {
  auth: {
    signIn: '/',
    resetPassword: '/reset-password',
    createNewPassword: '/create-new-password/:token',
  },
  guest: {
    dashboard: '/guest/dashboard',
    market: '/guest/market',
    help: '/guest/help',
    supportDocumentation: '/guest/help/support-documentation',
  },
  admin: {
    dashboard: '/admin/dashboard',
    userService: '/admin/user-service',
    userServiceInfo: '/admin/user-service/:userId',
    influencerServices: '/admin/influencer-services',
    influencerInfo: '/admin/influencer-services/:influencerId',
    influencerInfoViewTokens: '/admin/influencer-services/:influencerId/tokens',
    adminSetup: '/admin/system-setup/admin-setup',
    adminSetupInfo: '/admin/system-setup/admin-setup/:adminId',
    tradingSetup: '/admin/system-setup/trading-setup',
    securitySetup: '/admin/security-setup',
    securityLogs: '/admin/security-setup/logs',
    reports: '/admin/reports/:slug?',
    digitalWalletServices: '/admin/financial-services/digital-wallet-services',
    dividendPayments: '/admin/financial-services',
    dividendPaymentsInfo: '/admin/financial-services/:dividendId?',
    systemSetup: '/admin/system-setup',
    promotionalMessage:
      '/admin/system-setup/promotional-message/:edit?/:messageId?',
    emergencyMessage:
      '/admin/system-setup/emergency-message/:edit?/:messageId?',
    news: '/admin/system-setup/news/:edit?/:newsId?',
    userFunds: '/admin/user-funds',
  },
  user: {
    dashboard: '/user/dashboard',
    market: '/user/market',
    marketTransactions: '/user/market/transactions',
    marketTrading: '/user/market/:id',
    holdings: '/user/holdings',
    holdingsInfluencerDetails: '/user/holdings/details/:influencerId',
    money: '/user/money',
    help: '/user/help',
    supportDocumentation: '/user/help/support-documentation',
    influencerOptions: '/user/influencer-options',
    influencerProfile: '/user/influencer-profile/:id',
    settings: '/user/settings',
    profileRegistration: '/user/profile-registration',
    profile: '/user/profile/:hash?',
    applyForInfluencer: '/user/settings/apply-for-influencer',
    notifications: '/user/notifications',
  },
  general: {
    support: '/general/support',
  },

  error: '/error',
  twitterVerification: '/twitter/callback',
};
