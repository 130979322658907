import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getSecuritySetup = createAsyncThunk(
  'admin/getSecuritySetup',
  async () => {
    try {
      const { data } = await api.securitySetup.getSecuritySetup();
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateSecuritySetup = createAsyncThunk(
  'admin/updateSecuritySetup',
  async (fd) => {
    try {
      const { data } = await api.securitySetup.updateSecuritySetup(fd);
      toast.success('Successfully updated security setup');
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getSecuritySetup,
  updateSecuritySetup,
};

export { thunks };
