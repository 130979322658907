import { css } from '@emotion/react';

import { device } from 'utils/device';

export const GlobalStyles = (theme) => css`
  body {
    .Toastify__toast-container {
      top: 25px;
      right: 25px;
      width: 360px;
      word-break: break-word;

      .Toastify__toast {
        padding: 0;
        min-height: auto;
        background-color: ${theme.hintOfGreen};
        color: ${theme.maastrichtBlue};
        font-size: 12px;
        line-height: normal;
        font-family: var(--base-font-family);
        box-shadow: 0 4px 6px 1px rgba(74, 80, 87, 0.13);

        &--error {
          background-color: ${theme.error};
          color: ${theme.white};
        }

        &-body {
          padding: 13px 15px;
          margin: 0;
        }

        &-icon {
          width: 16px;
        }
      }

      @media only screen and (${device.sm}) {
        top: 0;
        right: 16px;
        left: 16px;
        width: auto;
      }
    }

    .react-tel-input {
      .country-list {
        padding: 6px 0;
        background-color: ${theme.white};
        border: 1px solid ${theme.gainsboro};
        box-shadow: 0 4px 6px 1px rgba(74, 80, 87, 0.13);
        border-radius: 6px;
        margin: 4px 0 4px -1px;

        &::-webkit-scrollbar {
          height: 4px;
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background: ${theme.romanSilver};
          border-radius: 4px;
        }

        .country {
          &.highlight,
          &:hover {
            background-color: ${theme.honeydew};
          }
        }
      }
    }
  }
`;
