import styled from '@emotion/styled';

const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 16px;
  background-color: ${({ color }) => color};
  z-index: 500;
  overflow-y: auto;
`;

export { Mask };
