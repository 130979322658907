import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getListersList = createAsyncThunk(
  'general/getListersList',
  async (params) => {
    try {
      const { data } = await api.general.getListersList(params);
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getListersList,
};

export { thunks };
