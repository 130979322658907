import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { GuestProfileResource } from 'resources/guestProfile';
import { thunks as frThunks } from 'store/user/guestProfile/fullRegistration/thunks';
import { thunks as listerProfileThunks } from 'store/user/listerProfile/thunks';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  profile: {},
  avatarStatus: status.IDLE,
  profileStatus: status.IDLE,
  updateUserStatus: status.IDLE,
  confirmPhoneChangeStatus: status.IDLE,
  confirmPhoneChangeResendStatus: status.IDLE,
  confirmEmailChangeStatus: status.IDLE,
  confirmEmailChangeResendStatus: status.IDLE,
};

const slice = createSlice({
  name: 'guestProfile',
  initialState: { ...initialState },
  reducers: {
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
    UPDATE_PROFILE_ITEMS: (state, { payload }) => {
      state.profile = { ...state.profile, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getProfile.pending, (state) => {
        state.profileStatus = status.PENDING;
      })
      .addCase(thunks.getProfile.fulfilled, (state, { payload }) => {
        state.profile = GuestProfileResource.get(payload);
        state.profileStatus = status.SUCCESS;
      })
      .addCase(thunks.getProfile.rejected, (state) => {
        state.profileStatus = status.FAIL;
      })

      .addCase(thunks.changeAvatar.pending, (state) => {
        state.avatarStatus = status.PENDING;
      })
      .addCase(thunks.changeAvatar.fulfilled, (state, { payload }) => {
        state.profile.photo = payload.data.photo;
        state.avatarStatus = status.SUCCESS;
      })
      .addCase(thunks.changeAvatar.rejected, (state) => {
        state.avatarStatus = status.FAIL;
      })

      .addCase(
        listerProfileThunks.updateListerPhoto.fulfilled,
        (state, { payload }) => {
          state.profile.photo = payload.photoPath;
        }
      )

      .addCase(thunks.updateUser.pending, (state) => {
        state.updateUserStatus = status.PENDING;
      })
      .addCase(thunks.updateUser.fulfilled, (state, { payload }) => {
        state.profile = { ...state.profile, ...payload.data.user };
        state.updateUserStatus = status.SUCCESS;
      })
      .addCase(thunks.updateUser.rejected, (state) => {
        state.updateUserStatus = status.FAIL;
      })

      .addCase(thunks.confirmPhoneChange.pending, (state) => {
        state.confirmPhoneChangeStatus = status.PENDING;
      })
      .addCase(thunks.confirmPhoneChange.fulfilled, (state, { payload }) => {
        state.profile = { ...state.profile, ...payload };
        state.confirmPhoneChangeStatus = status.SUCCESS;
      })
      .addCase(thunks.confirmPhoneChange.rejected, (state) => {
        state.confirmPhoneChangeStatus = status.FAIL;
      })

      .addCase(thunks.confirmPhoneChangeResend.pending, (state) => {
        state.confirmPhoneChangeResendStatus = status.PENDING;
      })
      .addCase(
        thunks.confirmPhoneChangeResend.fulfilled,
        (state, { payload }) => {
          state.profile = { ...state.profile, ...payload };
          state.confirmPhoneChangeResendStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.confirmPhoneChangeResend.rejected, (state) => {
        state.confirmPhoneChangeResendStatus = status.FAIL;
      })

      .addCase(frThunks.updateProfile.fulfilled, (state, { payload }) => {
        state.profile = { ...state.profile, ...payload };
      })

      .addCase(thunks.confirmEmailChange.pending, (state) => {
        state.confirmEmailChangeStatus = status.PENDING;
      })
      .addCase(thunks.confirmEmailChange.fulfilled, (state, { payload }) => {
        state.profile = { ...state.profile, ...payload };
        state.confirmEmailChangeStatus = status.SUCCESS;
      })
      .addCase(thunks.confirmEmailChange.rejected, (state) => {
        state.confirmEmailChangeStatus = status.FAIL;
      })

      .addCase(thunks.confirmEmailChangeResend.pending, (state) => {
        state.confirmEmailChangeResendStatus = status.PENDING;
      })
      .addCase(thunks.confirmEmailChangeResend.fulfilled, (state) => {
        state.confirmEmailChangeResendStatus = status.SUCCESS;
      })
      .addCase(thunks.confirmEmailChangeResend.rejected, (state) => {
        state.confirmEmailChangeResendStatus = status.FAIL;
      });
  },
});

const guestProfile = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { guestProfile };
export default slice.reducer;
