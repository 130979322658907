import { createSlice } from '@reduxjs/toolkit';

import {
  adminStatusOptions,
  roleOptions,
} from 'components/Admin/AdminSetupPage/Filters/config';
import { pagination } from 'models/pagination';
import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  list: [],
  admin: {},
  pagination: {},
  filters: {
    status: adminStatusOptions[0],
    role: roleOptions[0],
  },
  fetchingStatus: status.IDLE,
  addAdminStatus: status.IDLE,
  fetchingAdminStatus: status.IDLE,
  editAdminStatus: status.IDLE,
  changeStatusStatus: status.IDLE,
};

const slice = createSlice({
  name: 'adminSetup',
  initialState,
  reducers: {
    SET_FILTERS: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
    RESET_FILTERS: (state) => {
      state.filters = initialState.filters;
    },
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getAdmins.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getAdmins.fulfilled, (state, { payload }) => {
        const { meta, data } = payload;
        const { current_page, from, to, total, per_page } = meta;
        state.list = data;
        state.pagination = pagination(current_page, from, to, total, per_page);
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getAdmins.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      })

      .addCase(thunks.addAdmin.pending, (state) => {
        state.addAdminStatus = status.PENDING;
      })
      .addCase(thunks.addAdmin.fulfilled, (state) => {
        state.addAdminStatus = status.SUCCESS;
      })
      .addCase(thunks.addAdmin.rejected, (state) => {
        state.addAdminStatus = status.FAIL;
      })

      .addCase(thunks.getAdmin.pending, (state) => {
        state.fetchingAdminStatus = status.PENDING;
      })
      .addCase(thunks.getAdmin.fulfilled, (state, { payload }) => {
        state.admin = payload;
        state.fetchingAdminStatus = status.SUCCESS;
      })
      .addCase(thunks.getAdmin.rejected, (state) => {
        state.fetchingAdminStatus = status.FAIL;
      })

      .addCase(thunks.editAdmin.pending, (state) => {
        state.editAdminStatus = status.PENDING;
      })
      .addCase(thunks.editAdmin.fulfilled, (state, { payload }) => {
        state.admin = { ...state.admin, ...payload };
        state.editAdminStatus = status.SUCCESS;
      })
      .addCase(thunks.editAdmin.rejected, (state) => {
        state.editAdminStatus = status.FAIL;
      })

      .addCase(thunks.changeStatus.pending, (state) => {
        state.changeStatusStatus = status.PENDING;
      })
      .addCase(thunks.changeStatus.fulfilled, (state, { payload }) => {
        state.admin = { ...state.admin, ...payload };
        state.changeStatusStatus = status.SUCCESS;
      })
      .addCase(thunks.changeStatus.rejected, (state) => {
        state.changeStatusStatus = status.FAIL;
      });
  },
});

export const adminSetup = {
  actions: slice.actions,
  selectors,
  thunks,
};

export default slice.reducer;
