import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const userFunds = {
  getWithdrawRequests(params) {
    return http.get(WEB_API_ROUTES.admin.userFunds.getWithdrawRequests, {
      params,
    });
  },

  approveRequest(data) {
    return http.post(
      WEB_API_ROUTES.admin.userFunds.approveWithdrawalRequest,
      data
    );
  },

  rejectRequest(data) {
    return http.post(
      WEB_API_ROUTES.admin.userFunds.rejectWithdrawalRequest,
      data
    );
  },

  getWalletBalance() {
    return http.get(WEB_API_ROUTES.general.getWallet);
  },
};
