import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const userMarket = {
  getMarketList(params) {
    return http.get(WEB_API_ROUTES.user.market.getMarketList, { params });
  },

  addToWatchlist(data) {
    return http.post(WEB_API_ROUTES.user.market.addToWatchlist, data);
  },

  removeFromWatchlist(influencerId) {
    return http.delete(
      WEB_API_ROUTES.user.market.removeFromWatchlist.replace(
        '{influencerId}',
        influencerId
      )
    );
  },

  getTransactionsList(params) {
    return http.get(WEB_API_ROUTES.user.market.getTransactionsList, { params });
  },
};
