import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const holdings = {
  getListerDetails(influencer) {
    return http.get(
      WEB_API_ROUTES.general.holdings.getListerDetails.replace(
        '{influencer}',
        influencer
      )
    );
  },
  getListerOffers(influencer, params) {
    return http.get(
      WEB_API_ROUTES.general.holdings.getListerOffers.replace(
        '{influencer}',
        influencer
      ),
      { params }
    );
  },
  stopOffer(offer) {
    return http.post(
      WEB_API_ROUTES.general.holdings.stopOffer.replace('{sellOffer}', offer)
    );
  },
  startOffer(offer) {
    return http.post(
      WEB_API_ROUTES.general.holdings.startOffer.replace('{sellOffer}', offer)
    );
  },
  deleteSellOffer(offer) {
    return http.delete(
      WEB_API_ROUTES.general.holdings.deleteSellOffer.replace(
        '{sellOffer}',
        offer
      )
    );
  },

  // TODO: move to admin trading?
  sellOffer(fd) {
    return http.post(WEB_API_ROUTES.user.trading.sellMarketTokens, fd);
  },
};
