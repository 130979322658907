import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  data: {},
  message: {},
  fetchingStatus: status.IDLE,
  createMessageStatus: status.IDLE,
  getMessageStatus: status.IDLE,
  editMessageStatus: status.IDLE,
  deleteMessageStatus: status.IDLE,
  changeMessageStatusStatus: status.IDLE,
  resendMessageStatus: status.IDLE,
};

const slice = createSlice({
  name: 'systemSetup',
  initialState,
  reducers: {
    RESET_MESSAGE: (state) => {
      state.message = initialState.message;
    },
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getData.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getData.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getData.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      })

      .addCase(thunks.createMessage.pending, (state) => {
        state.createMessageStatus = status.PENDING;
      })
      .addCase(thunks.createMessage.fulfilled, (state) => {
        state.createMessageStatus = status.SUCCESS;
      })
      .addCase(thunks.createMessage.rejected, (state) => {
        state.createMessageStatus = status.FAIL;
      })

      .addCase(thunks.createNews.pending, (state) => {
        state.createMessageStatus = status.PENDING;
      })
      .addCase(thunks.createNews.fulfilled, (state) => {
        state.createMessageStatus = status.SUCCESS;
      })
      .addCase(thunks.createNews.rejected, (state) => {
        state.createMessageStatus = status.FAIL;
      })

      .addCase(thunks.getMessage.pending, (state) => {
        state.getMessageStatus = status.PENDING;
      })
      .addCase(thunks.getMessage.fulfilled, (state, { payload }) => {
        state.message = payload;
        state.getMessageStatus = status.SUCCESS;
      })
      .addCase(thunks.getMessage.rejected, (state) => {
        state.getMessageStatus = status.FAIL;
      })

      .addCase(thunks.getNews.pending, (state) => {
        state.getMessageStatus = status.PENDING;
      })
      .addCase(thunks.getNews.fulfilled, (state, { payload }) => {
        state.message = payload;
        state.getMessageStatus = status.SUCCESS;
      })
      .addCase(thunks.getNews.rejected, (state) => {
        state.getMessageStatus = status.FAIL;
      })

      .addCase(thunks.editMessage.pending, (state) => {
        state.editMessageStatus = status.PENDING;
      })
      .addCase(thunks.editMessage.fulfilled, (state, { payload }) => {
        state.message = payload;
        state.editMessageStatus = status.SUCCESS;
      })
      .addCase(thunks.editMessage.rejected, (state) => {
        state.editMessageStatus = status.FAIL;
      })

      .addCase(thunks.editNews.pending, (state) => {
        state.editMessageStatus = status.PENDING;
      })
      .addCase(thunks.editNews.fulfilled, (state, { payload }) => {
        state.message = payload;
        state.editMessageStatus = status.SUCCESS;
      })
      .addCase(thunks.editNews.rejected, (state) => {
        state.editMessageStatus = status.FAIL;
      })

      .addCase(thunks.deleteMessage.pending, (state) => {
        state.deleteMessageStatus = status.PENDING;
      })
      .addCase(thunks.deleteMessage.fulfilled, (state, { payload }) => {
        const { arrayId, messageId } = payload;
        const itemIdx = state.data[arrayId].findIndex(
          (item) => item.id === messageId
        );
        state.data[arrayId] = [
          ...state.data[arrayId].slice(0, itemIdx),
          ...state.data[arrayId].slice(itemIdx + 1),
        ];
        state.deleteMessageStatus = status.SUCCESS;
      })
      .addCase(thunks.deleteMessage.rejected, (state) => {
        state.deleteMessageStatus = status.FAIL;
      })

      .addCase(thunks.deleteNews.pending, (state) => {
        state.deleteMessageStatus = status.PENDING;
      })
      .addCase(thunks.deleteNews.fulfilled, (state, { payload }) => {
        const { arrayId, newsId } = payload;
        const itemIdx = state.data[arrayId].findIndex(
          (item) => item.id === newsId
        );
        state.data[arrayId] = [
          ...state.data[arrayId].slice(0, itemIdx),
          ...state.data[arrayId].slice(itemIdx + 1),
        ];
        state.deleteMessageStatus = status.SUCCESS;
      })
      .addCase(thunks.deleteNews.rejected, (state) => {
        state.deleteMessageStatus = status.FAIL;
      })

      .addCase(thunks.changeMessageStatus.pending, (state) => {
        state.changeMessageStatusStatus = status.PENDING;
      })
      .addCase(thunks.changeMessageStatus.fulfilled, (state, { payload }) => {
        const { data, arrayId } = payload;
        state.data = { ...state.data, [arrayId]: data };
        state.changeMessageStatusStatus = status.SUCCESS;
      })
      .addCase(thunks.changeMessageStatus.rejected, (state) => {
        state.changeMessageStatusStatus = status.FAIL;
      })

      .addCase(thunks.changeNewsStatus.pending, (state) => {
        state.changeMessageStatusStatus = status.PENDING;
      })
      .addCase(thunks.changeNewsStatus.fulfilled, (state, { payload }) => {
        const { data, arrayId } = payload;
        state.data = { ...state.data, [arrayId]: data };
        state.changeMessageStatusStatus = status.SUCCESS;
      })
      .addCase(thunks.changeNewsStatus.rejected, (state) => {
        state.changeMessageStatusStatus = status.FAIL;
      })

      .addCase(thunks.resendMessage.pending, (state) => {
        state.resendMessageStatus = status.PENDING;
      })
      .addCase(thunks.resendMessage.fulfilled, (state) => {
        state.resendMessageStatus = status.SUCCESS;
      })
      .addCase(thunks.resendMessage.rejected, (state) => {
        state.resendMessageStatus = status.FAIL;
      });
  },
});

const systemSetup = {
  actions: slice.actions,
  selectors,
  thunks,
};

export { systemSetup };
export default slice.reducer;
