export const WEB_API_ROUTES = {
  auth: {
    login: '/auth/login',
    getRolesList: '/user/roles',
    register: '/auth/registration',
    logout: '/auth/logout',
    refreshToken: '/oauth/refresh',
    twoFactorChooseWay: '/auth/two-fa-choose-way',
    twoFactorLogin: '/auth/two-fa-login-send-code',
    twoFactorLoginResend: '/auth/two-fa-login-resend',
    resetPassword: '/reset-password/reset-password',
    sendResetPasswordCode: '/reset-password/phone-code',
    changePassword: '/reset-password/password-change',
    changePasswordByOld: '/reset-password/change-password',
  },
  guest: {
    market: {
      getMarketList: '/market',
    },
  },
  user: {
    getGuestProfile: '/user/profile',
    updateUser: '/user',
    updateUserProfile: '/user/update-user-profile',
    profileConfirmPhoneChange: '/user/update-user-profile/confirm-phone',
    profileConfirmPhoneChangeResend:
      '/user/update-user-profile/resend-phone-code',
    profileConfirmEmailChange: '/user/update-user-profile/confirm-email',
    profileConfirmEmailChangeResend:
      '/user/update-user-profile/resend-email-code',
    getProfile: '/user/profile',
    updateProfile: '/user/profile',
    changeAvatar: '/user/profile/photo',
    confirmPhoneChange: '/user/phone/change/confirm',
    confirmPhoneChangeResend: '/user/phone/change/resend',
    confirmEmailChange: '/user/email/change/confirm',
    confirmEmailChangeResend: '/user/email/change/resend',
    getListerProfile: '/user/lister',
    getListerData: '/list/lister-data',
    getMyListerInfo: '/user/lister/my',
    readNotification: '/user/lister/my/read-approve',
    getListerInfo: '/user/lister/{lister}',
    getGraphic: '/user/lister/{lister}/graphic',
    sellOffer: '/market/sell-offer',
    updateListerPhoto: '/user/lister/photo',
    updateListerProfile: '/user/lister',
    contactInformation: '/user/contact-information',
    getTwitterVerificationLink: '/verification/twitter',
    sendTwitterVerificationParams: '/verification/twitter/callback',

    settings: {
      updateNotificationSettings: '/user/notification-settings',
      updateInterfaceSettings: 'user/interface-settings',
      getQR: 'user/setting/qr-code',
      disableTwoFactor: '/user/setting/disable-two-factor',
      enableTwoFactor: '/user/setting/enable-two-factor',
      confirmTwoFactor: '/user/setting/confirm-two-factor',
    },

    holdings: {
      getHoldings: '/holdings',
      getApproximateValue: '/holdings/approximate-value',
      purchaseRequests: '/holdings/purchase-requests',
      purchaseRequestsByLister: `/holdings/purchase-requests/{lister}`,
    },
    notifications: {
      getNotifications: '/notifications',
      getLatest: '/notifications/latest',
      setMarkAllAsRead: 'notifications/mark-all-is-read',
    },
    dashboard: {
      getDashboard: '/user/dashboard',
    },
    market: {
      getMarketList: '/market',
      addToWatchlist: '/market/watchlist',
      removeFromWatchlist: '/market/watchlist/{influencerId}',
      getTransactionsList: '/market/transactions',
    },
    trading: {
      getInfluencerTradingInfo: '/market/info/{influencerId}',
      getTradingOrders: '/market/offers',
      cancelTradingOrder: '/market/offers/{influencerId}',
      buyMarketTokens: '/market/buy',
      sellMarketTokens: '/market/sell',
      buyLimitTokens: '/market/buy-limited',
      sellLimitTokens: '/market/sell-limited',
      getLineChart: '/market/line-graph/{influencerId}',
      getCandlestickChart: '/market/candlestick/{influencerId}/list',
      updateCandlestickChart: '/market/candlestick/{influencerId}/item',
    },
    money: {
      withdrawBalance: '/transactions/withdraw',
      getTokens: '/transactions/shards',
      getINXTokens: '/transactions/inx-tokens',
      getMoney: '/transactions/money',
    },
  },
  additionalData: {
    getCountries: '/list/countries',
    getLanguages: '/list/languages',
    getTimeZones: '/list/time-zones',
  },
  general: {
    support: '/auth/support-request',
    phoneIsExist: '/user/phone/is-exist',
    tradingSetup: '/admin/trading-setup',
    stripeFee: '/stripe/fee',
    getListersList: '/user/lister/autocomplete',
    getSecuritySetup: '/list/security-setup',
    getWallet: '/wallet/balance',
    swapUSDTtoINX: '/swap/usdt-to-inx',
    swapINXtoUSDT: '/swap/inx-to-usdt',
    getTransferUsers: '/transfer/users',
    transferTokensToUser: '/transfer',

    holdings: {
      getListerDetails: '/holdings/lister-detail/{influencer}',
      getListerOffers: '/holdings/lister-detail-table/{influencer}',
      stopOffer: '/holdings/sell-offer/{sellOffer}/stop',
      startOffer: '/holdings/sell-offer/{sellOffer}/start',
      deleteSellOffer: '/holdings/{sellOffer}',
    },
  },
  admin: {
    getDashboard: '/admin/dashboard',
    userService: {
      getUsers: '/admin/all-users',
      updateUserStatus: '/admin/user/{user}/status',
      // user info
      getUserInfo: '/admin/user-info/{userId}',
      getTitlesForReject: '/list/titles-for-reject',
      rejectUser: '/admin/reject-user',
      updateUserInfo: '/admin/user-info/{user}',
      updateUserPhoto: '/admin/user-info/{user}/photo',
    },
    listerServices: {
      getListers: '/admin/lister',
      getListerInfo: '/admin/lister/{lister}',
      updateInfluencerInfo: '/admin/lister/{lister}',
      updateInfluencerPhoto: '/admin/lister/{lister}/photo',
      updateInfluencerRating: '/admin/lister/{lister}/rating',
      updateActions: '/admin/lister/{lister}/shards',
      approveLister: '/admin/lister/{lister}/approve',
      rejectLister: '/admin/lister/{lister}/reject',
      stopTrading: '/admin/lister/{lister}/stop-trading',
      startTrading: '/admin/lister/{lister}/start-trading',
      updateAccountStatus: '/admin/user/{user}/status',
    },
    financialServices: {
      services: '/list/digital-wallets',
      dividends: '/admin/dividends',
      getDividendDetails: '/admin/dividends/{dividends}/payments-header',
      getPayments: '/admin/dividends/{dividends}/payments-table',
      onSendDividendsAndReport: '/admin/dividends/{dividends}/payments',
      exportCsv: '/admin/dividends/{dividends}/export',
    },
    userReports: {
      getShardsReports: '/admin/report/shards',
      getMoneyReports: '/admin/report/money',
      exportShardsTableCsv: '/admin/report/shards/export',
      exportMoneyTableCsv: '/admin/report/money/export',
    },
    securitySetup: {
      securityConfig: '/admin/security-config',
      getLogs: '/admin/security-logs',
      exportLogsCsv: '/admin/security-logs/export',
      getArchived: '/admin/security-logs/archive',
    },
    systemSetup: {
      getData: '/admin/system-setup',
      createMessage: '/admin/message',
      createNews: '/admin/news',
      messageAction: '/admin/message/{message}',
      newsAction: '/admin/news/{news}',
      changeMessageStatus: '/admin/message/{message}/change-status',
      changeNewsStatus: '/admin/news/{news}/change-status',
      resendMessage: '/admin/message/{message}/resend',
    },
    adminSetup: {
      action: '/admin/admin',
      actionById: '/admin/admin/{admin}',
      changeStatus: '/admin/admin/{admin}/change-status',
    },
    userFunds: {
      getWithdrawRequests: '/admin/withdraw',
      approveWithdrawalRequest: '/admin/withdraw/approved',
      rejectWithdrawalRequest: '/admin/withdraw/reject',
    },
  },
};
