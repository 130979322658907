import { prepareDateFromServer } from 'utils/prepareDate';

export const GuestProfileResource = {
  get(data) {
    const { user, profile, accountStatus, settings } = data;
    return {
      ...user,
      ...profile,
      dob: profile?.dob
        ? prepareDateFromServer(profile.dob, settings.timezone.offset)
        : null,
      userAccountStatus: accountStatus,
    };
  },
  update() {},
  remove() {},
};
