import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getHoldings = createAsyncThunk(
  'userHoldings/getHoldings',
  async ({ filters, pageSize, pageIndex, sortCol, sortDir }) => {
    try {
      const { data } = await api.userHoldings.getHoldings({
        perPage: pageSize,
        page: pageIndex,
        sortCol,
        sortDir,
        ...filters,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getApproximateValue = createAsyncThunk(
  'userHoldings/getApproximateValue',
  async () => {
    try {
      const {
        data: { data },
      } = await api.userHoldings.getApproximateValue();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getHoldings,
  getApproximateValue,
};

export { thunks };
