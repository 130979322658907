import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { rating } from 'utils/const';
import { handleServerErrors } from 'utils/serverErrors';
import { checkMultiselectOptionAll } from 'utils/prepareFields';

const getMarketList = createAsyncThunk(
  'user/getMarketList',
  async ({ pageSize, pageIndex, sortCol, sortDir, filters }) => {
    try {
      const { data } = await api.userMarket.getMarketList({
        perPage: pageSize,
        page: pageIndex,
        sortCol,
        sortDir,
        ...filters,
        rating: checkMultiselectOptionAll(filters.rating, Object.keys(rating)),
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const addToWatchlist = createAsyncThunk(
  'user/addToWatchlist',
  async (listerId) => {
    try {
      const { data } = await api.userMarket.addToWatchlist(listerId);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const removeFromWatchlist = createAsyncThunk(
  'user/removeFromWatchlist',
  async (listerId) => {
    try {
      const { data } = await api.userMarket.removeFromWatchlist(listerId);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getMarketList,
  addToWatchlist,
  removeFromWatchlist,
};

export { thunks };
