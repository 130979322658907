import styled from '@emotion/styled';

import { Flex } from 'components/App/GlobalStyled';

const Wrap = styled.div`
  ${Flex({ justify: 'center', align: 'center' })};
  flex-direction: column;
  flex: 1;
  text-align: center;
  height: 100vh;
`;

const Img = styled.img`
  width: 100%;
  height: auto;
  max-width: 227px;
  margin-bottom: 40px;
`;

const Heading = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 24px;
`;

const Paragraph = styled.div`
  font-size: 16px;
  max-width: 595px;
  line-height: 25.6px;
  margin-bottom: 48px;
`;

const ButtonContainer = styled.div`
  max-width: 372px;
  width: 100%;
`;

export { Wrap, Img, Heading, Paragraph, ButtonContainer };
