import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const adminSetup = {
  getAdmins(params) {
    return http.get(WEB_API_ROUTES.admin.adminSetup.action, { params });
  },

  addAdmin(fd) {
    return http.post(WEB_API_ROUTES.admin.adminSetup.action, fd);
  },

  getAdmin(adminId) {
    return http.get(
      WEB_API_ROUTES.admin.adminSetup.actionById.replace('{admin}', adminId)
    );
  },

  editAdmin(adminId, fd) {
    return http.put(
      WEB_API_ROUTES.admin.adminSetup.actionById.replace('{admin}', adminId),
      fd
    );
  },

  changeStatus(adminId) {
    return http.post(
      WEB_API_ROUTES.admin.adminSetup.changeStatus.replace('{admin}', adminId)
    );
  },
};
