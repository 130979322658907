import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  wallet: { address: null, balance: 0, processing: 0 },
  transfer: {
    users: [],
  },
  withdrawStatus: status.IDLE,
  getUserWalletStatus: status.IDLE,
  swapUSDTtoINXStatus: status.IDLE,
  swapINXtoUSDTStatus: status.IDLE,
  transferUsersLoadingStatus: status.IDLE,
  transferTokensToUserStatus: status.IDLE,
};

const walletModel = ({
  address,
  balance,
  balance_inx,
  order_hold,
  processing,
  refresh_timeout,
  exchange,
}) => {
  return {
    address,
    balanceUSDT: balance,
    balanceINX: balance_inx,
    processing,
    exchange,
    timeout: refresh_timeout,
    tokensOnHold: order_hold,
  };
};

const slice = createSlice({
  name: 'userDigitalWallet',
  initialState,
  reducers: {
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getUserWallet.pending, (state) => {
        state.getUserWalletStatus = status.PENDING;
      })
      .addCase(thunks.getUserWallet.fulfilled, (state, { payload }) => {
        state.wallet = walletModel(payload);
        state.getUserWalletStatus = status.SUCCESS;
      })
      .addCase(thunks.getUserWallet.rejected, (state) => {
        state.getUserWalletStatus = status.FAIL;
      })

      .addCase(thunks.withdrawBalance.pending, (state) => {
        state.withdrawStatus = status.PENDING;
      })
      .addCase(thunks.withdrawBalance.fulfilled, (state) => {
        state.withdrawStatus = status.SUCCESS;
      })
      .addCase(thunks.withdrawBalance.rejected, (state) => {
        state.withdrawStatus = status.FAIL;
      })

      .addCase(thunks.swapUSDTtoINX.pending, (state) => {
        state.swapUSDTtoINXStatus = status.PENDING;
      })
      .addCase(thunks.swapUSDTtoINX.fulfilled, (state, { payload }) => {
        state.wallet = walletModel(payload);
        state.swapUSDTtoINXStatus = status.SUCCESS;
      })
      .addCase(thunks.swapUSDTtoINX.rejected, (state) => {
        state.swapUSDTtoINXStatus = status.FAIL;
      })

      .addCase(thunks.swapINXtoUSDT.pending, (state) => {
        state.swapINXtoUSDTStatus = status.PENDING;
      })
      .addCase(thunks.swapINXtoUSDT.fulfilled, (state, { payload }) => {
        state.wallet = walletModel(payload);
        state.swapINXtoUSDTStatus = status.SUCCESS;
      })
      .addCase(thunks.swapINXtoUSDT.rejected, (state) => {
        state.swapINXtoUSDTStatus = status.FAIL;
      })

      .addCase(thunks.getTransferUsers.pending, (state) => {
        state.transferUsersLoadingStatus = status.PENDING;
      })
      .addCase(thunks.getTransferUsers.fulfilled, (state, { payload }) => {
        state.transfer.users = payload.map((u) => ({
          path: u.photo,
          label: u.username,
          value: u.id,
        }));
        state.transferUsersLoadingStatus = status.SUCCESS;
      })
      .addCase(thunks.getTransferUsers.rejected, (state) => {
        state.transferUsersLoadingStatus = status.FAIL;
      })

      .addCase(thunks.transferTokensToUser.pending, (state) => {
        state.transferTokensToUserStatus = status.PENDING;
      })
      .addCase(thunks.transferTokensToUser.fulfilled, (state, { payload }) => {
        state.wallet = walletModel(payload);

        state.transferTokensToUserStatus = status.SUCCESS;
      })
      .addCase(thunks.transferTokensToUser.rejected, (state) => {
        state.transferTokensToUserStatus = status.FAIL;
      });
  },
});

const userDigitalWallet = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { userDigitalWallet };
export default slice.reducer;
