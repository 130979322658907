import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getUserInfo = createAsyncThunk('admin/getUserInfo', async (userId) => {
  try {
    const { data } = await api.userService.getUserInfo(userId);
    return data.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getTitlesForReject = createAsyncThunk(
  'admin/getTitlesForReject',
  async () => {
    try {
      const { data } = await api.userService.getTitlesForReject();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const rejectUser = createAsyncThunk('admin/rejectUser', async (fd) => {
  try {
    const { data } = await api.userService.rejectUser(fd);
    toast.success(data.message);
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const updateUserInfo = createAsyncThunk('admin/updateUserInfo', async (fd) => {
  try {
    const { userId, formData } = fd;
    const { data } = await api.userService.updateUserInfo(userId, formData);
    toast.success('User info was successfully updated!');
    return data?.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const updateUserPhoto = createAsyncThunk(
  'admin/updateUserPhoto',
  async (fd) => {
    try {
      const { userId, formData } = fd;
      const { data } = await api.userService.updateUserPhoto(userId, formData);
      toast.success('User photo was successfully updated!');
      return data?.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getUserInfo,
  getTitlesForReject,
  rejectUser,
  updateUserInfo,
  updateUserPhoto,
};

export { thunks };
