const selectors = {
  token: (state) => state.auth.token,
  roles: (state) => state.auth.roles,
  username: (state) => state.auth.username,
  rateLimit: (state) => state.auth.rateLimit,
  accountAccessError: (state) => state.auth.accountAccessError,
  skipRegistration: (state) => state.auth.skipRegistration,
  accountWasDisabled: (state) => state.auth.accountWasDisabled,
  accountWasDisabledAfterAllAttempts: (state) =>
    state.auth.accountWasDisabledAfterAllAttempts,
  loginStatus: (state) => state.auth.loginStatus,
  registerStatus: (state) => state.auth.registerStatus,
  logoutStatus: (state) => state.auth.logoutStatus,
  twoFactorChooseWayStatus: (state) => state.auth.twoFactorChooseWayStatus,
  twoFactorLoginStatus: (state) => state.auth.twoFactorLoginStatus,
  twoFactorLoginResendStatus: (state) => state.auth.twoFactorLoginResendStatus,
  resetPasswordRequestStatus: (state) => state.auth.resetPasswordRequestStatus,
  sendResetPasswordCodeStatus: (state) =>
    state.auth.sendResetPasswordCodeStatus,
  changePasswordStatus: (state) => state.auth.changePasswordStatus,
  changePasswordByOldStatus: (state) => state.auth.changePasswordByOldStatus,
  getRolesListStatus: (state) => state.auth.getRolesListStatus,
};

export { selectors };
