import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getSecuritySetup = createAsyncThunk(
  'general/getSecuritySetup',
  async () => {
    try {
      const { data } = await api.general.getSecuritySetup();
      return data?.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getSecuritySetup,
};

export { thunks };
