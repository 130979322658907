import { createSlice } from '@reduxjs/toolkit';

import { ListerProfileInfoModel } from 'resources/listerProfile/listerProfileInfo';
import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  data: {},
  timePeriod: 'day',
  updating: false,
  fetchingStatus: status.IDLE,
  fetchingGraphic: status.IDLE,
  sellOfferStatus: status.IDLE,
};

const slice = createSlice({
  name: 'listerProfileInfo',
  initialState,
  reducers: {
    RESET_STATE: (state) => {
      Object.assign(state, initialState);
    },
    SET_UPDATING: (state, { payload }) => {
      state.updating = payload;
    },
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
    UPDATE_TIME_PERIOD: (state, { payload }) => {
      state.timePeriod = payload;
    },
    CLEAR_NOTIFICATIONS: (state) => {
      state.data.notification = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getMyListerInfo.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getMyListerInfo.fulfilled, (state, { payload }) => {
        const { data, offset } = payload;
        const listerProfileInfoModel = new ListerProfileInfoModel(data);
        const { priceDynamic, ...rest } = listerProfileInfoModel.get(offset);
        if (state.updating) {
          state.data = { ...state.data, ...rest };
          state.updating = false;
        } else {
          state.data = { priceDynamic, ...rest };
        }
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getMyListerInfo.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      })

      .addCase(thunks.getListerInfo.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getListerInfo.fulfilled, (state, { payload }) => {
        const { data, offset } = payload;
        const listerProfileInfoModel = new ListerProfileInfoModel(data);
        state.data = listerProfileInfoModel.get(offset);
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getListerInfo.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      })

      .addCase(thunks.getGraphic.pending, (state) => {
        state.fetchingGraphic = status.PENDING;
      })
      .addCase(thunks.getGraphic.fulfilled, (state, { payload }) => {
        state.data.priceDynamic = payload;
        state.fetchingGraphic = status.SUCCESS;
      })
      .addCase(thunks.getGraphic.rejected, (state) => {
        state.fetchingGraphic = status.FAIL;
      })

      .addCase(thunks.sellOffer.pending, (state) => {
        state.sellOfferStatus = status.PENDING;
      })
      .addCase(thunks.sellOffer.fulfilled, (state) => {
        state.updating = true;
        state.sellOfferStatus = status.SUCCESS;
      })
      .addCase(thunks.sellOffer.rejected, (state) => {
        state.sellOfferStatus = status.FAIL;
      });
  },
});

const listerProfileInfo = {
  actions: slice.actions,
  selectors,
  thunks,
};

export { listerProfileInfo };
export default slice.reducer;
