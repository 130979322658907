import yup from 'plugins/yup-config';

import { transformNaN } from 'utils/prepare';

const eventTypeOptions = [
  { value: 'All', label: 'All' },
  { value: 'Login', label: 'Login' },
  { value: 'Registration', label: 'Registration' },
  { value: 'Reset password', label: 'Reset password' },
  { value: 'Account status change', label: 'Account status change' },
  { value: 'Trade status change', label: 'Trade status change' },
  { value: 'Lister verification', label: 'Lister verification' },
  { value: 'User verification', label: 'User verification' },
];

const eventStatusOptions = [
  { value: 'All', label: 'All' },
  { value: 'Failed', label: 'Failed' },
  { value: 'Successful', label: 'Successful' },
  { value: 'Active', label: 'Active' },
  { value: 'Disabled', label: 'Disabled' },
  { value: 'Suspended', label: 'Suspended' },
  { value: 'Verified', label: 'Verified' },
  { value: 'Rejected', label: 'Rejected' },
  { value: 'Pending', label: 'Pending' },
];

const eventStatusStatic = eventStatusOptions.reduce((acc, option) => {
  acc[option.value.toUpperCase()] = option.value;
  return acc;
}, {});

const countriesOptions = [{ value: 'All', label: 'All' }];

const schema = yup.object().shape({
  dateFrom: yup.number().nullable().transform(transformNaN),
  dateTo: yup.number().nullable().transform(transformNaN),
});

export {
  eventTypeOptions,
  eventStatusOptions,
  eventStatusStatic,
  countriesOptions,
  schema,
};
