const selectors = {
  data: (state) => state.tradingSetup.data,
  stripeFee: (state) => state.tradingSetup.stripeFee,
  updatingTradeStatus: (state) => state.tradingSetup.updatingTradeStatus,
  fetchingStatus: (state) => state.tradingSetup.fetchingStatus,
  updateTradingSetupStatus: (state) =>
    state.tradingSetup.updateTradingSetupStatus,
  fetchingStripeFeeStatus: (state) =>
    state.tradingSetup.fetchingStripeFeeStatus,
};

export { selectors };
