const selectors = {
  wallet: (state) => state.userDigitalWallet.wallet,
  transfer: (state) => state.userDigitalWallet.transfer,
  withdrawStatus: (state) => state.userDigitalWallet.withdrawStatus,
  getUserWalletStatus: (state) => state.userDigitalWallet.getUserWalletStatus,
  swapUSDTtoINXStatus: (state) => state.userDigitalWallet.swapUSDTtoINXStatus,
  swapINXtoUSDTStatus: (state) => state.userDigitalWallet.swapINXtoUSDTStatus,
  transferUsersLoadingStatus: (state) =>
    state.userDigitalWallet.transferUsersLoadingStatus,
  transferTokensToUserStatus: (state) =>
    state.userDigitalWallet.transferTokensToUserStatus,
};

export { selectors };
