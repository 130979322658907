const status = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAIL: 'fail',
};

const thunkStatus = {
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
};

const twoFactorAuthentication = {
  TFA_EMAIL: 1,
  TFA_PHONE: 2,
  TFA_GOOGLE: 3,
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const rating = {
  1: 'A+',
  2: 'A',
  3: 'A-',
  4: 'B+',
  5: 'B',
  6: 'B-',
  7: 'C+',
  8: 'C',
  9: 'C-',
  10: 'D',
  11: 'F',
};

const currencies = ['USDT', 'BTC', 'ETH'];

const loginTypes = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
};

const numbers = {
  0: 'zero',
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
};

const supportPhoneNumber = '+18907564597';

const verificationStatus = {
  PENDING: 'Pending',
  NOT_VERIFIED: 'Not verified',
  VERIFIED: 'Verified',
  REGISTERED: 'Listed',
  REJECTED: 'Rejected',
  // RESERVED: 'Reserved',
  // REMOVED: 'Removed',
};

const twoFactorStatus = {
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
};

const saleStatus = {
  ACTIVE: 1,
  CANCELED: 2,
  SOLD: 3,
};

const saleStatusRevert = {
  1: 'Pending',
  2: 'Canceled',
  3: 'Sold',
  4: 'Reserved',
  5: 'Removed',
};

const accountStatus = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  DISABLED: 'disabled',
};

const userDocuments = ['Driver License', 'ID Card', 'State issued ID Card'];

const tradingStatus = {
  ACTIVE: 'Active',
  PAUSED: 'Paused',
};

const messageType = {
  PROMOTIONAL_MESSAGE: 1,
  EMERGENCY_MESSAGE: 2,
  NEWS: 3,
};

const dividendStatus = {
  CREATING: 'Creating',
  CREATED: 'Created',
  PROCESSING: 'Processing',
  FAILED: 'Failed',
  PAID: 'Paid',
};

const shardsMoneyType = {
  BUY: 'BUY',
  SELL: 'SELL',
  PORTFOLIO_INIT: 'PORTFOLIO INITIALIZATION',
};

const moneyType = {
  FEE: 'FEE',
  WITHDRAW: 'WITHDRAW',
  DIVIDENDS: 'DIVIDENDS',
  DEPOSIT: 'DEPOSIT',
};

const chartTypes = {
  line: 'line',
  candles: 'candles',
  area: 'area',
};

const orderTypes = {
  market: 'market',
  limit: 'limit',
};

const transactionTypes = {
  buy: 'buy',
  sell: 'sell',
};

const ethereumAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;

const bitcoinTestAddressRegex =
  /^tb1[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{39,59}$/;

const withdrawStatuses = {
  WITHDRAW_PENDING: 'Pending',
  WITHDRAW_APPROVED: 'Approved',
  WITHDRAW_REJECT: 'Rejected',
};

const withdrawStatusesOriginal = {
  WITHDRAW_PENDING: 'WITHDRAW_PENDING',
  WITHDRAW_APPROVED: 'WITHDRAW_APPROVED',
  WITHDRAW_REJECT: 'WITHDRAW_REJECT',
};

export {
  status,
  thunkStatus,
  twoFactorAuthentication,
  months,
  rating,
  currencies,
  loginTypes,
  numbers,
  supportPhoneNumber,
  verificationStatus,
  twoFactorStatus,
  saleStatus,
  saleStatusRevert,
  accountStatus,
  userDocuments,
  tradingStatus,
  messageType,
  dividendStatus,
  shardsMoneyType,
  moneyType,
  chartTypes,
  orderTypes,
  transactionTypes,
  ethereumAddressRegex,
  bitcoinTestAddressRegex,
  withdrawStatuses,
  withdrawStatusesOriginal,
};
