import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { toast } from 'react-toastify';

const getOrdersList = createAsyncThunk(
  'userMarket/getOrdersList',
  async (influencerId, { getState }) => {
    const {
      marketTrading: { pagination },
    } = getState();

    try {
      const {
        data: { data, meta },
      } = await api.userTrading.getTradingOrders({
        listerId: influencerId,
        // TODO: rework on socket implementation
        perPage: pagination.perPage || pagination.pageSize,
        page: pagination.current || pagination.pageIndex + 1,
      });
      return { data, meta };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const cancelLimitOrder = createAsyncThunk(
  'userMarket/cancelLimitOrder',
  async (influencerId) => {
    try {
      const { data } = await api.userTrading.cancelLimitOrder(influencerId);
      toast.success('You have canceled an order');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getInfluencerTradingInfo = createAsyncThunk(
  'userMarket/getInfluencerTradingInfo',
  async (influencerId) => {
    try {
      const {
        data: { data },
      } = await api.userTrading.getInfluencerTradingInfo(influencerId);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const buyMarketTokens = createAsyncThunk(
  'userMarket/buyMarketTokens',
  async ({ listerId, amount }) => {
    try {
      const res = await api.userTrading.buyMarketTokens({ listerId, amount });
      toast.success('The transaction was successfully completed');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sellMarketTokens = createAsyncThunk(
  'userMarket/sellMarketTokens',
  async ({ listerId, amount }) => {
    try {
      const res = await api.userTrading.sellMarketTokens({ listerId, amount });
      toast.success('The transaction was successfully completed');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const buyLimitTokens = createAsyncThunk(
  'userMarket/buyLimitTokens',
  async (data) => {
    try {
      const res = await api.userTrading.buyLimitTokens(data);
      toast.success('You have created a request to purchase tokens');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sellLimitTokens = createAsyncThunk(
  'userMarket/sellLimitTokens',
  async (data) => {
    try {
      const res = await api.userTrading.sellLimitTokens(data);
      toast.success('You have created a request to sell tokens');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getLineChart = createAsyncThunk(
  'userMarket/getLineChart',
  async ({ influencerId, period }) => {
    try {
      const res = await api.userTrading.getLineChart(influencerId, {
        period,
      });
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getCandleChart = createAsyncThunk(
  'userMarket/getCandleChart',
  async ({ influencerId, period }) => {
    try {
      const res = await api.userTrading.getCandlestickChart(influencerId, {
        period,
      });
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateCandleChart = createAsyncThunk(
  'userMarket/updateCandleChart',
  async ({ influencerId, period }) => {
    try {
      const res = await api.userTrading.updateCandlestickChart(influencerId, {
        period,
      });
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getOrdersList,
  cancelLimitOrder,
  getInfluencerTradingInfo,
  buyMarketTokens,
  buyLimitTokens,
  sellMarketTokens,
  sellLimitTokens,
  getLineChart,
  getCandleChart,
  updateCandleChart,
};

export { thunks };
