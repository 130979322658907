import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getData = createAsyncThunk('systemSetup/getData', async () => {
  try {
    const { data } = await api.systemSetup.getData();
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const createMessage = createAsyncThunk(
  'systemSetup/createMessage',
  async (fd) => {
    try {
      const { data } = await api.systemSetup.createMessage(fd);
      toast.success(data.message);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const createNews = createAsyncThunk('systemSetup/createNews', async (fd) => {
  try {
    const { data } = await api.systemSetup.createNews(fd);
    toast.success(data.message);
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getMessage = createAsyncThunk(
  'systemSetup/getMessage',
  async (messageId) => {
    try {
      const { data } = await api.systemSetup.getMessage(messageId);
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getNews = createAsyncThunk('systemSetup/getNews', async (newsId) => {
  try {
    const { data } = await api.systemSetup.getNews(newsId);
    return data.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const editMessage = createAsyncThunk(
  'systemSetup/editMessage',
  async ({ messageId, formData }) => {
    try {
      const { data } = await api.systemSetup.editMessage(messageId, formData);
      toast.success('Message was successfully updated');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const editNews = createAsyncThunk(
  'systemSetup/editNews',
  async ({ newsId, formData }) => {
    try {
      const { data } = await api.systemSetup.editNews(newsId, formData);
      toast.success('News was successfully updated');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteMessage = createAsyncThunk(
  'systemSetup/deleteMessage',
  async ({ messageId, arrayId }) => {
    try {
      const { data } = await api.systemSetup.deleteMessage(messageId);
      toast.success(data.message);
      return { ...data, messageId, arrayId };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteNews = createAsyncThunk(
  'systemSetup/deleteNews',
  async ({ newsId, arrayId }) => {
    try {
      const { data } = await api.systemSetup.deleteNews(newsId);
      toast.success(data.message);
      return { ...data, newsId, arrayId };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const changeMessageStatus = createAsyncThunk(
  'systemSetup/changeMessageStatus',
  async ({ messageId, arrayId }) => {
    try {
      const {
        data: { data },
      } = await api.systemSetup.changeMessageStatus(messageId);
      toast.success('Successfully changed message visibility');
      return { data, arrayId };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const changeNewsStatus = createAsyncThunk(
  'systemSetup/changeNewsStatus',
  async ({ newsId, arrayId }) => {
    try {
      const {
        data: { data },
      } = await api.systemSetup.changeNewsStatus(newsId);
      toast.success('Successfully changed news visibility');
      return { data, arrayId };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const resendMessage = createAsyncThunk(
  'systemSetup/resendMessage',
  async (messageId) => {
    try {
      const { data } = await api.systemSetup.resendMessage(messageId);
      toast.success(data.message);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getData,
  createMessage,
  createNews,
  getMessage,
  getNews,
  editMessage,
  editNews,
  deleteMessage,
  deleteNews,
  changeMessageStatus,
  changeNewsStatus,
  resendMessage,
};

export { thunks };
