import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  data: {},
  timePeriod: 'day',
  fetchingStatus: status.IDLE,
  updateChartStatus: status.IDLE,
};

const slice = createSlice({
  name: 'userDashboard',
  initialState,
  reducers: {
    RESET_STATE: (state) => {
      Object.assign(state, initialState);
    },
    UPDATE_TIME_PERIOD: (state, { payload }) => {
      state.timePeriod = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getDashboard.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getDashboard.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getDashboard.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      })

      .addCase(thunks.updateChart.pending, (state) => {
        state.updateChartStatus = status.PENDING;
      })
      .addCase(thunks.updateChart.fulfilled, (state, { payload }) => {
        state.data.priceDynamic = payload;
        state.updateChartStatus = status.SUCCESS;
      })
      .addCase(thunks.updateChart.rejected, (state) => {
        state.updateChartStatus = status.FAIL;
      })

      .addCase(thunks.removeFromWatchlist.fulfilled, (state, { payload }) => {
        const { listerId } = payload;
        state.data.watchlistListers = state.data.watchlistListers.filter(
          (lister) => lister.id !== listerId
        );
      });
  },
});

const userDashboard = {
  actions: slice.actions,
  selectors,
  thunks,
};

export { userDashboard };
export default slice.reducer;
