import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from 'store/additional/thunks';
import { selectors } from 'store/additional/selectors';

const initialState = {
  countries: [],
  languages: [],
  timeZones: [],
  contacts: {},
  countriesStatus: status.IDLE,
  languagesStatus: status.IDLE,
  timeZonesStatus: status.IDLE,
  contactsStatus: status.IDLE,
};

const slice = createSlice({
  name: 'additionalData',
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getCountries.pending, (state) => {
        state.countriesStatus = status.PENDING;
      })
      .addCase(thunks.getCountries.fulfilled, (state, { payload }) => {
        state.countries = payload;
        state.countriesStatus = status.SUCCESS;
      })
      .addCase(thunks.getCountries.rejected, (state) => {
        state.countriesStatus = status.FAIL;
      })

      .addCase(thunks.getLanguages.pending, (state) => {
        state.languagesStatus = status.PENDING;
      })
      .addCase(thunks.getLanguages.fulfilled, (state, { payload }) => {
        state.languages = payload;
        state.languagesStatus = status.SUCCESS;
      })
      .addCase(thunks.getLanguages.rejected, (state) => {
        state.languagesStatus = status.FAIL;
      })

      .addCase(thunks.getTimeZones.pending, (state) => {
        state.timeZonesStatus = status.PENDING;
      })
      .addCase(thunks.getTimeZones.fulfilled, (state, { payload }) => {
        state.timeZones = payload;
        state.timeZonesStatus = status.SUCCESS;
      })
      .addCase(thunks.getTimeZones.rejected, (state) => {
        state.timeZonesStatus = status.FAIL;
      })

      .addCase(thunks.getContactInformation.pending, (state) => {
        state.contactsStatus = status.PENDING;
      })
      .addCase(thunks.getContactInformation.fulfilled, (state, { payload }) => {
        state.contacts = payload;
        state.contactsStatus = status.SUCCESS;
      })
      .addCase(thunks.getContactInformation.rejected, (state) => {
        state.contactsStatus = status.FAIL;
      });
  },
});

const additionalData = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { additionalData };
export default slice.reducer;
