import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const userMoney = {
  withdrawBalance(fd) {
    return http.post(WEB_API_ROUTES.user.money.withdrawBalance, fd);
  },
  getUserWallet() {
    return http.get(WEB_API_ROUTES.general.getWallet);
  },
  getTokens(params) {
    return http.get(WEB_API_ROUTES.user.money.getTokens, { params });
  },
  getINXTokens(params) {
    return http.get(WEB_API_ROUTES.user.money.getINXTokens, { params });
  },
  getMoney(params) {
    return http.get(WEB_API_ROUTES.user.money.getMoney, { params });
  },
  swapINXtoUSDT(fd) {
    return http.post(WEB_API_ROUTES.general.swapINXtoUSDT, fd);
  },
  swapUSDTtoINX(fd) {
    return http.post(WEB_API_ROUTES.general.swapUSDTtoINX, fd);
  },
  getTransferUsers(params) {
    return http.get(WEB_API_ROUTES.general.getTransferUsers, { params });
  },
  transferTokensToUser(fd) {
    return http.post(WEB_API_ROUTES.general.transferTokensToUser, fd);
  },
};
