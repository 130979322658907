import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { checkOptionAll } from 'utils/prepareFields';
import { prepareDateToServer } from 'utils/prepareDate';

const getTokens = createAsyncThunk(
  'user/getTokens',
  async (params, { getState }) => {
    const { offset } =
      getState().settingsPage.defaultInterfaceSettings.timeZone;

    try {
      const { filters, pageSize, pageIndex, sortCol, sortDir } = params;
      const { data } = await api.userMoney.getTokens({
        perPage: pageSize,
        page: pageIndex,
        sortCol,
        sortDir,
        type: checkOptionAll(filters.type),
        dateFrom:
          filters.dateFrom !== ''
            ? prepareDateToServer(filters.dateFrom, offset)
            : null,
        dateTo:
          filters.dateFrom !== ''
            ? prepareDateToServer(filters.dateTo, offset)
            : null,
      });
      return { data: data.data, meta: data.meta };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getMoney = createAsyncThunk(
  'user/getMoney',
  async (params, { getState }) => {
    const { offset } =
      getState().settingsPage.defaultInterfaceSettings.timeZone;

    try {
      const { filters, pageSize, pageIndex, sortCol, sortDir } = params;
      const { data } = await api.userMoney.getMoney({
        perPage: pageSize,
        page: pageIndex,
        sortCol,
        sortDir,
        type: checkOptionAll(filters.type),
        dateFrom:
          filters.dateFrom !== ''
            ? prepareDateToServer(filters.dateFrom, offset)
            : null,
        dateTo:
          filters.dateFrom !== ''
            ? prepareDateToServer(filters.dateTo, offset)
            : null,
      });
      return { data: data.data, meta: data.meta };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getInxTokens = createAsyncThunk(
  'user/getInxTokens',
  async (params, { getState }) => {
    const { offset } =
      getState().settingsPage.defaultInterfaceSettings.timeZone;

    try {
      const { filters, pageSize, pageIndex, sortCol, sortDir } = params;
      const { data } = await api.userMoney.getINXTokens({
        perPage: pageSize,
        page: pageIndex,
        sortCol,
        sortDir,
        type: checkOptionAll(filters.type),
        currency: checkOptionAll(filters.currency),
        dateFrom: filters.dateFrom
          ? prepareDateToServer(filters.dateFrom, offset)
          : null,
        dateTo: filters.dateTo
          ? prepareDateToServer(filters.dateTo, offset)
          : null,
      });
      return { data: data.data, meta: data.meta };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getTokens,
  getMoney,
  getInxTokens,
};

export { thunks };
