import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const guestProfile = {
  getProfile() {
    return http.get(WEB_API_ROUTES.user.getGuestProfile);
  },
  changeAvatar(file) {
    return http.post(WEB_API_ROUTES.user.changeAvatar, file);
  },
  updateProfile(data) {
    return http.put(WEB_API_ROUTES.user.updateProfile, data);
  },
  updateUser(data) {
    return http.put(WEB_API_ROUTES.user.updateUser, data);
  },
  updateUserProfile(data) {
    return http.put(WEB_API_ROUTES.user.updateUserProfile, data);
  },
  profileConfirmPhoneChange(data) {
    return http.post(WEB_API_ROUTES.user.profileConfirmPhoneChange, data);
  },
  profileConfirmPhoneChangeResend() {
    return http.post(WEB_API_ROUTES.user.profileConfirmPhoneChangeResend);
  },
  profileConfirmEmailChange(data) {
    return http.post(WEB_API_ROUTES.user.profileConfirmEmailChange, data);
  },
  profileConfirmEmailChangeResend() {
    return http.post(WEB_API_ROUTES.user.profileConfirmEmailChangeResend);
  },
  confirmPhoneChange(data) {
    return http.post(WEB_API_ROUTES.user.confirmPhoneChange, data);
  },
  confirmPhoneChangeResend() {
    return http.post(WEB_API_ROUTES.user.confirmPhoneChangeResend);
  },
  confirmEmailChange(data) {
    return http.post(WEB_API_ROUTES.user.confirmEmailChange, data);
  },
  confirmEmailChangeResend() {
    return http.post(WEB_API_ROUTES.user.confirmEmailChangeResend);
  },
};
