import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getDividendDetails = createAsyncThunk(
  'financialServices/getDividendDetails',
  async (dividendId) => {
    try {
      const { data } = await api.financialServices.getDividendDetails(
        dividendId
      );
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getPayments = createAsyncThunk(
  'financialServices/getPayments',
  async ({ dividendId, filters, pageIndex, pageSize }) => {
    try {
      const params = {
        perPage: pageSize,
        page: pageIndex,
        ...filters,
      };
      const { data } = await api.financialServices.getPayments(
        dividendId,
        params
      );
      return { data: data.data, meta: data.meta };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendDividendsAndReport = createAsyncThunk(
  'financialServices/sendsDividendsAndReport',
  async (dividendId) => {
    try {
      const { data } = await api.financialServices.sendDividendsAndReport(
        dividendId
      );
      toast.success(data.message);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const exportCsv = createAsyncThunk(
  'financialServices/exportCsv',
  async ({ dividendId, fd }) => {
    try {
      const formData = Object.fromEntries(
        Object.entries(fd).filter((item) => item[1])
      );
      const { data } = await api.financialServices.exportCsv(
        dividendId,
        formData
      );
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

export const thunks = {
  getDividendDetails,
  getPayments,
  sendDividendsAndReport,
  exportCsv,
};
