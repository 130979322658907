const selectors = {
  login: (state) => state.adminSecuritySetup.data.login,
  log: (state) => state.adminSecuritySetup.data.log,
  password: (state) => state.adminSecuritySetup.data.password,
  fetchingStatus: (state) => state.adminSecuritySetup.fetchingStatus,
  updateSecuritySetupStatus: (state) =>
    state.adminSecuritySetup.updateSecuritySetupStatus,
};

export { selectors };
