const selectors = {
  data: (state) => state.listerProfileInfo.data,
  timePeriod: (state) => state.listerProfileInfo.timePeriod,
  updating: (state) => state.listerProfileInfo.updating,
  sellOfferStatus: (state) => state.listerProfileInfo.sellOfferStatus,
  fetchingStatus: (state) => state.listerProfileInfo.fetchingStatus,
  fetchingGraphic: (state) => state.listerProfileInfo.fetchingGraphic,
};

export { selectors };
