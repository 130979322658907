import { createSlice } from '@reduxjs/toolkit';

import { selectors } from 'store/user/guestProfile/settingsPage/selectors';
import { thunks } from 'store/user/guestProfile/settingsPage/thunks';
import { thunks as profileThunks } from 'store/user/guestProfile/thunks';
import { status } from 'utils/const';

const initialState = {
  twoFactorWay: null,
  twoFactorEnabled: false,
  googleAppSettings: {
    googleTwoFactorQrCode: '',
    googleTwoFactorSecret: '',
  },
  alertPreferences: {
    notificationProfile: true,
    notificationShard: true,
  },
  defaultInterfaceSettings: {
    languageId: null,
    timeZone: null,
  },
  disable2FAStatus: status.IDLE,
  enable2FAStatus: status.IDLE,
  confirm2FAStatus: status.IDLE,
  updateSettingsStatus: status.IDLE,
  gettingQRStatus: status.IDLE,
};

const slice = createSlice({
  name: 'settingsPage',
  initialState,
  reducers: {
    RESET_GOOGLE_APP_SETTINGS: (state) => {
      state.googleAppSettings = initialState.googleAppSettings;
    },
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.updateNotificationSettings.pending, (state) => {
        state.updateSettingsStatus = status.PENDING;
      })
      .addCase(
        thunks.updateNotificationSettings.fulfilled,
        (state, { payload }) => {
          state.alertPreferences = payload.data.notifications;
          state.updateSettingsStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.updateNotificationSettings.rejected, (state) => {
        state.updateSettingsStatus = status.FAIL;
      })

      .addCase(thunks.updateInterfaceSettings.pending, (state) => {
        state.updateSettingsStatus = status.PENDING;
      })
      .addCase(
        thunks.updateInterfaceSettings.fulfilled,
        (state, { payload }) => {
          state.defaultInterfaceSettings = {
            languageId: payload.data.languageId,
            timeZone: payload.data.timezone,
          };
          state.updateSettingsStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.updateInterfaceSettings.rejected, (state) => {
        state.updateSettingsStatus = status.FAIL;
      })

      .addCase(profileThunks.getProfile.pending, (state) => {
        state.updateSettingsStatus = status.PENDING;
      })
      .addCase(profileThunks.getProfile.fulfilled, (state, { payload }) => {
        const { settings, twoFactorWay, twoFactorEnabled } = payload;
        const { notifications, ...restSettings } = settings;
        state.twoFactorWay = twoFactorWay;
        state.twoFactorEnabled = twoFactorEnabled;
        state.alertPreferences = notifications;
        state.defaultInterfaceSettings = {
          languageId: restSettings.languageId,
          timeZone: restSettings.timezone,
        };
        state.updateSettingsStatus = status.SUCCESS;
      })
      .addCase(profileThunks.getProfile.rejected, (state) => {
        state.updateSettingsStatus = status.FAIL;
      })

      .addCase(thunks.enable2FA.pending, (state) => {
        state.enable2FAStatus = status.PENDING;
      })
      .addCase(thunks.enable2FA.fulfilled, (state) => {
        state.enable2FAStatus = status.SUCCESS;
      })
      .addCase(thunks.enable2FA.rejected, (state) => {
        state.enable2FAStatus = status.FAIL;
      })

      .addCase(thunks.disable2FA.pending, (state) => {
        state.disable2FAStatus = status.PENDING;
      })
      .addCase(thunks.disable2FA.fulfilled, (state, { payload }) => {
        state.twoFactorWay = payload;
        state.disable2FAStatus = status.SUCCESS;
      })
      .addCase(thunks.disable2FA.rejected, (state) => {
        state.disable2FAStatus = status.FAIL;
      })

      .addCase(thunks.confirm2FA.pending, (state) => {
        state.confirm2FAStatus = status.PENDING;
      })
      .addCase(thunks.confirm2FA.fulfilled, (state, { payload }) => {
        state.twoFactorWay = payload;
        state.confirm2FAStatus = status.SUCCESS;
      })
      .addCase(thunks.confirm2FA.rejected, (state) => {
        state.confirm2FAStatus = status.FAIL;
      })

      .addCase(thunks.getQR.pending, (state) => {
        state.gettingQRStatus = status.PENDING;
      })
      .addCase(thunks.getQR.fulfilled, (state, { payload }) => {
        state.googleAppSettings = payload.data;
        state.gettingQRStatus = status.SUCCESS;
      })
      .addCase(thunks.getQR.rejected, (state) => {
        state.gettingQRStatus = status.FAIL;
      });
  },
});

const settingsPage = {
  actions: slice.actions,
  selectors,
  thunks,
};

export { settingsPage };
export default slice.reducer;
