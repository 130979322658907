import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  data: {},
  fetchingStatus: status.IDLE,
  popupIsVisible: false,
  timerDurationInMinutes: 2,
};

const slice = createSlice({
  name: 'securitySetup',
  initialState,
  reducers: {
    SET_POPUP: (state, { payload }) => {
      state.popupIsVisible = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.getSecuritySetup.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.fetchingStatus = status.SUCCESS;
    });
  },
});

const securitySetup = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { securitySetup };
export default slice.reducer;
