const selectors = {
  list: (state) => state.userFunds.list,
  balance: (state) => state.userFunds.balance,
  filters: (state) => state.userFunds.filters,
  pagination: (state) => state.userFunds.pagination,
  fetchingStatus: (state) => state.userFunds.fetchingStatus,
  requestApprovalStatus: (state) => state.userFunds.requestApprovalStatus,
  requestRejectionStatus: (state) => state.userFunds.requestRejectionStatus,
};

export { selectors };
