import { createSlice } from '@reduxjs/toolkit';

import { walletServicesModel } from 'models/wallet-services';
import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  list: [],
  fetchingStatus: status.IDLE,
};

const slice = createSlice({
  name: 'digitalWalletServices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getServices.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getServices.fulfilled, (state, { payload }) => {
        state.list = walletServicesModel(payload);
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getServices.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      });
  },
});

const digitalWalletServices = {
  actions: slice.actions,
  selectors,
  thunks,
};

export { digitalWalletServices };
export default slice.reducer;
