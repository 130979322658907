const selectors = {
  data: (state) => state.systemSetup.data,
  message: (state) => state.systemSetup.message,
  fetchingStatus: (state) => state.systemSetup.fetchingStatus,
  createMessageStatus: (state) => state.systemSetup.createMessageStatus,
  editMessageStatus: (state) => state.systemSetup.editMessageStatus,
  getMessageStatus: (state) => state.systemSetup.getMessageStatus,
  deleteMessageStatus: (state) => state.systemSetup.deleteMessageStatus,
  changeMessageStatusStatus: (state) =>
    state.systemSetup.changeMessageStatusStatus,
  resendMessageStatus: (state) => state.systemSetup.resendMessageStatus,
};

export { selectors };
