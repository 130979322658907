const selectors = {
  list: (state) => state.adminSetup.list,
  admin: (state) => state.adminSetup.admin,
  filters: (state) => state.adminSetup.filters,
  pagination: (state) => state.adminSetup.pagination,
  fetchingStatus: (state) => state.adminSetup.fetchingStatus,
  addAdminStatus: (state) => state.adminSetup.addAdminStatus,
  fetchingAdminStatus: (state) => state.adminSetup.fetchingAdminStatus,
  editAdminStatus: (state) => state.adminSetup.editAdminStatus,
  changeStatusStatus: (state) => state.adminSetup.changeStatusStatus,
};

export { selectors };
