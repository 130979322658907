import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { rating } from 'utils/const';
import { handleServerErrors } from 'utils/serverErrors';
import { checkOptionAll, checkMultiselectOptionAll } from 'utils/prepareFields';
import { prepareDateToServer } from 'utils/prepareDate';

const getListers = createAsyncThunk(
  'admin/listerServices',
  async ({ pageSize, pageIndex, sortCol, sortDir, filters }) => {
    const queryParam = filters
      ? {
          perPage: pageSize,
          page: pageIndex,
          sortCol,
          sortDir,
          ...filters,
          tradeStatus: checkOptionAll(filters.tradeStatus),
          listerStatus: checkOptionAll(filters.listerStatus),
          userStatus: checkOptionAll(filters.userStatus),
          rating: checkMultiselectOptionAll(
            filters.rating,
            Object.keys(rating)
          ),
          dateFrom: filters.dateFrom
            ? prepareDateToServer(filters.dateFrom)
            : filters.dateFrom,
          dateTo: filters.dateTo
            ? prepareDateToServer(filters.dateTo)
            : filters.dateTo,
        }
      : {
          perPage: pageSize,
          page: pageIndex,
          sortCol,
          sortDir,
        };
    try {
      const { data } = await api.listerServices.getListers(queryParam);
      return { data: data.data, meta: data.meta };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateAccountStatus = createAsyncThunk(
  'admin/updateAccountStatus',
  async (formData) => {
    const { userId, fd } = formData;
    try {
      const { data } = await api.listerServices.updateAccountStatus(userId, fd);
      toast.success('Account status was successfully updated!');
      return { message: data.message, userId };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getListers,
  updateAccountStatus,
};

export { thunks };
