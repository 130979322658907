import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { listerProfile } from 'store/user/listerProfile';
import { status } from 'utils/const';
import { APP_ROUTES } from 'router/appRoutes';
import { RLoadingOverlay } from 'components/RLoadingOverlay';
import { toast } from 'react-toastify';

function TwitterCallbackHandler() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const urlParams = new URLSearchParams(search);

  const state = urlParams.get('state');
  const code = urlParams.get('code');
  const error = urlParams.get('error');

  const sendingParamsStatus = useSelector(
    listerProfile.selectors.sendingVerificationParamsStatus
  );

  const callbackRequestResolved =
    sendingParamsStatus === status.SUCCESS ||
    sendingParamsStatus === status.FAIL;

  useEffect(() => {
    if (error) {
      history.replace(APP_ROUTES.user.applyForInfluencer);
      toast.error('Your X account has not been confirmed. Please try again.');

      return;
    }

    dispatch(
      listerProfile.thunks.sendTwitterVerificationParams({ state, code })
    );
  }, [search]);

  useEffect(() => {
    if (callbackRequestResolved) {
      history.replace(APP_ROUTES.user.applyForInfluencer);
      toast.success('Successful Confirmation X Account');

      dispatch(
        listerProfile.actions.RESET_STATUS('sendingVerificationParamsStatus')
      );
    }
  }, [sendingParamsStatus]);

  return (
    <div>
      <RLoadingOverlay isVisible={sendingParamsStatus === status.PENDING} />
      <h3>Processing Twitter callback...</h3>
    </div>
  );
}

export default TwitterCallbackHandler;
