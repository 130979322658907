import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { prepareDateFromServer } from 'utils/prepareDate';
import { getObjectFromValue } from 'utils/prepareFields';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  data: {},
  fetchingStatus: status.IDLE,
  titlesForReject: {},
  titlesForRejectStatus: status.IDLE,
  rejectUserStatus: status.IDLE,
  updatingUserStatus: status.IDLE,
};

const slice = createSlice({
  name: 'userInfo',
  initialState: { ...initialState },
  reducers: {
    RESET_USER_INFO: (state) => {
      state.data = {};
      state.fetchingStatus = status.IDLE;
    },
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getUserInfo.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getUserInfo.fulfilled, (state, { payload }) => {
        const { profile, ...data } = payload;
        state.data = {
          ...data,
          ...profile,
          country: profile?.country ? getObjectFromValue(profile?.country) : {},
          dob: profile?.dob ? prepareDateFromServer(profile.dob) : null,
        };
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getUserInfo.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      })

      .addCase(thunks.getTitlesForReject.pending, (state) => {
        state.titlesForRejectStatus = status.PENDING;
      })
      .addCase(thunks.getTitlesForReject.fulfilled, (state, { payload }) => {
        state.titlesForRejectStatus = status.SUCCESS;
        state.titlesForReject = payload;
      })
      .addCase(thunks.getTitlesForReject.rejected, (state) => {
        state.titlesForRejectStatus = status.FAIL;
      })

      .addCase(thunks.rejectUser.pending, (state) => {
        state.rejectUserStatus = status.PENDING;
      })
      .addCase(thunks.rejectUser.fulfilled, (state) => {
        state.rejectUserStatus = status.SUCCESS;
      })
      .addCase(thunks.rejectUser.rejected, (state) => {
        state.rejectUserStatus = status.FAIL;
      })

      .addCase(thunks.updateUserInfo.pending, (state) => {
        state.updatingUserStatus = status.PENDING;
      })
      .addCase(thunks.updateUserInfo.fulfilled, (state, { payload }) => {
        const { profile, ...data } = payload;
        state.data = {
          ...data,
          ...profile,
          country: profile?.country ? getObjectFromValue(profile?.country) : {},
          dob: profile?.dob ? prepareDateFromServer(profile.dob) : null,
        };
        state.updatingUserStatus = status.SUCCESS;
      })
      .addCase(thunks.updateUserInfo.rejected, (state) => {
        state.updatingUserStatus = status.FAIL;
      })

      .addCase(thunks.updateUserPhoto.pending, (state) => {
        state.updatingUserStatus = status.PENDING;
      })
      .addCase(thunks.updateUserPhoto.fulfilled, (state, { payload }) => {
        const { profile } = payload;
        state.data.photo = profile.photo;
        state.updatingUserStatus = status.SUCCESS;
      })
      .addCase(thunks.updateUserPhoto.rejected, (state) => {
        state.updatingUserStatus = status.FAIL;
      });
  },
});

const userInfo = {
  actions: slice.actions,
  selectors,
  thunks,
};

export { userInfo };
export default slice.reducer;
