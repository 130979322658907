const selectors = {
  data: (state) => state.listerInfo.data,
  fetchingStatus: (state) => state.listerInfo.fetchingStatus,
  updatePhotoStatus: (state) => state.listerInfo.updatePhotoStatus,
  updateInfoStatus: (state) => state.listerInfo.updateInfoStatus,
  updateRatingStatus: (state) => state.listerInfo.updateRatingStatus,
  approveStatus: (state) => state.listerInfo.approveStatus,
  rejectStatus: (state) => state.listerInfo.rejectStatus,
  stopTradingStatus: (state) => state.listerInfo.stopTradingStatus,
  startTradingStatus: (state) => state.listerInfo.startTradingStatus,
};

export { selectors };
