import { createSlice } from '@reduxjs/toolkit';

import { pagination } from 'models/pagination';
import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  list: [],
  pagination: {},
  filters: {
    priceFrom: null,
    priceTo: null,
    shardsFrom: null,
    shardsTo: null,
    dateFrom: null,
    dateTo: null,
    search: null,
  },
  fetchingStatus: status.IDLE,
};

const slice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    SET_FILTERS: (state, { payload }) => {
      state.filters = payload;
    },
    RESET_FILTERS: (state) => {
      state.filters = initialState.filters;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getTransactionsList.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(
        thunks.getTransactionsList.fulfilled,
        (
          state,
          {
            payload: {
              meta: { current_page, from, to, total, per_page },
              data,
            },
          }
        ) => {
          state.fetchingStatus = status.SUCCESS;
          state.list = data;
          state.pagination = pagination(
            current_page,
            from,
            to,
            total,
            per_page
          );
        }
      )
      .addCase(thunks.getTransactionsList.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      });
  },
});

const marketTransactions = {
  actions: slice.actions,
  selectors,
  thunks,
};

export { marketTransactions };
export default slice.reducer;
