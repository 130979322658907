const selectors = {
  approximateValue: (state) => state.userHoldings.approximateValue,
  lockedTokens: (state) => state.userHoldings.lockedTokens,
  list: (state) => state.userHoldings.list,
  pagination: (state) => state.userHoldings.pagination,
  filters: (state) => state.userHoldings.filters,
  holdingsLoadingStatus: (state) => state.userHoldings.holdingsLoadingStatus,
};

export { selectors };
