import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getRequest = async (getState) => {
  const {
    listStaticParams: { page, type },
  } = getState().userNotifications;

  try {
    const {
      data: { data, meta },
    } = await api.userNotifications.getNotifications({ page, type });

    return {
      data,
      pag: {
        page: meta.current_page,
        lastPage: meta.last_page,
      },
    };
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
};

const getNotifications = createAsyncThunk(
  'user/getNotifications',
  (_, { getState }) => {
    return getRequest(getState);
  }
);

const getMoreNotifications = createAsyncThunk(
  'user/getMoreNotifications',
  async (_, { getState }) => {
    return getRequest(getState);
  }
);

const getLatest = createAsyncThunk(
  'user/getLatest',
  async (_, { getState }) => {
    const { offset } =
      getState().settingsPage.defaultInterfaceSettings.timeZone;

    try {
      const { data } = await api.userNotifications.getLatest();
      return { data: data.data, offset };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const setMarkAllAsRead = createAsyncThunk('user/setMarkAllAsRead', async () => {
  try {
    const { data } = await api.userNotifications.setMarkAllAsRead();
    return { status: data.success };
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const thunks = {
  getNotifications,
  getMoreNotifications,
  getLatest,
  setMarkAllAsRead,
};

export { thunks };
