import http from '../http';
import { WEB_API_ROUTES } from '../api-routes';

export const additionalData = {
  getCountries() {
    return http.get(WEB_API_ROUTES.additionalData.getCountries);
  },
  getLanguages() {
    return http.get(WEB_API_ROUTES.additionalData.getLanguages);
  },
  getTimeZones() {
    return http.get(WEB_API_ROUTES.additionalData.getTimeZones);
  },
  contactInformation() {
    return http.get(WEB_API_ROUTES.user.contactInformation);
  },
};
