const selectors = {
  list: (state) => state.listerDetails.list,
  lister: (state) => state.listerDetails.lister,
  filters: (state) => state.listerDetails.filters,
  sorting: (state) => state.listerDetails.sorting,
  pagination: (state) => state.listerDetails.pagination,
  shardsInfo: (state) => state.listerDetails.shardsInfo,
  updatingDetails: (state) => state.listerDetails.updatingDetails,
  listerLoadingStatus: (state) => state.listerDetails.listerLoadingStatus,
  offersLoadingStatus: (state) => state.listerDetails.offersLoadingStatus,
  stopOfferStatus: (state) => state.listerDetails.stopOfferStatus,
  startOfferStatus: (state) => state.listerDetails.startOfferStatus,
  deleteOfferStatus: (state) => state.listerDetails.deleteOfferStatus,
  sellOfferStatus: (state) => state.listerDetails.sellOfferStatus,
};

export { selectors };
