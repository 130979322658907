import yup from 'plugins/yup-config';

import { transformNaN, transformObject } from 'utils/prepare';

const defaultValuesINX = {
  type: {},
  currency: {},
  dateFrom: null,
  dateTo: null,
};

const schema = yup.object().shape({
  type: yup.object().transform(transformObject).nullable().required(),
  currency: yup.object().transform(transformObject).nullable().required(),
  dateFrom: yup.number().nullable().transform(transformNaN),
  dateTo: yup.number().nullable().transform(transformNaN),
});

const typeOptions = [
  { value: 'All', label: 'All' },
  { value: 'SWAP', label: 'Swap' },
  { value: 'TRANSFER', label: 'Transfer' },
];

const currencyOptions = [
  { value: 'All', label: 'All' },
  { value: 'inx', label: 'INX' },
  { value: 'usdt', label: 'USDT' },
];

export { defaultValuesINX, schema, typeOptions, currencyOptions };
