const selectors = {
  data: (state) => state.userInfo.data,
  fetchingStatus: (state) => state.userInfo.fetchingStatus,
  titlesForReject: (state) => {
    const list = Object.entries(state.userInfo.titlesForReject);
    if (!list.length) return [];
    return list.map(([key, value]) => ({
      value: key,
      label: value,
    }));
  },
  titlesForRejectStatus: (state) => state.userInfo.titlesForRejectStatus,
  rejectUserStatus: (state) => state.userInfo.rejectUserStatus,
  updatingUserStatus: (state) => state.userInfo.updatingUserStatus,
};

export { selectors };
