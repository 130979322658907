import { WEB_API_ROUTES } from 'api/api-routes';
import http from 'api/http';

export const auth = {
  login(formData) {
    return http.post(WEB_API_ROUTES.auth.login, formData);
  },
  getRolesList() {
    return http.get(WEB_API_ROUTES.auth.getRolesList);
  },
  register(formData) {
    return http.post(WEB_API_ROUTES.auth.register, formData);
  },
  logout() {
    return http.post(WEB_API_ROUTES.auth.logout);
  },
  twoFactorChooseWay(formData) {
    return http.post(WEB_API_ROUTES.auth.twoFactorChooseWay, formData);
  },
  twoFactorLogin(formData) {
    return http.post(WEB_API_ROUTES.auth.twoFactorLogin, formData);
  },
  twoFactorLoginResend(formData) {
    return http.post(WEB_API_ROUTES.auth.twoFactorLoginResend, formData);
  },
  resetPassword(login) {
    return http.post(WEB_API_ROUTES.auth.resetPassword, login);
  },
  sendResetPasswordCode(data) {
    return http.post(WEB_API_ROUTES.auth.sendResetPasswordCode, data);
  },
  changePassword(data) {
    return http.post(WEB_API_ROUTES.auth.changePassword, data);
  },
  changePasswordByOld(data) {
    return http.post(WEB_API_ROUTES.auth.changePasswordByOld, data);
  },
};
