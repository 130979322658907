import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getListerInfo = createAsyncThunk(
  'admin/getListerInfo',
  async (listerId) => {
    try {
      const { data } = await api.listerServices.getListerInfo(listerId);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateInfluencerPhoto = createAsyncThunk(
  'admin/updateInfluencerPhoto',
  async ({ influencerId, formData }) => {
    try {
      const { data } = await api.listerServices.updateInfluencerPhoto(
        influencerId,
        formData
      );
      toast.success('Influencer photo was successfully updated!');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateInfluencerInfo = createAsyncThunk(
  'admin/updateInfluencerInfo',
  async ({ influencerId, formData }) => {
    try {
      const { data } = await api.listerServices.updateInfluencerInfo(
        influencerId,
        formData
      );
      toast.success('Influencer info was successfully updated!');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateInfluencerRating = createAsyncThunk(
  'admin/updateInfluencerRating',
  async ({ influencerId, formData }) => {
    try {
      const { data } = await api.listerServices.updateInfluencerRating(
        influencerId,
        {
          ...formData,
          rating: formData.rating.value,
        }
      );
      toast.success('Lister info was successfully updated!');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const approveLister = createAsyncThunk(
  'admin/approveLister',
  async (listerId) => {
    try {
      const { data } = await api.listerServices.approveLister(listerId);
      toast.success(data.message);
      return data.message;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const rejectLister = createAsyncThunk(
  'admin/rejectLister',
  async ({ listerId, formData }) => {
    try {
      const { data } = await api.listerServices.rejectLister(
        listerId,
        formData
      );
      toast.success(data.message);
      return data.message;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const stopTrading = createAsyncThunk('admin/stopTrading', async (listerId) => {
  try {
    const { data } = await api.listerServices.stopTrading(listerId);
    return { message: data.message, listerId };
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const startTrading = createAsyncThunk(
  'admin/startTrading',
  async (listerId) => {
    try {
      const { data } = await api.listerServices.startTrading(listerId);
      return { message: data.message, listerId };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getListerInfo,
  updateInfluencerPhoto,
  updateInfluencerInfo,
  updateInfluencerRating,
  approveLister,
  rejectLister,
  stopTrading,
  startTrading,
};

export { thunks };
