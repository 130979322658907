import yup from 'plugins/yup-config';

import { transformNaN } from 'utils/prepare';

const influencerStatusOptions = [
  { value: 'All', label: 'All' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Rejected', label: 'Rejected' },
];

const schema = yup.object().shape({
  dateFrom: yup.number().nullable().transform(transformNaN),
  dateTo: yup.number().nullable().transform(transformNaN),
});

export { schema, influencerStatusOptions };
