const selectors = {
  profile: (state) => state.guestProfile.profile,
  profileStatus: (state) => state.guestProfile.profileStatus,
  updateUserStatus: (state) => state.guestProfile.updateUserStatus,
  confirmPhoneChangeStatus: (state) =>
    state.guestProfile.confirmPhoneChangeStatus,
  confirmPhoneChangeResendStatus: (state) =>
    state.guestProfile.confirmPhoneChangeResendStatus,
  confirmEmailChangeStatus: (state) =>
    state.guestProfile.confirmEmailChangeStatus,
  confirmEmailChangeResendStatus: (state) =>
    state.guestProfile.confirmEmailChangeResendStatus,
  getUserWalletStatus: (state) => state.guestProfile.getUserWalletStatus,
};

export { selectors };
