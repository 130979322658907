const selectors = {
  countries: (state) =>
    state.additionalData.countries?.map(({ isoCode, flagPath, name }) => ({
      _id: isoCode,
      value: name,
      label: name,
      path: flagPath,
    })),
  languages: (state) =>
    state.additionalData.languages.map(({ id, name, flagPath, ...rest }) => ({
      label: name,
      value: id,
      path: flagPath,
      ...rest,
    })),
  timeZones: (state) =>
    state.additionalData.timeZones.map(({ id, zone }) => ({
      label: zone,
      value: id,
    })),
  contacts: (state) => state.additionalData.contacts,
};

export { selectors };
