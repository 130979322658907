const selectors = {
  list: (s) => s.userMarket.list,
  pagination: (s) => s.userMarket.pagination,
  filters: (s) => s.userMarket.filters,
  fetchingStatus: (s) => s.userMarket.fetchingStatus,
  addToWatchlistStatus: (s) => s.userMarket.addToWatchlistStatus,
  removeFromWatchlistStatus: (s) => s.userMarket.removeFromWatchlistStatus,
};

export { selectors };
