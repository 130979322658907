export const appDateFormat = 'dd.MM.yyyy';
export const secondaryDateFormat = 'dd/MM/yyyy';
export const reverseDateFormat = 'yyyy_MM_dd';
export const dateFormatWithTime = 'dd.MM.yyyy, p';
export const twelveHourFormat = 'p';
export const twentyFourHourFormat = 'HH:mm';
export const dayOfWeekFormat = 'EEE';
export const dayOfMonthFormat = 'dd.LL';
export const monthFormat = 'LLL';
export const yearFormat = 'yyyy';
export const chartDateFormat = 'yyyy-MM-dd';
