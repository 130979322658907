import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const userTrading = {
  getInfluencerTradingInfo(influencerId) {
    return http.get(
      WEB_API_ROUTES.user.trading.getInfluencerTradingInfo.replace(
        '{influencerId}',
        influencerId
      ),
      {
        influencerId,
      }
    );
  },

  getTradingOrders(params) {
    return http.get(WEB_API_ROUTES.user.trading.getTradingOrders, { params });
  },

  cancelLimitOrder(influencerId) {
    return http.delete(
      WEB_API_ROUTES.user.trading.cancelTradingOrder.replace(
        '{influencerId}',
        influencerId
      )
    );
  },

  buyMarketTokens(data) {
    return http.post(WEB_API_ROUTES.user.trading.buyMarketTokens, data);
  },

  sellMarketTokens(data) {
    return http.post(WEB_API_ROUTES.user.trading.sellMarketTokens, data);
  },

  buyLimitTokens(data) {
    return http.post(WEB_API_ROUTES.user.trading.buyLimitTokens, data);
  },

  sellLimitTokens(data) {
    return http.post(WEB_API_ROUTES.user.trading.sellLimitTokens, data);
  },

  getLineChart(influencerId, params) {
    return http.get(
      WEB_API_ROUTES.user.trading.getLineChart.replace(
        '{influencerId}',
        influencerId
      ),
      { params }
    );
  },

  getCandlestickChart(influencerId, params) {
    return http.get(
      WEB_API_ROUTES.user.trading.getCandlestickChart.replace(
        '{influencerId}',
        influencerId
      ),
      { params }
    );
  },

  updateCandlestickChart(influencerId, params) {
    return http.get(
      WEB_API_ROUTES.user.trading.updateCandlestickChart.replace(
        '{influencerId}',
        influencerId
      ),
      { params }
    );
  },
};
