const selectors = {
  updateUserStatus: (state) => state.profile.updateUserStatus,
  confirmPhoneChangeStatus: (state) => state.profile.confirmPhoneChangeStatus,
  confirmPhoneChangeResendStatus: (state) =>
    state.profile.confirmPhoneChangeResendStatus,
  confirmEmailChangeStatus: (state) => state.profile.confirmEmailChangeStatus,
  confirmEmailChangeResendStatus: (state) =>
    state.profile.confirmEmailChangeResendStatus,
};

export { selectors };
