import styled from '@emotion/styled';

import { device } from 'utils/device';
import { Flex } from 'components/App/GlobalStyled';

const Wrap = styled.div`
  ${Flex};
  height: 100%;

  @media (${device.md}) {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    row-gap: 30px;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  max-width: 142px;
  margin-left: 45px;
  padding-block: 45px;
`;

const Text = styled.div`
  @media (${device.md}) {
    padding: 0 16px;
  }
`;

const Error = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: ${({ theme }) => theme.tuftsBlue};
  margin-bottom: 16px;
`;

const Heading = styled.div`
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const Paragraph = styled.div`
  max-width: 305px;
  letter-spacing: 0.03em;
`;

export { Wrap, Content, Img, Text, Error, Heading, Paragraph };
