import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  listersList: [],
  fetchingListersListStatus: status.IDLE,
};

const slice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    RESET_STATE: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getListersList.pending, (state) => {
        state.fetchingListersListStatus = status.PENDING;
      })
      .addCase(thunks.getListersList.fulfilled, (state, { payload }) => {
        state.listersList = payload.map(({ id, symbol }) => ({
          label: symbol,
          value: id,
        }));
        state.fetchingListersListStatus = status.SUCCESS;
      })
      .addCase(thunks.getListersList.rejected, (state) => {
        state.fetchingListersListStatus = status.FAIL;
      });
  },
});

const general = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { general };
export default slice.reducer;
