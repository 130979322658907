import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { getValueFromOptions } from 'utils/prepareFields';
import { handleServerErrors } from 'utils/serverErrors';
import { prepareDateToServer } from 'utils/prepareDate';

const getProfile = createAsyncThunk(
  'listerProfile/getProfile',
  async (_, { getState }) => {
    const { offset } =
      getState().settingsPage.defaultInterfaceSettings.timeZone;

    try {
      const { data } = await api.listerProfile.getProfile();
      return { data: data.data, offset };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateListerProfile = createAsyncThunk(
  'listerProfile/updateListerProfile',
  async (payload, { getState }) => {
    const { profile } = getState().listerProfile;
    const { offset } =
      getState().settingsPage.defaultInterfaceSettings.timeZone;

    const formData = getValueFromOptions(profile);
    formData.dob = prepareDateToServer(profile.dob, offset);

    try {
      const { data } = await api.listerProfile.updateListerProfile(formData);
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateListerPhoto = createAsyncThunk(
  'listerProfile/updateListerPhoto',
  async (file) => {
    try {
      const { data } = await api.listerProfile.updateListerPhoto(file);
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getListerData = createAsyncThunk(
  'listerProfile/getListerData',
  async () => {
    try {
      const { data } = await api.listerProfile.getListerData();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getVerificationLink = createAsyncThunk(
  'listerProfile/getVerificationLink',
  async () => {
    try {
      const { data } = await api.listerProfile.getVerificationLink();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendTwitterVerificationParams = createAsyncThunk(
  'listerProfile/sendTwitterVerificationParams',
  async (params) => {
    try {
      const { data } = await api.listerProfile.sendTwitterVerificationParams(
        params
      );
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getProfile,
  updateListerProfile,
  updateListerPhoto,
  getListerData,
  getVerificationLink,
  sendTwitterVerificationParams,
};

export { thunks };
