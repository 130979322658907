import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getDashboard = createAsyncThunk(
  'adminDashboard/getDashboard',
  async () => {
    try {
      const { data } = await api.adminDashboard.getDashboard();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getDashboard,
};

export { thunks };
