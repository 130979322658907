import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const financialServices = {
  getServices() {
    return http.get(WEB_API_ROUTES.admin.financialServices.services);
  },

  getDividends(params) {
    return http.get(WEB_API_ROUTES.admin.financialServices.dividends, {
      params,
    });
  },

  addDividend(formData) {
    return http.post(
      WEB_API_ROUTES.admin.financialServices.dividends,
      formData
    );
  },

  getDividendDetails(dividendId) {
    return http.get(
      WEB_API_ROUTES.admin.financialServices.getDividendDetails.replace(
        '{dividends}',
        dividendId
      )
    );
  },

  getPayments(dividendId, params) {
    return http.get(
      WEB_API_ROUTES.admin.financialServices.getPayments.replace(
        '{dividends}',
        dividendId
      ),
      {
        params,
      }
    );
  },

  sendDividendsAndReport(dividendId) {
    return http.post(
      WEB_API_ROUTES.admin.financialServices.onSendDividendsAndReport.replace(
        '{dividends}',
        dividendId
      )
    );
  },

  exportCsv(dividendId, fd) {
    return http.post(
      WEB_API_ROUTES.admin.financialServices.exportCsv.replace(
        '{dividends}',
        dividendId
      ),
      fd
    );
  },
};
