import { createSlice } from '@reduxjs/toolkit';

import { prepareDateFromServer } from 'utils/prepareDate';
import { getObjectFromValue } from 'utils/prepareFields';
import { status } from 'utils/const';
import { thunks as gpThunks } from 'store/user/guestProfile/thunks';
import { selectors } from './selectors';
import { thunks } from './thunks';

const steps = [
  {
    id: 0,
    slug: 'GENERAL',
    title: 'General',
    completed: false,
  },
  {
    id: 1,
    slug: 'CONTACT',
    title: 'Contact',
    completed: false,
  },
  {
    id: 2,
    slug: 'ADDRESS',
    title: 'Address',
    completed: false,
  },
  {
    id: 3,
    slug: 'DOCUMENTS',
    title: 'Documents',
    completed: false,
  },
];

const defaultFields = {
  general: {
    username: '',
    firstName: '',
    middleName: null,
    lastName: '',
    dob: null,
  },
  contacts: {
    primaryTel: '',
    alterTel: '',
    primaryEmail: '',
    alterEmail: '',
  },
  address: {
    country: {},
    state: '',
    city: '',
    zip: '',
    primaryAddress: '',
    alternateAddress: null,
  },
  documents: {
    idFrontPage: null,
    documentType: null,
  },
};

const initialState = {
  steps,
  currentStep: steps[0],
  activeStep: steps[0],
  fieldsData: defaultFields,
  updateProfileStatus: status.IDLE,
  initFieldsDataStatus: true,
};

const slice = createSlice({
  name: 'fullRegistration',
  initialState: { ...initialState },
  reducers: {
    RESET_STATE: (state) => {
      Object.assign(state, initialState);
    },
    SET_CURRENT_STEP: (state, { payload }) => {
      state.currentStep = payload;
    },
    SET_ACTIVE_STEP: (state, { payload }) => {
      state.activeStep = payload;
    },
    SET_MODIFIED_STEPS: (state, { payload }) => {
      state.steps = payload;
    },
    SET_FIELDS_DATA: (state, { payload }) => {
      state.fieldsData = { ...state.fieldsData, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.updateProfile.pending, (state) => {
        state.updateProfileStatus = status.PENDING;
      })
      .addCase(thunks.updateProfile.fulfilled, (state) => {
        state.updateProfileStatus = status.SUCCESS;
      })
      .addCase(thunks.updateProfile.rejected, (state) => {
        state.updateProfileStatus = status.FAIL;
      })

      .addCase(gpThunks.getProfile.fulfilled, (state, { payload }) => {
        if (state.initFieldsDataStatus) {
          const { profile, user, settings } = payload;

          const general = {
            username: user.username,
            firstName: profile.firstName,
            middleName: profile.middleName,
            lastName: profile.lastName,
            dob: profile.dob
              ? prepareDateFromServer(profile.dob, settings.timezone.offset)
              : null,
          };
          const contacts = {
            primaryTel: user.primaryPhone,
            alterTel: user.alternatePhone,
            primaryEmail: profile.temporaryEmail || user.primaryEmail,
            alterEmail: user.alternateEmail,
          };
          const address = {
            country: profile.country ? getObjectFromValue(profile.country) : {},
            state: profile.state,
            city: profile.city,
            zip: profile.zip,
            primaryAddress: profile.primaryAddress,
            alternateAddress: profile.alternateAddress,
          };
          const documents = {
            idFrontPage: profile.idFrontPage,
            documentType: profile.documentType,
          };
          state.fieldsData = {
            ...state.fieldsData,
            general,
            contacts,
            address,
            documents,
          };
          state.initFieldsDataStatus = false;
        }
      });
  },
});

const fullRegistration = {
  actions: slice.actions,
  selectors,
  thunks,
};

export { fullRegistration };
export default slice.reducer;
