import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const updateUserProfile = createAsyncThunk(
  'profile/updateUserProfile',
  async (data) => {
    try {
      const res = await api.guestProfile.updateUserProfile(data);
      return {
        data: res.data,
      };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const confirmPhoneChange = createAsyncThunk(
  'profile/confirmPhoneChange',
  async (code, { rejectWithValue }) => {
    try {
      const { data } = await api.guestProfile.profileConfirmPhoneChange(code);
      return data?.data;
    } catch (err) {
      handleServerErrors(err);
      return rejectWithValue(err.response);
    }
  }
);

const confirmPhoneChangeResend = createAsyncThunk(
  'profile/confirmPhoneChangeResend',
  async (code, { rejectWithValue }) => {
    try {
      const { data } = await api.guestProfile.profileConfirmPhoneChangeResend(
        code
      );
      return data;
    } catch (err) {
      handleServerErrors(err);
      return rejectWithValue(err.response);
    }
  }
);

const confirmEmailChange = createAsyncThunk(
  'profile/confirmEmailChange',
  async (code, { rejectWithValue }) => {
    try {
      const { data } = await api.guestProfile.profileConfirmEmailChange(code);
      return data?.data;
    } catch (err) {
      handleServerErrors(err);
      return rejectWithValue(err.response);
    }
  }
);

const confirmEmailChangeResend = createAsyncThunk(
  'profile/confirmEmailChangeResend',
  async (code, { rejectWithValue }) => {
    try {
      const { data } = await api.guestProfile.profileConfirmEmailChangeResend(
        code
      );
      return data;
    } catch (err) {
      handleServerErrors(err);
      return rejectWithValue(err.response);
    }
  }
);

export const thunks = {
  updateUserProfile,
  confirmPhoneChange,
  confirmPhoneChangeResend,
  confirmEmailChange,
  confirmEmailChangeResend,
};
