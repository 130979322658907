import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getMyListerInfo = createAsyncThunk(
  'listerProfileInfo/getMyListerInfo',
  async (_, { getState }) => {
    const { offset } =
      getState().settingsPage.defaultInterfaceSettings.timeZone;

    try {
      const { data } = await api.listerProfile.getMyListerInfo();
      return { data, offset };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const readNotification = createAsyncThunk(
  'listerProfileInfo/readNotification',
  async () => {
    try {
      const { data } = await api.listerProfile.readNotification();
      return { data };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getListerInfo = createAsyncThunk(
  'listerProfileInfo/getListerInfo',
  async ({ listerId }, { getState }) => {
    const { offset } =
      getState().settingsPage.defaultInterfaceSettings.timeZone;

    try {
      const { data } = await api.listerProfile.getListerInfo(listerId);
      return { data, offset };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getGraphic = createAsyncThunk(
  'listerProfileInfo/getGraphic',
  async ({ listerId, fd }) => {
    try {
      const { data } = await api.listerProfile.getGraphic(listerId, fd);
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sellOffer = createAsyncThunk(
  'listerProfileInfo/sellOffer',
  async (fd) => {
    try {
      const { data } = await api.listerProfile.sellOffer(fd);
      toast.success(data.message);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getMyListerInfo,
  readNotification,
  getListerInfo,
  getGraphic,
  sellOffer,
};

export { thunks };
