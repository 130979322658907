import { createSlice } from '@reduxjs/toolkit';

import { influencerStatusOptions } from 'components/Admin/UserFundsPage/Filters/config';
import { pagination } from 'models/pagination';
import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  balance: null,
  list: [],
  pagination: {},
  filters: {
    tradeStatus: influencerStatusOptions[0],
    dateFrom: null,
    dateTo: null,
  },
  fetchingStatus: status.IDLE,
  requestApprovalStatus: status.IDLE,
  requestRejectionStatus: status.IDLE,
  getWalletBalanceStatus: status.IDLE,
};

const slice = createSlice({
  name: 'userFunds',
  initialState,
  reducers: {
    SET_FILTERS: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
    RESET_FILTERS: (state) => {
      state.filters = initialState.filters;
    },
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getRequests.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getRequests.fulfilled, (state, { payload }) => {
        const {
          meta: { current_page, from, to, total, per_page },
          data,
        } = payload;
        state.list = data;
        state.pagination = pagination(current_page, from, to, total, per_page);
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getRequests.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      })

      .addCase(thunks.approveRequest.pending, (state) => {
        state.requestApprovalStatus = status.PENDING;
      })
      .addCase(thunks.approveRequest.fulfilled, (state) => {
        state.requestApprovalStatus = status.SUCCESS;
      })
      .addCase(thunks.approveRequest.rejected, (state) => {
        state.requestApprovalStatus = status.FAIL;
      })

      .addCase(thunks.rejectRequest.pending, (state) => {
        state.requestRejectionStatus = status.PENDING;
      })
      .addCase(thunks.rejectRequest.fulfilled, (state) => {
        state.requestRejectionStatus = status.SUCCESS;
      })
      .addCase(thunks.rejectRequest.rejected, (state) => {
        state.requestRejectionStatus = status.FAIL;
      })

      .addCase(thunks.getWalletBalance.pending, (state) => {
        state.getWalletBalanceStatus = status.PENDING;
      })
      .addCase(thunks.getWalletBalance.fulfilled, (state, { payload }) => {
        state.balance = payload;
        state.getWalletBalanceStatus = status.SUCCESS;
      })
      .addCase(thunks.getWalletBalance.rejected, (state) => {
        state.getWalletBalanceStatus = status.FAIL;
      });
  },
});

export const userFunds = {
  actions: slice.actions,
  selectors,
  thunks,
};

export default slice.reducer;
