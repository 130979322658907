import PropTypes from 'prop-types';

import { RSpinner } from 'components/RSpinner';
import { Button, ButtonChildren, PrependIcon } from './styled';

RButton.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf([
    'contained',
    'outline',
    'text',
    'buy',
    'sell',
    'secondary-dark',
    'secondary-gray',
  ]),
  variantColor: PropTypes.string,
  prependIcon: PropTypes.element,
  fullWidth: PropTypes.bool,
  height: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  css: PropTypes.object,
  form: PropTypes.string,
  $loading: PropTypes.bool,
};

export function RButton({
  type = 'button',
  variant = 'contained',
  variantColor,
  prependIcon,
  fullWidth,
  height = 40,
  disabled,
  onClick = () => {},
  children,
  css,
  form,
  $loading,
  ...rest
}) {
  return (
    <Button
      type={type}
      variant={variant}
      variantColor={variantColor}
      fullWidth={fullWidth}
      height={height}
      disabled={disabled || $loading}
      onClick={!disabled ? onClick : undefined}
      css={css}
      form={form}
      {...rest}
    >
      <ButtonChildren>
        {prependIcon && (
          <PrependIcon disabled={disabled}>{prependIcon}</PrependIcon>
        )}
        {!$loading ? (
          children
        ) : (
          <>
            <RSpinner size={14} />
            &nbsp;Loading...
          </>
        )}
      </ButtonChildren>
    </Button>
  );
}
