import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
  quarterOptions,
  yearOptions,
} from 'components/Admin/DividendPaymentsPage/Filters/config';
import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import {
  checkMultiselectOptionAll,
  getValueFromOptions,
} from 'utils/prepareFields';

const getDividends = createAsyncThunk(
  'financialServices/getDividends',
  async ({ filters, pageIndex, pageSize }) => {
    try {
      const params = {
        perPage: pageSize,
        page: pageIndex,
        ...filters,
        quarter: checkMultiselectOptionAll(filters.quarter, quarterOptions),
        year: checkMultiselectOptionAll(filters.year, yearOptions),
      };
      const { data } = await api.financialServices.getDividends(params);
      return { data: data.data, meta: data.meta };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const addDividend = createAsyncThunk(
  'financialServices/addDividend',
  async (fd) => {
    try {
      const formData = getValueFromOptions(fd);
      const { data } = await api.financialServices.addDividend(formData);
      toast.success('Distribution has been successfully recorded');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getDividends,
  addDividend,
};

export { thunks };
