import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  supportStatus: status.IDLE,
};

const slice = createSlice({
  name: 'support',
  initialState: { ...initialState },
  reducers: {
    RESET_STATE: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.sendSupport.pending, (state) => {
        state.supportStatus = status.PENDING;
      })
      .addCase(thunks.sendSupport.fulfilled, (state) => {
        state.supportStatus = status.SUCCESS;
      })
      .addCase(thunks.sendSupport.rejected, (state) => {
        state.supportStatus = status.FAIL;
      });
  },
});

const support = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { support };
export default slice.reducer;
