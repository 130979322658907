import yup from 'plugins/yup-config';

import {
  generateArrayOfFutureYears,
  generateSequenceOfNumbers,
} from 'utils/prepare';

const quarterOptions = generateSequenceOfNumbers().map((number) => ({
  label: number.toString(),
  value: number.toString(),
}));

const yearOptions = generateArrayOfFutureYears().map((year) => ({
  label: year.toString(),
  value: year.toString(),
}));

const fields = [
  { label: 'Quarter', name: 'quarter', options: quarterOptions },
  { label: 'Year', name: 'year', options: yearOptions },
];

const schema = yup.object().shape({
  quarter: yup.array().nullable(),
  year: yup.array().nullable(),
});

export { schema, quarterOptions, yearOptions, fields };
