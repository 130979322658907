import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  data: {},
  fetchingStatus: status.IDLE,
  updatePhotoStatus: status.IDLE,
  updateInfoStatus: status.IDLE,
  updateRatingStatus: status.IDLE,
  updateActionStatus: status.IDLE,
  approveStatus: status.IDLE,
  rejectStatus: status.IDLE,
  stopTradingStatus: status.IDLE,
  startTradingStatus: status.IDLE,
};

const slice = createSlice({
  name: 'listerInfo',
  initialState,
  reducers: {
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
    RESET_LISTER_INFO: (state) => {
      state.data = {};
      state.fetchingStatus = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getListerInfo.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getListerInfo.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getListerInfo.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      })

      .addCase(thunks.updateInfluencerPhoto.pending, (state) => {
        state.updatePhotoStatus = status.PENDING;
      })
      .addCase(thunks.updateInfluencerPhoto.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.updatePhotoStatus = status.SUCCESS;
      })
      .addCase(thunks.updateInfluencerPhoto.rejected, (state) => {
        state.updatePhotoStatus = status.FAIL;
      })

      .addCase(thunks.updateInfluencerInfo.pending, (state) => {
        state.updateInfoStatus = status.PENDING;
      })
      .addCase(thunks.updateInfluencerInfo.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.updateInfoStatus = status.SUCCESS;
      })
      .addCase(thunks.updateInfluencerInfo.rejected, (state) => {
        state.updateInfoStatus = status.FAIL;
      })

      .addCase(thunks.updateInfluencerRating.pending, (state) => {
        state.updateRatingStatus = status.PENDING;
      })
      .addCase(
        thunks.updateInfluencerRating.fulfilled,
        (state, { payload }) => {
          state.data = payload;
          state.updateRatingStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.updateInfluencerRating.rejected, (state) => {
        state.updateRatingStatus = status.FAIL;
      })

      .addCase(thunks.approveLister.pending, (state) => {
        state.approveStatus = status.PENDING;
      })
      .addCase(thunks.approveLister.fulfilled, (state, { payload }) => {
        state.data = { ...state.data, listerStatus: payload };
        state.approveStatus = status.SUCCESS;
      })
      .addCase(thunks.approveLister.rejected, (state) => {
        state.approveStatus = status.FAIL;
      })

      .addCase(thunks.rejectLister.pending, (state) => {
        state.rejectStatus = status.PENDING;
      })
      .addCase(thunks.rejectLister.fulfilled, (state) => {
        state.rejectStatus = status.SUCCESS;
      })
      .addCase(thunks.rejectLister.rejected, (state) => {
        state.rejectStatus = status.FAIL;
      })

      .addCase(thunks.stopTrading.pending, (state) => {
        state.stopTradingStatus = status.PENDING;
      })
      .addCase(thunks.stopTrading.fulfilled, (state, { payload }) => {
        state.data = { ...state.data, tradeStatus: payload.message };
        state.stopTradingStatus = status.SUCCESS;
      })
      .addCase(thunks.stopTrading.rejected, (state) => {
        state.stopTradingStatus = status.FAIL;
      })

      .addCase(thunks.startTrading.pending, (state) => {
        state.startTradingStatus = status.PENDING;
      })
      .addCase(thunks.startTrading.fulfilled, (state, { payload }) => {
        state.data = { ...state.data, tradeStatus: payload.message };
        state.startTradingStatus = status.SUCCESS;
      })
      .addCase(thunks.startTrading.rejected, (state) => {
        state.startTradingStatus = status.FAIL;
      });
  },
});

export const listerInfo = {
  actions: slice.actions,
  selectors,
  thunks,
};

export default slice.reducer;
