import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  updateUserStatus: status.IDLE,
  confirmPhoneChangeStatus: status.IDLE,
  confirmPhoneChangeResendStatus: status.IDLE,
  confirmEmailChangeStatus: status.IDLE,
  confirmEmailChangeResendStatus: status.IDLE,
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunks.updateUserProfile.pending, (state) => {
        state.updateUserStatus = status.PENDING;
      })
      .addCase(thunks.updateUserProfile.fulfilled, (state) => {
        state.updateUserStatus = status.SUCCESS;
      })
      .addCase(thunks.updateUserProfile.rejected, (state) => {
        state.updateUserStatus = status.FAIL;
      })

      .addCase(thunks.confirmPhoneChange.pending, (state) => {
        state.confirmPhoneChangeStatus = status.PENDING;
      })
      .addCase(thunks.confirmPhoneChange.fulfilled, (state) => {
        state.confirmPhoneChangeStatus = status.SUCCESS;
      })
      .addCase(thunks.confirmPhoneChange.rejected, (state) => {
        state.confirmPhoneChangeStatus = status.FAIL;
      })

      .addCase(thunks.confirmPhoneChangeResend.pending, (state) => {
        state.confirmPhoneChangeResendStatus = status.PENDING;
      })
      .addCase(thunks.confirmPhoneChangeResend.fulfilled, (state) => {
        state.confirmPhoneChangeResendStatus = status.SUCCESS;
      })
      .addCase(thunks.confirmPhoneChangeResend.rejected, (state) => {
        state.confirmPhoneChangeResendStatus = status.FAIL;
      })

      .addCase(thunks.confirmEmailChange.pending, (state) => {
        state.confirmEmailChangeStatus = status.PENDING;
      })
      .addCase(thunks.confirmEmailChange.fulfilled, (state) => {
        state.confirmEmailChangeStatus = status.SUCCESS;
      })
      .addCase(thunks.confirmEmailChange.rejected, (state) => {
        state.confirmEmailChangeStatus = status.FAIL;
      })

      .addCase(thunks.confirmEmailChangeResend.pending, (state) => {
        state.confirmEmailChangeResendStatus = status.PENDING;
      })
      .addCase(thunks.confirmEmailChangeResend.fulfilled, (state) => {
        state.confirmEmailChangeResendStatus = status.SUCCESS;
      })
      .addCase(thunks.confirmEmailChangeResend.rejected, (state) => {
        state.confirmEmailChangeResendStatus = status.FAIL;
      });
  },
});

const profile = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { profile };
export default slice.reducer;
