import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { getValueFromOptions } from 'utils/prepareFields';

const sendSupport = createAsyncThunk('auth/sendSupport', async (fd) => {
  try {
    const formData = getValueFromOptions(fd);
    const { data } = await api.general.sendSupport(formData);
    toast.success('Your message has been successfully sent to support');
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const thunks = {
  sendSupport,
};

export { thunks };
