import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const listerProfile = {
  getProfile() {
    return http.get(WEB_API_ROUTES.user.getListerProfile);
  },
  updateListerProfile(formData) {
    return http.put(WEB_API_ROUTES.user.updateListerProfile, formData);
  },
  updateListerPhoto(file) {
    return http.post(WEB_API_ROUTES.user.updateListerPhoto, file);
  },
  getListerData() {
    return http.get(WEB_API_ROUTES.user.getListerData);
  },
  getMyListerInfo() {
    return http.get(WEB_API_ROUTES.user.getMyListerInfo);
  },
  readNotification() {
    return http.post(WEB_API_ROUTES.user.readNotification);
  },
  getListerInfo(listerId) {
    return http.get(
      WEB_API_ROUTES.user.getListerInfo.replace('{lister}', listerId)
    );
  },
  getGraphic(listerId, params) {
    return http.get(
      WEB_API_ROUTES.user.getGraphic.replace('{lister}', listerId),
      { params }
    );
  },
  sellOffer(data) {
    return http.post(WEB_API_ROUTES.user.sellOffer, data);
  },
  getVerificationLink() {
    return http.get(WEB_API_ROUTES.user.getTwitterVerificationLink);
  },
  sendTwitterVerificationParams(params) {
    return http.get(WEB_API_ROUTES.user.sendTwitterVerificationParams, {
      params,
    });
  },
};
